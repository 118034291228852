import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { format } from 'date-fns'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  getCustomerById,
  getCustomers,
  updateCustomerById,
  bulkActionCustomer,
} from '../../api/customer'
import ExportButton from '../../components/mini/ExportButton'
import HideDetails from '../../components/mini/HideDetails'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import { useGlobalContext } from '../../context'
import { dtFilenameDate } from '../../functions/myDateFns'
import { FilterMatchMode } from 'primereact/api'
import { TabPanel, TabView } from 'primereact/tabview'
import moneyFormatter from '../../functions/moneyFormatter'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'

export default function CustomerPage() {
  const { user, agents } = useGlobalContext()
  const toast = useRef(null)
  const dt = useRef(null)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = parseInt(searchParams.get('id'))
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    isEmailVerified: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedUserId: { value: null, matchMode: FilterMatchMode.IN },
  }
  const [selectedRecords, setSelectedRecords] = useState([])
  const emptyRecord = {
    id: null,
    fullName: null,
    email: null,
    lastOrder: {
      orderid: null,
      refillDate: null,
    },
    agent: null,
    assignedUser: null,
    newAssignedUser: null,
    paidOrderStats: {
      totalOrders: 0,
      totalPurchase: 0,
      avgOrderValue: 0,
      totalDiscount: 0,
    },
    paidOrders: [],
    unpaidOrders: [],
    loading: false,
  }
  const [filters, setFilters] = useState(emptyFilterObject)
  const [records, setRecords] = useState([])
  const [record, setRecord] = useState(emptyRecord)
  const [recordVisible, setRecordVisible] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [limit, setLimit] = useState(10)

  const onChangeFilter = (value, target) => {
    let _filters = { ...filters }
    _filters[target].value = value
    setFilters(_filters)
  }
  const hideRecord = () => {
    setRecord(emptyRecord)
    setRecordVisible(false)
    history.push({
      pathname: '/customers',
    })
  }

  const fetchRecord = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getCustomerById(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 404) {
        hideRecord()
      }
      if (res.status === 200) {
        setRecord((ps) => ({
          ...ps,
          ...res.data.record,
          newAssignedUser: res?.data?.record?.assignedUser || null,
        }))
        setRecordVisible(true)
      }
    }
  }, [id])

  useEffect(() => fetchRecord(), [fetchRecord])

  const fetchRecords = useCallback(async () => {
    setTableLoading(true)
    const res = await getCustomers({ limit })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setRecords(res.data)
      }
    }
  }, [limit])

  useEffect(() => fetchRecords(), [fetchRecords])

  const updateRecord = async () => {
    setRecord((ps) => ({ ...ps, loading: true }))
    const res = await updateCustomerById(record.id, {
      assignedUser: record.newAssignedUser,
    })
    if (res) {
      const updatedRecord = res.data.record
      setRecord((ps) => ({ ...ps, loading: false }))
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Record updated.',
          life: 3000,
        })
        // update the record in the records array
        setRecords((ps) => {
          const index = ps.findIndex((r) => r.id === record.id)
          if (index > -1) {
            const newRecords = [...ps]
            newRecords[index].assignedUsername = updatedRecord?.assignedUser?.username
            newRecords[index].assignedUserId = updatedRecord?.assignedUser?.id
            return newRecords
          }
          return ps
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Record update failed.',
          life: 3000,
        })
      }
    }
    setRecord((ps) => ({ ...ps, loading: false }))
  }

  const [bulkAssignedUser, setBulkAssignedUser] = useState(null)
  const [bulkAssignedUserLoading, setBulkAssignedUserLoading] = useState(false)
  const bulkAssignUserHandler = async () => {
    setBulkAssignedUserLoading(true)
    const ids = selectedRecords.map((r) => r.id)
    const res = await bulkActionCustomer({
      ids,
      action: 'assign-user',
      assignedUser: bulkAssignedUser,
    })
    if (res) {
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Records updated.',
          life: 3000,
        })
        // update the record in the records array
        const user = agents.find((a) => a.id === bulkAssignedUser)
        setRecords((ps) => {
          const newRecords = [...ps]
          ids.forEach((id) => {
            const index = newRecords.findIndex((r) => r.id === id)
            if (index > -1) {
              newRecords[index].assignedUsername = user?.username
              newRecords[index].assignedUserId = user?.id
            }
          })
          return newRecords
        })
        setSelectedRecords([])
        setBulkAssignedUser(undefined)
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Records update failed.',
          life: 3000,
        })
      }
    }
    setBulkAssignedUserLoading(false)
  }

  return (
    <div className="card">
      <Toast ref={toast} />

      <Toolbar
        className="p-toolbar p-flex-wrap gap-1"
        left={
          <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
            <h4 className="p-m-0">Customers</h4>
          </div>
        }
        right={
          <div className="p-d-flex p-flex-wrap gap-1">
            <Dropdown
              icon="pi pi-refresh"
              label="Assign User"
              disabled={
                !['superadmin', 'admin', 'manager'].includes(user.role) || !selectedRecords.length
              }
              placeholder="Select Assign User"
              options={agents.filter((a) => ['agent', 'teamlead'].includes(a.role))}
              optionLabel="username"
              optionValue="id"
              value={bulkAssignedUser}
              onChange={(e) => setBulkAssignedUser(e.value)}
            />
            <Button
              icon="pi pi-check"
              onClick={bulkAssignUserHandler}
              loading={bulkAssignedUserLoading}
              disabled={!bulkAssignedUser || bulkAssignedUserLoading}
              tooltip="Assign User Save"
            />
            <Button icon="pi pi-refresh" onClick={fetchRecords} />
            <ExportButton datatable_ref={dt} />
          </div>
        }
      ></Toolbar>
      <DataTable
        exportFilename={`Customer_${dtFilenameDate}`}
        ref={dt}
        loading={tableLoading}
        value={records}
        filters={filters}
        showGridlines
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
        className="datatable-responsive"
        columnResizeMode="fit"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
        rowHover
        emptyMessage="No record found."
        breakpoint="1200px"
        responsiveLayout="stack"
        removableSort
        selectionMode="multiple"
        selection={selectedRecords}
        onSelectionChange={(e) => {
          setSelectedRecords(e.value)
        }}
        header={
          <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
            <div className=" p-d-flex p-ai-center">
              <p className="p-m-0 p-pr-2">Assigned User</p>
              <MultiSelect
                placeholder="Assigned User"
                options={agents}
                optionLabel="username"
                optionValue="id"
                filter
                filterBy="username"
                filterPlaceholder="Search..."
                value={filters.assignedUserId.value}
                onChange={(e) => {
                  onChangeFilter(e.target.value, 'assignedUserId')
                }}
                showClear
              />
            </div>
            <div className=" p-d-flex p-ai-center">
              <p className="p-m-0 p-pr-2">Email Verified</p>
              <Dropdown
                placeholder="Email Verified"
                options={[
                  {
                    label: 'Yes',
                    value: 1,
                  },
                  {
                    label: 'No',
                    value: 0,
                  },
                ]}
                optionLabel="label"
                optionValue="value"
                // value={filters.isEmailVerified.value}
                // onChange={(e) => {
                //   onChangeFilter(e.target.value, 'isEmailVerified')
                // }}
                showClear
              />
            </div>
            <div className=" p-d-flex p-ai-center">
              <p className="p-m-0 p-pr-2">Limit</p>
              <Dropdown
                placeholder="Limit"
                options={[10, 50, 100, 500, 'All']}
                onChange={(e) => {
                  setLimit(e.value)
                }}
                value={limit}
              />
            </div>

            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                value={filters.global.value}
                onChange={(e) => {
                  onChangeFilter(e.target.value, 'global')
                }}
                placeholder="Search..."
              />
            </span>
          </div>
        }
      >
        <Column style={{ width: '0%' }} selectionMode="multiple" />
        <Column style={{ width: '0%' }} header="ID" field="id" sortable />
        <Column style={{ width: '3%' }} header="Name" field="fullName" />
        <Column
          style={{ width: '1%' }}
          header="Email"
          field="email"
          body={(rd) => <HideDetails value={rd.email} style={{ background: 'none', border: 0 }} />}
        />
        <Column
          style={{ width: '1%' }}
          header="Email Verified"
          field="isEmailVerified"
          body={(rd) => (rd.isEmailVerified ? 'Yes' : 'No')}
          sortable
        />
        <Column header="AgentId" field="assignedUserId" hidden />
        <Column style={{ width: '1%' }} header="User" field="assignedUsername" sortable />
        <Column
          style={{ width: '1%' }}
          header="Last Order"
          field="lastOrderId"
          body={(rd) =>
            rd.lastOrderId ? (
              <Link to={`/orders?invId=${rd.lastOrderId}`} target="_blank">
                {rd.lastOrderId}
              </Link>
            ) : (
              'N/A'
            )
          }
        />
        <Column
          style={{ width: '1%' }}
          header="Next Refill"
          field="lastOrderRefillDate"
          body={(rd) =>
            rd.lastOrderRefillDate ? format(new Date(rd.lastOrderRefillDate), 'P') : 'N/A'
          }
        />
        <Column
          style={{ width: '1%' }}
          header="Avg Order"
          field="avgOrderValue"
          body={(rd) => '$' + rd.avgOrderValue}
          sortable
        />
        <Column style={{ width: '1%' }} header="T. Orders" field="totalOrders" sortable />
        <Column
          style={{ width: '1%' }}
          header="T. Purchase"
          field="totalPurchase"
          body={(rd) => '$' + rd.totalPurchase}
          sortable
        />
        <Column
          style={{ width: '1%' }}
          header="T. Discount"
          field="totalDiscount"
          body={(rd) => '$' + rd.totalDiscount}
          sortable
        />
        <Column
          style={{ width: '1%' }}
          header="Action"
          body={(rd) => (
            <div className="p-d-flex gap-1by2 p-jc-start">
              <Link to={`?id=${rd?.id}`}>
                <Button
                  icon="pi pi-eye"
                  tooltip="Open Record"
                  tooltipOptions={{
                    position: 'bottom',
                  }}
                />
              </Link>
              <Link to={`/customers?history=${rd.id}`}>
                <Button
                  tooltip="History"
                  tooltipOptions={{ position: 'bottom' }}
                  icon="pi pi-clock"
                  className="p-button p-button-help"
                />
              </Link>
            </div>
          )}
        />
      </DataTable>
      <Dialog
        autoFocus
        style={{ width: '1200px' }}
        header={`Customer | ${record.fullName} | ${record.id}`}
        modal
        className=""
        visible={recordVisible}
        footer={
          <>
            <Button
              label="Close"
              icon="pi pi-times"
              className="p-button-text"
              disabled={record.loading}
              onClick={() => hideRecord()}
            />
          </>
        }
        onHide={() => hideRecord()}
      >
        <TabView>
          <TabPanel header="Customer Details">
            <DataTable
              value={[
                {
                  key: 'Id',
                  value: record?.id,
                },
                {
                  key: 'Name',
                  value: record?.fullName,
                },
                {
                  key: 'Email',
                  value: record?.email,
                },
                {
                  key: 'Email Verified',
                  value: record?.isEmailVerified ? 'Yes' : 'No',
                },
                {
                  key: 'Last Order',
                  value: (
                    <Link to={`/orders?invId=${record?.lastOrder?.orderid}`} target="_blank">
                      {record?.lastOrder?.orderid}
                    </Link>
                  ),
                },
                {
                  key: 'Next Refill',
                  value: record?.lastOrder?.refillDate
                    ? format(new Date(record?.lastOrder?.refillDate), 'Pp')
                    : 'N/A',
                },
                {
                  key: 'Assigned Agent',
                  value: (
                    <div className="p-d-flex gap-1 p-ai-center">
                      <Dropdown
                        className="min-w-15"
                        options={agents.filter((a) => ['agent', 'teamlead'].includes(a.role))}
                        optionLabel="username"
                        optionValue="id"
                        disabled={!['superadmin', 'admin', 'manager'].includes(user.role)}
                        placeholder="Select Agent"
                        value={record?.newAssignedUser}
                        onChange={(e) => {
                          setRecord((ps) => ({
                            ...ps,
                            newAssignedUser: e.value,
                          }))
                        }}
                      />
                      <Button
                        label="Save"
                        icon={'pi pi-save'}
                        disabled={record.assignedUser === record.newAssignedUser}
                        loading={record.loading}
                        onClick={updateRecord}
                      />
                    </div>
                  ),
                },
                {
                  key: ' Paid Total Orders',
                  value: record?.paidOrderStats?.totalOrders + ' Orders',
                },
                {
                  key: 'Paid Total Purchase',
                  value: moneyFormatter(record?.paidOrderStats?.totalPurchase, '$'),
                },
                {
                  key: ' Paid Avg Order Value',
                  value: moneyFormatter(record?.paidOrderStats?.avgOrderValue, '$'),
                },
                {
                  key: 'Paid Total Discount',
                  value: moneyFormatter(record?.paidOrderStats?.totalDiscount, '$'),
                },
              ]}
            >
              <Column field="key" header="Key"></Column>
              <Column field="value" header="Value" body={(rd) => rd.value}></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Paid Order History">
            <OrderTable value={record?.paidOrders} />
          </TabPanel>
          <TabPanel header="Unpaid Order History">
            <OrderTable value={record?.unpaidOrders} />
          </TabPanel>
        </TabView>
      </Dialog>
      <ChangeHistorySidebar
        setTableLoading={setTableLoading}
        header="Customer Change History"
        historyType="customer"
      />
    </div>
  )
}

const OrderTable = ({ value }) => {
  return (
    <DataTable
      value={value}
      rowHover
      breakpoint="1000px"
      responsiveLayout="stack"
      removableSort
      dataKey="orderid"
      showGridlines
      paginator
      rows={10}
      rowsPerPageOptions={[10, 25, 50]}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
      className="datatable-responsive"
      columnResizeMode="fit"
      emptyMessage="No orders found."
    >
      <Column
        field="orderid"
        header="Order ID"
        body={(rd) => (
          <Link to={`/orders?invId=${rd?.orderid}`} target="_blank">
            {rd?.orderid}
          </Link>
        )}
      />
      <Column field="orderStatus" header="Status" />
      <Column field="total" header="Total" body={(rd) => moneyFormatter(rd?.total, '$')} />
      <Column field="source" header="Source" />
      <Column field="shippingCompany" header="Ship" />
      <Column
        field="orderDate"
        header="Order Date"
        body={(rd) => format(new Date(rd.orderDate), 'Pp')}
      />
    </DataTable>
  )
}
