import baseURL from './baseUrl'

const endpoints = {
  test: {
    index: `${baseURL}/test/`,
    // upload: `${baseURL}/test/uploads`,
  },
  notification: {
    index: `${baseURL}/notification/`,
    subscribe: `${baseURL}/notification/subscribe`,
    unsubscribe: `${baseURL}/notification/unsubscribe`,
    topics: `${baseURL}/notification/topics`,
    history: `${baseURL}/notification/history`,
  },
  proxy: {
    aftershipAddressValidation: `${baseURL}/proxy/aftership/address-validation/`,
  },
  ipaccess: {
    index: `${baseURL}/ipaccess/`,
    website: `${baseURL}/ipaccess/website/`,
  },
  target: {
    index: `${baseURL}/target/`,
    my: `${baseURL}/target/my/`,
  },
  targetManagement: {
    index: `${baseURL}/targetManagement/`,
    pending: `${baseURL}/targetManagement/pending/`,
  },
  faq: {
    index: `${baseURL}/faq/`,
    category: `${baseURL}/faq/category/`,
    categoryFind: `${baseURL}/faq/category/find/`,
  },
  customer: {
    index: `${baseURL}/customer/`,
    bulkAction: `${baseURL}/customer/bulk-action/`,
    inquires: `${baseURL}/customer/inquires/`,
    inquires_analysis: `${baseURL}/customer/inquiry-analysis/`,
    blacklist: `${baseURL}/customer/blacklist/`,
    inquire_feedback: `${baseURL}/customer/inquires/feedback/`,
    inquire_reply: `${baseURL}/customer/inquires/reply/`,
    dnd: `${baseURL}/customer/dnd`,
  },
  auth: {
    login: `${baseURL}/auth/login/`,
    register: `${baseURL}/auth/register/`,
    verify: `${baseURL}/auth/verify/login/`,
    logout: `${baseURL}/auth/logout/`,
    myself: `${baseURL}/auth/myself/`,
    resetPassword: `${baseURL}/auth/reset-password/`,
  },
  offer: {
    index: `${baseURL}/offer/`,
  },
  master: {
    index: `${baseURL}/master/`,
    myself: `${baseURL}/master/myself/`,
    history: `${baseURL}/master/history/`,
    historyMy: `${baseURL}/master/history/my/`,
    operations: `${baseURL}/master/operations`,
    resetpass: `${baseURL}/master/resetpass/`,
    otp: `${baseURL}/master/otp/`,
    teams: `${baseURL}/master/teams/`,
  },
  review: {
    index: `${baseURL}/review/`,
    image: `${baseURL}/review/image/`,
  },
  order: {
    index: `${baseURL}/orders/`,
    status: `${baseURL}/orders/status/`,
    request: `${baseURL}/orders/processed-request/`,
    priceAndQuantity: `${baseURL}/orders/priceandquantity/`,
    product: `${baseURL}/orders/product/`,
    address: `${baseURL}/orders/address/`,
    productStatus: `${baseURL}/orders/product/status/`,
    products: `${baseURL}/orders/products/`,
    // orderProductFollowups: `${baseURL}/orders/product/followups/`,
    followups: `${baseURL}/orders/followups/`,
    orderProductSearch: `${baseURL}/orders/product/search/`,
    orderProductShipmentDelaySwitch: `${baseURL}/orders/product/shipmentdelay/switch/`,
    orderProductShipmentDelayNote: `${baseURL}/orders/product/shipmentdelay/note/`,
    history: `${baseURL}/orders/history/`,
    sync: `${baseURL}/orders/sync/`,
    contactMail: `${baseURL}/orders/contact/mail/`,
    contactMessage: `${baseURL}/orders/contact/message/`,
    contact: `${baseURL}/orders/contact/`,
    contactFix: `${baseURL}/orders/contact/fix/`,
    checkoutShipping: `${baseURL}/orders/checkout/shipping/`,
    issue: `${baseURL}/orders/issue/`,
    emailResend: `${baseURL}/orders/email/resend/`,
    assign: `${baseURL}/orders/assign/`,
    note: `${baseURL}/orders/note/`,
    qualityScore: `${baseURL}/orders/quality-score/`,
  },

  shipmentDelay: {
    index: `${baseURL}/shipment-delay/`,
    followups: `${baseURL}/shipment-delay/followups/`,
    note: `${baseURL}/shipment-delay/note/`,
  },

  product: {
    index: `${baseURL}/product/`,
    names: `${baseURL}/product/names/`,
    strengths: `${baseURL}/product/strengths/`,
    quantities: `${baseURL}/product/quantities/`,
    bulkAction: `${baseURL}/product/bulk-action/`,
    strengthandquantity: `${baseURL}/product/strengthandquantity/`,
    ingredients: `${baseURL}/product/ingredients/`,
    manufactureNames: `${baseURL}/product/manufactureNames/`,
    altnames: `${baseURL}/product/altnames/`,
    website: `${baseURL}/product/website/`,
    mce: `${baseURL}/product/mce/`,
    image: `${baseURL}/product/image/`,
    sync: `${baseURL}/product/sync/`,
    qna: `${baseURL}/product/qna/`,
    util: `${baseURL}/product/priceutil`,
  },
  json: {
    index: `${baseURL}/json/`,
  },
  category: {
    index: `${baseURL}/category/`,
    names: `${baseURL}/category/names/`,
    arrange: `${baseURL}/category/arrange/`,
  },
  cart: {
    index: `${baseURL}/cart/`,
  },
  search: {
    product: `${baseURL}/search/product/`,
    strength: `${baseURL}/search/strength/`,
    quantity: `${baseURL}/search/quantity/`,
    email: `${baseURL}/search/email/`,
    orderOrName: `${baseURL}/search/orderOrName/`,
    phone: `${baseURL}/search/phone/`,
  },
  payment: {
    index: `${baseURL}/payment/`,
  },
  address: {
    index: `${baseURL}/address/`,
  },
  tracking: {
    index: `${baseURL}/tracking/`,
    reship: `${baseURL}/tracking/reship/`,
  },
  dashboard: {
    ordersales: `${baseURL}/dashboard/ordersales/`,
    cancelledSalesRanking: `${baseURL}/dashboard/rank/cancelledsales/`,
    conversionRateRanking: `${baseURL}/dashboard/rank/conversionrate/`,
    salesRanking: `${baseURL}/dashboard/rank/sales/`,
    growthchart: `${baseURL}/dashboard/growthchart/`,
    targets: `${baseURL}/dashboard/targets/`,
    orderProductSales: `${baseURL}/dashboard/orderproductsales/`,
    salesQualityScore: `${baseURL}/dashboard/salesqualityscore/`,
  },

  coupon: {
    index: `${baseURL}/coupon/`,
    analytics: `${baseURL}/coupon/analytics/`,
    available: `${baseURL}/coupon/available/`,
  },
  excel: {
    order: `${baseURL}/excel/orders/`,
    orderProduct: `${baseURL}/excel/order-products/`,
  },
  shipping: {
    index: `${baseURL}/shipping/`,
    company: {
      index: `${baseURL}/shipping/company/`,
      tags: `${baseURL}/shipping/company/tags/`,
      timeline: `${baseURL}/shipping/company/timeline/`,
    },
    charges: {
      index: `${baseURL}/shipping/charges/`,
      product: `${baseURL}/shipping/charges/product/`,
    },
    shippingChargeMeta: `${baseURL}/shipping/shippingChargeMeta/`,
  },
  discount: {
    index: `${baseURL}/discount/`,
  },
  expanse: {
    index: `${baseURL}/expanse/`,
  },

  issues: {
    types: `${baseURL}/issues/types`,
    complaint: {
      index: `${baseURL}/issues/complaint/`,
      customOrder: `${baseURL}/issues/complaint/order`,
      notes: `${baseURL}/issues/complaint/notes/`,
      reopen: `${baseURL}/issues/complaint/reopen/`,
      followups: `${baseURL}/issues/complaint/followups/`,
    },
    dac: {
      index: `${baseURL}/issues/dac/`,
      customOrder: `${baseURL}/issues/dac/order`,
      notes: `${baseURL}/issues/dac/notes/`,
      reopen: `${baseURL}/issues/dac/reopen/`,
      followups: `${baseURL}/issues/dac/followups/`,
    },
  },

  ticketManagement: {
    index: `${baseURL}/userTickets/`,
    notes: `${baseURL}/userTickets/notes/`,
  },
  awsUploads: {
    uploads: `${baseURL}/aws/s3/uploads`,
  },
  avatar: {
    index: `${baseURL}/avatar/`,
  },
  paymentMethods: {
    index: `${baseURL}/payment-methods/`,
  },
  websiteBanners: {
    index: `${baseURL}/website-banners/`,
  },
}

export default endpoints
