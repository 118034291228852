import React, { useState, useEffect, useRef, useCallback } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import AddNewProductDialog from '../../components/mycomponents/Dialog/AddNewProductDialog'
import { deleteStoreProduct, getProducts, bulkActionProduct, syncStoreProduct } from '../../api'
import { Dropdown } from 'primereact/dropdown'
import ProductDetailsDialog from '../../components/mycomponents/Dialog/ProductDetailsDialog'
import { useGlobalContext } from '../../context'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import { Link } from 'react-router-dom'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import ConfirmationDialogWithInput from '../../components/mycomponents/Dialog/ConfirmationDialogWithInput'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import ExportButton from '../../components/mini/ExportButton'
import { dtFilenameDate } from '../../functions/myDateFns'
import { SplitButton } from 'primereact/splitbutton'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { handleObjChange } from '../../functions/setter'
import { Password } from 'primereact/password'

export default function ProductsPage() {
  const { user, productTypes, productCategoriesWithIds, shippingTags, toast } = useGlobalContext()
  /* STATIC */
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCode: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    shippingCompany: { value: null, matchMode: FilterMatchMode.EQUALS },
    productMainCategory: { value: null, matchMode: FilterMatchMode.EQUALS },
    productCategory: { value: null, matchMode: FilterMatchMode.CONTAINS },
    bestseller: { value: null, matchMode: FilterMatchMode.EQUALS },
    productType: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    isStockOut: { value: null, matchMode: FilterMatchMode.EQUALS },
    productStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdBy: { value: null, matchMode: FilterMatchMode.EQUALS },
  }

  /* REACT */
  const dt = useRef(null)
  const [openAddNewProductDialog, setOpenAddNewProductDialog] = useState(false)
  const [products, setProducts] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [tableProdLoading, setTableProdLoading] = useState(false)

  const [filters, setFilters] = useState(emptyFilterObject)
  const [deleteProduct, setDeleteProduct] = useState({
    value: {},
    status: false,
  })

  const fetchData = useCallback(async () => {
    setTableLoading(true)
    const res = await getProducts()
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setProducts(res.data)
      }
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handelSyncProduct = async (code) => {
    const res = await syncStoreProduct(code)
    if (res && res.status === 200) {
      setProducts((ps) =>
        [...ps].map((val) => {
          if (val.productCode === code) {
            return res.data
          }
          return val
        })
      )
    }
  }

  const handelSyncAddProduct = async (code) => {
    const res = await syncStoreProduct(code)
    if (res && res.status === 200) {
      setProducts((ps) => [res.data, ...ps])
    }
  }

  const onChangeFilter = (value, target) => {
    let _filters = { ...filters }
    _filters[target].value = value
    setFilters(_filters)
  }
  /* STATUS CHANGE REASON */
  const bulkActionEmpty = {
    visible: false,
    action: '',
    header: '',
    body: '',
    data: {},
    reason: '',
    password: '',
    loading: false,
  }
  const [bulkActionData, setBulkActionData] = useState(bulkActionEmpty)
  const hideReasonDialog = () => {
    setBulkActionData(bulkActionEmpty)
  }
  const handleBulkAction = async () => {
    handleObjChange(setBulkActionData, 'loading', true)
    const { reason, password, action, data } = bulkActionData
    const ids = selectedRows.map((item) => item.productCode)
    const input = {
      reason,
      action,
      data,
    }
    const res = await bulkActionProduct(ids, input, password)
    if (res) {
      handleObjChange(setBulkActionData, 'loading', false)
      if (res.status === 200) {
        hideReasonDialog()
        setSelectedRows([])
        fetchData()
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message || 'Action completed successfully',
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: res.data.error || 'Something went wrong',
        })
      }
    }
  }
  /* BODIES */

  const dateBodyTemplate = (rowData) => {
    let maindate = rowData.createdDate.split(' ')[0]
    return <>{maindate}</>
  }

  /* DELETE METHODS */
  const [password, setPassword] = useState('')
  const [deleting, setDeleting] = useState(false)

  const hideConfirmDeleteProductDialog = () => {
    setPassword('')
    setDeleteProduct({ value: {}, status: false })
    setDeleting(false)
  }
  const handelDeleteProduct = async () => {
    let code = deleteProduct.value.productCode
    setDeleting(true)
    const res = await deleteStoreProduct(code, password)
    setDeleting(false)

    if (res && res.status === 200) {
      setProducts(products.filter((item) => item.productCode !== code))
      hideConfirmDeleteProductDialog()

      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Product deleted successfully',
      })
    } else {
      setPassword('')
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: res.data.error || 'Something went wrong',
      })
    }
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <div style={{ gap: '8px' }} className="actions p-d-flex p-ai-center p-jc-center">
        <Link to={`?view=${rowData.productCode}`}>
          <Button icon="pi pi-eye" tooltip="View" tooltipOptions={{ position: 'bottom' }} />
        </Link>

        {['superadmin', 'admin', 'manager', 'operations'].includes(user.role) && (
          <Button
            tooltip="Delete Product"
            tooltipOptions={{ position: 'bottom' }}
            icon="pi pi-trash"
            className="p-button p-button-danger"
            onClick={() => setDeleteProduct({ value: rowData, status: true })}
          />
        )}
        <Link to={`?history=${rowData.productCode}`}>
          <Button
            tooltip="History"
            tooltipOptions={{ position: 'bottom' }}
            icon="pi pi-clock"
            className="p-button p-button-help"
          />
        </Link>
      </div>
    )
  }

  /* HEADERS */
  const header = (
    <div
      className="p-my-2 p-d-flex p-flex-wrap gap-1 p-ai-center p-jc-end"
      style={{ textAlign: 'center' }}
    >
      <div className="p-d-flex p-flex-wrap p-ai-center gap-1by2">
        <span className="">S.C</span>
        <Dropdown
          value={filters.shippingCompany.value}
          onChange={(e) => onChangeFilter(e.value, 'shippingCompany')}
          options={shippingTags}
          showClear
          placeholder="Select"
        />
        <span className="">Main Cat.</span>
        <Dropdown
          placeholder="Select"
          value={filters.productMainCategory.value}
          onChange={(e) => onChangeFilter(e.value, 'productMainCategory')}
          options={productCategoriesWithIds.filter((product) => product.parentCatId === 0)}
          optionLabel="name"
          optionValue="name"
          filter
          filterBy="name"
          filterPlaceholder="Category"
          showClear
        />
        <span className="">Sub Cat.</span>
        <Dropdown
          placeholder="Select"
          value={filters.productCategory.value}
          onChange={(e) => onChangeFilter(e.value, 'productCategory')}
          options={productCategoriesWithIds.filter((product) => product.parentCatId !== 0)}
          optionLabel="name"
          optionValue="name"
          filter
          filterBy="name"
          filterPlaceholder="Category"
          showClear
        />
        <span className="">Type</span>
        <Dropdown
          placeholder="Select"
          value={filters.productType.value}
          onChange={(e) => onChangeFilter(e.value, 'productType')}
          options={productTypes}
          filterPlaceholder="Search Type"
          showClear
        />
        <span className="">Status</span>
        <Dropdown
          placeholder="Select"
          value={filters.productStatus.value}
          onChange={(e) => onChangeFilter(e.value, 'productStatus')}
          options={['active', 'inactive']}
          showClear
        />
        <span className="">Stock</span>
        <Dropdown
          placeholder="Select"
          value={filters.isStockOut.value}
          onChange={(e) => onChangeFilter(e.value, 'isStockOut')}
          options={[
            {
              label: 'Stock Out',
              value: 1,
            },
            {
              label: 'In Stock',
              value: 0,
            },
          ]}
          optionLabel="label"
          optionValue="value"
          showClear
        />

        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={filters.global.value}
            onInput={(e) => onChangeFilter(e.target.value, 'global')}
            placeholder="Search..."
          />
        </span>
      </div>
    </div>
  )

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="p-mr-2 p-mb-0">All Products</h5>
        <Button
          // label="Add Product"
          tooltipOptions={{ position: 'bottom' }}
          tooltip="Add Product"
          icon="pi pi-plus"
          className="p-button"
          onClick={() => setOpenAddNewProductDialog(true)}
        />

        <Link to="/website/category">
          <Button
            label="Category"
            icon="pi pi-external-link"
            className="p-button p-button-primary p-mx-2"
          />
        </Link>
      </React.Fragment>
    )
  }
  const rightToolbarTemplate = () => {
    return (
      <div className="p-d-flex p-flex-wrap gap-1">
        <SplitButton
          label="Deactivate"
          icon="pi pi-angle-double-down"
          disabled={selectedRows.length === 0}
          onClick={() => {
            setBulkActionData({
              visible: true,
              action: 'deactivate',
              header: 'Set Selected Deactivate Products',
              body: 'Are you sure you want to deactivate selected products?',
            })
          }}
          model={[
            {
              label: 'Activate',
              icon: 'pi pi-angle-double-up',
              disabled: selectedRows.length === 0,
              command: () =>
                setBulkActionData({
                  visible: true,
                  action: 'activate',
                  header: 'Set Selected Activate Products',
                  body: 'Are you sure you want to activate selected products?',
                }),
            },
            {
              label: 'Stock Available',
              icon: 'pi pi-check',
              disabled: selectedRows.length === 0,
              command: () =>
                setBulkActionData({
                  visible: true,
                  action: 'stock_available',
                  header: 'Set Selected Stock Available',
                  body: 'Are you sure you want to make selected products stock available?',
                }),
            },
            {
              label: 'Stock Unavailable',
              icon: 'pi pi-times',
              disabled: selectedRows.length === 0,
              command: () =>
                setBulkActionData({
                  visible: true,
                  action: 'stock_unavailable',
                  header: 'Set Selected Stock Unavailable',
                  body: 'Are you sure you want to make selected producs stock unavailable?',
                }),
            },
          ]}
        />

        <Button
          icon="pi pi-refresh"
          tooltip="Refresh"
          tooltipOptions={{ position: 'bottom' }}
          className="p-button"
          onClick={fetchData}
        />
        <ExportButton datatable_ref={dt} />
      </div>
    )
  }
  const bestSellerBody = (rowData) => {
    return <span>{rowData.bestseller}</span>
  }
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <div className="card">
        <Toolbar
          className="p-toolbar"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>
        <DataTable
          exportFilename={`Products_${dtFilenameDate}`}
          ref={dt}
          value={products}
          filters={filters}
          showGridlines
          dataKey="productCode"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          className="datatable-responsive"
          columnResizeMode="fit"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          emptyMessage="No products found."
          header={header}
          loading={tableLoading || tableProdLoading}
          rowHover
          breakpoint="1000px"
          responsiveLayout="stack"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
        >
          <Column selectionMode="multiple" headerStyle={{ width: '0' }} />
          <Column field="productCode" header="Code" />
          <Column field="productCode" header="Code" />
          <Column field="productName" sortable header="Name" />
          <Column field="shippingCompany" header="S.C" />
          <Column sortable field="productMainCategory" header="Main Cat." />
          <Column sortable field="productCategory" header="Sub Cat." />
          <Column field="bestseller" header="Bstslr" body={bestSellerBody} />
          <Column field="productType" header="Type" />
          <Column field="productSlug" header="Slug" />
          <Column field="productStatus" header="Status" />
          <Column
            field="isStockOut"
            header="Stock Out"
            body={(rowData) => <span>{rowData.isStockOut ? 'Yes' : 'No'}</span>}
          />
          <Column field="createdBy" header="Creator" />
          <Column field="createdDate" header="Date" body={dateBodyTemplate} />
          <Column
            hidden={!['superadmin', 'admin', 'manager', 'operations'].includes(user.role)}
            header="Action"
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
      <ChangeHistorySidebar
        setTableLoading={setTableLoading}
        header="Product Change History"
        historyType="product"
      />
      <AddNewProductDialog
        productDialog={openAddNewProductDialog}
        setProductDialog={setOpenAddNewProductDialog}
        handelSyncAddProduct={handelSyncAddProduct}
      />
      <ProductDetailsDialog
        setTableLoading={setTableLoading}
        setTableProdLoading={setTableProdLoading}
        handelSyncProduct={handelSyncProduct}
      />
      {/* Product delete confirmation */}
      <ConfirmDialogWithPassword
        onHide={hideConfirmDeleteProductDialog}
        onSubmit={handelDeleteProduct}
        visible={deleteProduct.status}
        loading={deleting}
        password={password}
        setPassword={setPassword}
        headerText="Delete Product"
        mainText={`Are you sure you want to delete product ${deleteProduct?.value?.productName}?`}
      />

      <ConfirmationDialogWithInput
        visible={bulkActionData?.visible}
        onHide={hideReasonDialog}
        header={bulkActionData?.header}
        customInputs={true}
        onSubmit={handleBulkAction}
        loading={bulkActionData?.loading}
      >
        <p>{bulkActionData?.body}</p>
        <div className="p-field p-fluid">
          <label className="p-d-block">Reason</label>
          <InputText
            value={bulkActionData?.reason}
            onChange={(e) => handleObjChange(setBulkActionData, 'reason', e.target.value)}
            autoFocus
            placeholder="Enter reason for change"
          />
        </div>
        <div className="p-field p-fluid">
          <label className="p-d-block">Password</label>
          <Password
            feedback={false}
            toggleMask={true}
            value={bulkActionData?.password}
            onChange={(e) => handleObjChange(setBulkActionData, 'password', e.target.value)}
            placeholder="Enter your password"
          />
        </div>
      </ConfirmationDialogWithInput>
    </PageAllowedToRoles>
  )
}
