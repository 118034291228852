import { Accordion, AccordionTab } from 'primereact/accordion'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { RadioButton } from 'primereact/radiobutton'
import { Timeline } from 'primereact/timeline'
import { Toast } from 'primereact/toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Image } from 'primereact/image'
import {
  addPaymentLink,
  addTrackinIds,
  deleteAwsS3Files,
  getOrderHistory,
  patchOrderAddress,
  patchOrderProducts,
  patchProductStatus,
  removePaymentLink,
  removeTrackingId,
  resendPaymentLink,
  resendTrackingId,
  reshipTrackingId,
  searchQuantity,
  updateOrderHistoryReason,
  uploadFileToS3,
} from '../../api'
import AddNewProductInOrder from './AddNewProductInOrder'
import { format, formatDistanceToNow, isValid } from 'date-fns'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { Tooltip } from 'primereact/tooltip'
import { useGlobalContext } from '../../context'
import capitalizeFirstLetter from '../../functions/capitalizeFirstLetter'
import discountFns from '../../functions/discountFns'
import OrderProductINSDSwitch from './OrderManagement/OrderProductINSDSwitch'
import HideDetails from '../mini/HideDetails'
import generateS3Path from '../../functions/generateS3Path'
import { nanoid } from 'nanoid'
import classNames from 'classnames'
import { undeliveredOrderStatusOptions } from '../../data/orderStatusValues'
import { MultiSelect } from 'primereact/multiselect'
import { patchOrderForFiles, patchSaleQualityScore } from '../../api/order'
import { handleObjChange } from '../../functions/setter'
import ConfirmDialogWithPassword from './Dialog/ConfirmDialogWithPassword'
import { set } from 'lodash'
import { PiWarningOctagonBold } from 'react-icons/pi'
import ConfirmationDialogWithInput from './Dialog/ConfirmationDialogWithInput'
import WarningDialog from './Dialog/WarningDialog'
const allowedProductStatus = ['Shipped', 'Tracking', 'Tracking-Live', 'Delivered'] // Processed has been removed from the options
export default function OrderDetailsDialog({
  resetUrl,
  discountOptions,
  handelSyncOrder,
  orderDialogVisible,
  setOrderDialogVisible,
  orderProductDetails,
  enableProductFields,
  setOrderProductDetails,
  orderDetails,
  setOrderDetails,
  setEnableProductFields,
  isEditable,
  setEnableFields,
  addressDetails,
  enableFields,
  setAddressDetails,
  addTrackingDetails,
  setAddTrackingDetails,
  trackingDetails,
  setTrackingDetails,
}) {
  /**
   * @param {function} resetUrl - The function to reset the url
   * @param {array} discountOptions - The array of discount options
   * @param {function} handelSyncOrder - The function to sync the order
   * @param {boolean} orderDialogVisible - The boolean to set the order dialog visible
   * @param {function} setOrderDialogVisible - The function to set the order dialog visible
   * @param {array} orderProductDetails - The array of order product details
   * @param {boolean} enableProductFields - The boolean to set the product fields editable
   * @param {function} setOrderProductDetails - The function to set the order product details
   * @param {object} orderDetails - The object of order details
   * @param {function} setOrderDetails - The function to set the order details
   * @param {function} setEnableProductFields - The function to set the product fields editable
   * @param {boolean} isEditable - The boolean to set the order editable
   * @param {function} setEnableFields - The function to set the order editable
   * @param {object} addressDetails - The object of address details
   * @param {boolean} enableFields - The boolean to set the order fields editable
   * @param {function} setAddressDetails - The function to set the address details
   * @param {array} addTrackingDetails - The array of tracking details
   * @param {function} setAddTrackingDetails - The function to set the tracking details
   * @param {array} trackingDetails - The array of tracking details
   * @param {function} setTrackingDetails - The function to set the tracking details
   * @description - The dialog to display the order details
   **/
  /* STATIC */
  const { getActionBtn, user, shippingTags, productStrengths, paymentMethods } = useGlobalContext()

  let emptyAddTrackingDetails = {
    trackinglink: null,
    trackingid: null,
    quantity: null,
  }
  const resend_status = ['Shipped', 'Tracking', 'Tracking-Live', 'Delivered']
  const salesQAScoreOptions = [
    {
      saleQualityValues: 'Greeting',
      saleQualityScore: 1,
    },
    {
      saleQualityValues: 'Product Quotation',
      saleQualityScore: 1,
    },
    {
      saleQualityValues: 'Customer Details Verification',
      saleQualityScore: 1,
    },
    {
      saleQualityValues: 'Order Details Verification',
      saleQualityScore: 1,
    },
    {
      saleQualityValues: 'Callback Number',
      saleQualityScore: 1,
    },
    {
      saleQualityValues: 'Shipping / Track/ Delivery Info',
      saleQualityScore: 1,
    },
    {
      saleQualityValues: 'Payment Discretion',
      saleQualityScore: 1,
    },
  ]

  const [isEditingSalesQAScore, setIsEditingSalesQAScore] = useState(false)
  const [updatedSalesQAScore, setUpdatedSalesQAScore] = useState({})
  const paymentLinkRef = useRef()
  const paymentScreenshotRef = useRef(null)
  const prescriptionDocumentRef = useRef(null)
  const [trackSubmit, setTrackSubmit] = useState(false)
  const [trackResend, setTrackResend] = useState({
    item: {},
    loading: false,
  })
  const [trackReship, setTrackReship] = useState({
    item: {},
    loading: false,
  })
  const [paymentLinks, setPaymentLinks] = useState([])
  const [plinkSubmit, setPlinkSubmit] = useState(false)
  const [pdSaveLoading, setPdSaveLoading] = useState(false)
  const [changeStatusNotification, setChangeStatusNotification] = useState(true)
  const [paymentImageFiles, setPaymentImageFiles] = useState([])
  const [paymentAttachments, setPaymentAttachments] = useState([])
  const [paymentImageLoading, setPaymentImageLoading] = useState(false)
  const [paymentImageTableLoading, setPaymentImageTableLoading] = useState(false)
  const status = [
    'Ineligible',
    'Pending',
    'Processed',
    'Shipped',
    'Tracking',
    'Tracking-Live',
    'Delivered',
    'Undelivered',
    'Cancelled',
    'Reshipment-Initiated',
    'Reshipment-Completed',
    'Refund-Initiated',
    'Refund-Completed',
    'Ineligi-Cancel',
  ]
  const [originalOrderProductDetails, setOriginalOrderProductDetails] = useState([])
  const [prescriptionDocuments, setPrescriptionDocuments] = useState([])
  const [prescriptionDocumentsTableLoading, setPrescriptionDocumentsTableLoading] = useState(false)
  const [prescriptionAttachments, setPrescriptionAttachments] = useState([])
  /* Ref */
  const toast = useRef(null)
  const showSuccess = (summary = 'Success Message', detail = 'Message Content') => {
    toast.current.show({
      severity: 'success',
      summary,
      detail,
      life: 3000,
    })
  }
  const showError = (summary = 'Error Message', detail = 'Message Content') => {
    toast.current.show({
      severity: 'error',
      summary,
      detail,
      life: 4000,
    })
  }

  /* STATES */
  const [addNewProductVisible, setAddNewProductVisible] = useState(false)
  const [newProduct, setNewProduct] = useState({})
  const [requestInfo, setRequestInfo] = useState(false)
  const [proxyEvent, setProxyEvent] = useState(null)
  const [proxyRowData, setProxyRowData] = useState(null)
  const [trackingValid, setTrackingValid] = useState(false)
  const [prescriptionDocumentDeleteRecord, setPrescriptionDocumentDeleteRecord] = useState({})
  const [prescriptionDocumentDeleteVisible, setPrescriptionDocumentDeleteVisible] = useState(false)
  const [paymentFilesDeleteRecord, setPaymentFilesDeleteRecord] = useState({})
  const [paymentFilesDeleteVisible, setPaymentFilesDeleteVisible] = useState(false)
  /* DIALOGS */
  const [changeStatusDialog, setChangeStatusDialog] = useState(false)
  const [saveProductsDialog, setSaveProductsDialog] = useState(false)
  const [saveAddressDialog, setSaveAddressDialog] = useState(false)

  /* HISTORY */
  const [orderHistory, setOrderHistory] = useState([])
  const [actionCounter, setActionCounter] = useState(0)

  /* STATUS LOCATION */

  const marker = (item) => {
    return getActionBtn(item.actionType)
  }

  const toggelReasonTextbox = (index, bool) => {
    if (orderHistory.length > 0) {
      const temp = [...orderHistory]
      temp[index].textbox = bool
      setOrderHistory(temp)
    }
  }
  const content = (item, index) => {
    let parseData = new Date(item?.timestamp)
    let date = formatDistanceToNow(parseData, { addSuffix: true })
    let date1 = format(parseData, 'PPPPp')

    return (
      <div key={item.id}>
        <h6 className=" p-text-bold" style={{ wordBreak: 'break-all' }}>
          {capitalizeFirstLetter(item?.action)}
        </h6>

        <div>
          <p>
            <span className=" ">Performed By : </span>
            <label className="p-text-lowercase">
              {item?.master} ({item?.masterRole})
            </label>
          </p>
          <span>
            {date} - {date1}
          </span>
        </div>
        {item.masterid === user.id && (
          <p
            onClick={() => toggelReasonTextbox(index, true)}
            className="p-my-2 p-error block"
            style={{ cursor: 'pointer' }}
          >
            Reason for Action ?
          </p>
        )}
        {item?.textbox && (
          <div className="p-d-flex p-ai-center ">
            <InputTextarea
              placeholder="Enter the reason"
              autoResize
              id="name"
              type="text"
              value={item?.reason}
              onChange={(e) => {
                const temp = [...orderHistory]
                temp[index].reason = e.target.value
                setOrderHistory(temp)
              }}
              style={{ width: '25rem', margin: '5px 0px' }}
            />
            <div>
              <div>
                <Button
                  label="Save"
                  className="p-button-text p-button-primary p-ml-2"
                  icon="pi pi-send"
                  onClick={async () => {
                    toggelReasonTextbox(index, false)
                    const res = await updateOrderHistoryReason(item.id, item.reason)
                    if (res && res.status === 200) {
                      showSuccess('Success', 'Order History Updated Successfully')
                    } else {
                      showError('Error', 'Something went wrong')
                    }
                  }}
                />
              </div>
              <div>
                <Button
                  label="Cancel"
                  className="p-button-text p-button-danger p-ml-2"
                  icon="pi pi-times"
                  onClick={() => toggelReasonTextbox(index, false)}
                />
              </div>
            </div>
          </div>
        )}
        {!item?.textbox && (
          <h6 className="p-d-flex p-ai-center p-text-italic p-mb-2">{item?.reason}</h6>
        )}
      </div>
    )
  }

  /* Check Tracking Valid */
  useEffect(() => {
    let valid = false
    if (addTrackingDetails && addTrackingDetails.length > 0) {
      //if product check for null
      addTrackingDetails.forEach((element) => {
        let tracking = Object.values(element)
        let findNullValue = tracking.findIndex((value) => !value)
        //-1 means no product is null so set valid true
        if (findNullValue === -1) {
          valid = true
        } else {
          valid = false
        }
      })
    }
    setTrackingValid(valid)
  }, [addTrackingDetails])

  /* FUNCTIONS */

  const getOrderTimeline = useCallback(async () => {
    if (!orderDialogVisible) return
    const res = await getOrderHistory(orderDetails.orderid, {
      type: 'order',
    })
    if (res && res.status === 200) {
      setOrderHistory(
        res.data.map((item) => {
          item.textbox = false
          return item
        })
      )
    }
  }, [orderDetails.orderid, orderDialogVisible])

  useEffect(() => {
    if (orderDetails?.paymentFiles) setPaymentImageFiles(orderDetails.paymentFiles)
    if (orderDetails?.prescriptionDocuments)
      setPrescriptionDocuments(orderDetails.prescriptionDocuments)
  }, [orderDetails.orderid, orderDetails?.paymentFiles])

  //Handler to upload new payment files
  const handlePaymentFilesUpload = async () => {
    setPaymentImageLoading(true)
    setPaymentImageTableLoading(true)
    try {
      let arrayOfFiles = []
      if (paymentAttachments.length) {
        await Promise.all(
          paymentAttachments.map(async (attachment, index) => {
            const res = await uploadFileToS3({ file: attachment.file })
            // save
            arrayOfFiles.push({
              uid: attachment.uid,
              name: attachment.name,
              path: res.data.filePath,
              createdAt: new Date().toISOString(),
              meta: {
                createdBy: user?.username,
              },
            })
          })
        )
      }
      // merge old and new image files
      const uploadFiles = arrayOfFiles.concat(paymentImageFiles)
      const res = await patchOrderForFiles(orderDetails?.orderid, 'ADD_PAYMENT_FILES', uploadFiles)
      if (res && res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: 'Files uploaded successfully',
        })
        setPaymentImageFiles(uploadFiles)
        setPaymentAttachments([])
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      })
    }
    setPaymentImageTableLoading(false)
    setPaymentImageLoading(false)
  }

  // Handler to delete payment file
  const handlePaymentFileDelete = async () => {
    setPaymentImageTableLoading(true)
    try {
      let deletePaths = [paymentFilesDeleteRecord.path]
      const updatedImageFiles = paymentImageFiles.filter(
        (imageFile) => imageFile.uid !== paymentFilesDeleteRecord.uid
      )
      await deleteAwsS3Files(deletePaths)
      const res = await patchOrderForFiles(
        orderDetails?.orderid,
        'REMOVE_PAYMENT_FILES',
        updatedImageFiles
      )
      if (res) {
        hidePaymentFilesDeleteDialog()
        if (res.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Operation Successful',
            detail: 'File deleted successfully',
          })
          setPaymentImageFiles(updatedImageFiles)
        }
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      })
    }
    setPaymentImageTableLoading(false)
  }

  // Handler to upload new prescription documents
  const handlePrescriptionDocumentsUpload = async () => {
    setPrescriptionDocumentsTableLoading(true)
    try {
      let arrayOfFiles = []
      if (prescriptionAttachments.length) {
        await Promise.all(
          prescriptionAttachments.map(async (attachment, index) => {
            const res = await uploadFileToS3({ file: attachment.file })
            // save
            arrayOfFiles.push({
              uid: attachment.uid,
              name: attachment.name,
              path: res.data.filePath,
              createdAt: new Date().toISOString(),
              meta: {
                createdBy: user?.username,
              },
            })
          })
        )
      }
      // merge old and new image files
      const uploadFiles = arrayOfFiles.concat(prescriptionDocuments)
      const res = await patchOrderForFiles(
        orderDetails?.orderid,
        'ADD_PRESCRIPTION_DOCUMENTS',
        uploadFiles
      )
      if (res && res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: 'Files uploaded successfully',
        })
        setPrescriptionDocuments(uploadFiles)
        setPrescriptionAttachments([])
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      })
    }
    setPrescriptionDocumentsTableLoading(false)
  }

  // Handler to delete prescription document
  const handlePrescriptionDocumentDelete = async () => {
    setPrescriptionDocumentsTableLoading(true)
    try {
      let deletePath = [prescriptionDocumentDeleteRecord.path]
      const updatedImageFiles = prescriptionDocuments.filter(
        (imageFile) => imageFile.uid !== prescriptionDocumentDeleteRecord.uid
      )
      await deleteAwsS3Files(deletePath)
      const res = await patchOrderForFiles(
        orderDetails?.orderid,
        'REMOVE_PRESCRIPTION_DOCUMENTS',
        updatedImageFiles
      )
      if (res) {
        hidePrescriptionDocumentDeleteDialog()
        if (res.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Operation Successful',
            detail: 'File deleted successfully',
          })
          setPrescriptionDocuments(updatedImageFiles)
        }
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      })
    }
    setPrescriptionDocumentsTableLoading(false)
  }

  useEffect(() => {
    getOrderTimeline()
  }, [actionCounter, getOrderTimeline])

  const submitPaymentLink = async () => {
    setPlinkSubmit(true)
    const res = await addPaymentLink(
      orderDetails?.orderid,
      orderDetails.paymentlink,
      orderDetails.paymentMethod
    )

    if (res) {
      setPlinkSubmit(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        let _orderDetails = { ...orderDetails }
        _orderDetails.isPaymentLinkThere = true
        _orderDetails.prevPaymentMethod = _orderDetails.paymentMethod
        setOrderDetails(_orderDetails)
        setActionCounter(actionCounter + 1)
      }
    }
  }

  const onEnableFields = () => {
    setEnableFields(true)
    // setRequestInfo(true)
  }

  // handle file upload function
  const handleFilesUpload = (e, setter) => {
    if (!e.target.files.length || typeof setter !== 'function') {
      return
    }
    const files = e.target.files
    const myFiles = []
    let largeFiles = 0
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (file.size < 1024 * 1024 * 5) {
        const path = URL.createObjectURL(file)
        myFiles.push({
          uid: nanoid(),
          name: file.name,
          file,
          path,
        })
      } else {
        largeFiles++
      }
    }
    if (largeFiles) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: `${largeFiles} files are larger than 5 MB and will not be uploaded`,
      })
    }
    setter((ps) => [...ps, ...myFiles])
  }

  // remove attachments files function
  const removeFiles = (uid, state, setter) => {
    if (typeof setter !== 'function') return
    let filValue = state.filter((ele) => ele.uid !== uid)
    setter(filValue)
  }

  const onEnableProductFields = () => {
    setEnableProductFields(true)
  }

  const onDisableProductFields = () => {
    setOrderProductDetails(originalOrderProductDetails)
    setEnableProductFields(false)
    // setOriginalOrderProductDetails([])
  }

  const hideDialog = () => {
    resetUrl()
    setOrderDialogVisible(false)
    setEnableFields(false)
    setRequestInfo(false)
    setEnableProductFields(false)
    setOrderHistory([])
    setPlinkSubmit(false)
    handelSyncOrder()
    setPaymentImageFiles([])
    setPaymentAttachments([])
    setIsEditingSalesQAScore(false)
  }

  const onDisableFields = () => {
    setEnableFields(false)
    setRequestInfo(false)
  }
  const saveAddressDeatils = async () => {
    const res = await patchOrderAddress(orderDetails.orderid, {
      ...addressDetails,
    })
    if (res && res.status === 200) {
      showSuccess('Success', 'Address Updated')
      onDisableFields()
      setActionCounter(actionCounter + 1)
    } else {
      showError('Error', 'Address Not Updated')
    }
  }
  const onProductAdd = async () => {
    setAddNewProductVisible(true)
  }
  const orderDialogFooter = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <div>
        <p className="opacity-75">
          Status: {orderDetails.orderStatus},{' '}
          {orderDetails.orderDate && isValid(new Date(orderDetails.orderDate))
            ? 'Order-Date: ' + format(new Date(orderDetails.orderDate), 'PPp')
            : ''}
          , {orderDetails.agentName ? 'Order by: ' + orderDetails.agentName : ''}
        </p>
      </div>
      <div>
        <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      </div>
    </div>
  )

  const onOrderProductChange = (val, target, id) => {
    let _orderProductDetails = [...orderProductDetails]
    const index = _orderProductDetails.findIndex((item) => item.id === id)
    _orderProductDetails[index][target] = val
    _orderProductDetails[index]['total'] = (
      parseFloat(_orderProductDetails[index]['quantityPrice']) *
      parseFloat(
        _orderProductDetails[index]['quantity'] ||
          _orderProductDetails[index]['quantity']['quantity']
      ) *
      parseFloat(_orderProductDetails[index]['totalQuantity'])
    ).toFixed(2)
    setOrderProductDetails(_orderProductDetails)
  }

  const paymentBasedDiscount = (cpm) => {
    let ppm = orderDetails?.prevPaymentMethod
    const czd =
      (parseFloat(orderDetails?.subtotal) +
        parseFloat(orderDetails?.dcharge) -
        parseFloat(orderDetails?.discount)) *
      0.02
    // add method name to apply discound
    const discountMethodsAry = []
    let discountVal = null
    if (ppm === null && discountMethodsAry.includes(cpm)) {
      discountVal = czd
    } else if (ppm === null && !discountMethodsAry.includes(cpm)) {
      discountVal = 0
    } else if (discountMethodsAry.includes(ppm) && discountMethodsAry.includes(cpm)) {
      discountVal = czd
    } else if (!discountMethodsAry.includes(ppm) && discountMethodsAry.includes(cpm)) {
      discountVal = czd
    } else if (discountMethodsAry.includes(ppm) && !discountMethodsAry.includes(cpm)) {
      discountVal = 0
    } else if (!discountMethodsAry.includes(ppm) && !discountMethodsAry.includes(cpm)) {
      discountVal = 0
    }
    return discountVal
  }
  const onOrderDetailsChange = (e, target) => {
    let val = (e.target && e.target.value) || ''
    if (target === 'dcharge' || target === 'discount') {
      if (val > 100 || val < 0) {
        return
      }
    }

    let _orderDetails = { ...orderDetails }
    _orderDetails[target] = val
    if (target === 'paymentMethod') {
      let newDiscount = paymentBasedDiscount(val)
      if (newDiscount !== null && isNaN(discountFns)) {
        newDiscount = parseFloat(newDiscount).toFixed(2)
        _orderDetails.paymentDiscount = newDiscount
      }
      _orderDetails.paymentlink = ''
      _orderDetails.isPaymentLinkThere = false
    }
    if (target === 'isDiscountPercent') {
      _orderDetails.discount = 0
      _orderDetails.discountValue = 0
    }
    if (target === 'discountValue') {
      let _discountValue = val || 0
      _orderDetails.discountValue = _discountValue
      if (_orderDetails.isDiscountPercent === 'Percentage') {
        let _subtotal = 0
        let _discountedSubtotal = 0

        orderProductDetails.forEach((val) => {
          let { quantityPrice, quantity, totalQuantity } = val
          quantityPrice = parseFloat(quantityPrice)
          quantity = parseFloat(quantity)
          totalQuantity = parseFloat(totalQuantity)
          let mySubtotal = 0
          let myDiscountedSubtotal = 0
          mySubtotal = parseFloat(quantityPrice * quantity * totalQuantity)
          if (_orderDetails.isDiscountPercent === 'Percentage') {
            let discountedQuantityPrice = parseFloat(
              (quantityPrice - (quantityPrice * _discountValue) / 100).toFixed(2)
            )
            myDiscountedSubtotal = parseFloat(discountedQuantityPrice * quantity * totalQuantity)
          }
          _subtotal += mySubtotal
          _discountedSubtotal += myDiscountedSubtotal
          return _subtotal
        })
        _orderDetails.discount = _subtotal - _discountedSubtotal
      } else {
        _orderDetails.discount = _discountValue
      }
      _orderDetails.discount = parseFloat(_orderDetails.discount.toFixed(2))
      _orderDetails.total = _orderDetails.subtotal - _orderDetails.discount
    }
    setOrderDetails(_orderDetails)
  }

  const onSaveOrderProducts = async () => {
    setEnableProductFields(false)
    setPdSaveLoading(true)
    const res = await patchOrderProducts(
      orderDetails.orderid,
      orderDetails.dcharge,
      orderDetails.isDiscountPercent === discountOptions[0] ? 1 : 0,
      orderDetails.discount,
      orderDetails.paymentDiscount,
      orderDetails.subtotal,
      orderDetails.total,
      orderDetails.profit,
      orderProductDetails
    )
    if (res) {
      setSaveProductsDialog(false)
      setPdSaveLoading(false)
      if (res.status === 200) {
        showSuccess('Success', 'Order Products Updated')
        setOrderProductDetails(
          orderProductDetails.map((item) => {
            if (item.unlinked === true) {
              let i = res.data.idPair.findIndex((resItem) => resItem.nanoid === item.nanoid)
              delete item.unlinked
              item.id = res.data?.idPair[i]?.id
              item.status = 'Pending'
            }
            return item
          })
        )
        setActionCounter(actionCounter + 1)
      }
    }
  }
  const onAddressDetailsChange = (e, target) => {
    let { value } = e.target
    let _addressDetails = addressDetails
    _addressDetails[target] = value
    setAddressDetails({ ..._addressDetails })
  }
  const handleProductStatusChange = async () => {
    if (!proxyRowData || !proxyEvent) return
    setChangeStatusDialog(false)

    const res = await patchProductStatus(
      proxyRowData.id,
      proxyEvent.value,
      changeStatusNotification
    )
    if (res) {
      if (res?.status === 200) {
        showSuccess('Success', 'Status Changed Successfully')
        onOrderProductChange(proxyEvent.value, 'status', proxyRowData.id)
      }
      setChangeStatusNotification(true)
      setActionCounter(actionCounter + 1)
    }
  }
  /* TRACKING */

  const onTrackingDetailsChange = (index, target, value) => {
    let _addTrackingDetails = [...addTrackingDetails]
    _addTrackingDetails[index][target] = value
    setAddTrackingDetails(_addTrackingDetails)
  }

  const onAddTrackingDetailsAdd = () => {
    let _addTrackingDetails = [...addTrackingDetails]
    _addTrackingDetails.push({ ...emptyAddTrackingDetails })
    setAddTrackingDetails(_addTrackingDetails)
  }
  const onAddTrackingDetailsRemove = (index) => {
    let _addTrackingDetails = [...addTrackingDetails]
    _addTrackingDetails.splice(index, 1)
    setAddTrackingDetails(_addTrackingDetails)
  }
  const onTrackingResend = async (item) => {
    setTrackResend({
      item: item,
      loading: true,
    })
    const res = await resendTrackingId(item.productid)
    if (res) {
      setTrackResend({
        item: {},
        loading: false,
      })
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res?.data?.message,
          life: 3000,
        })
      }
    }
  }
  const onTrackingReship = async (item) => {
    setTrackReship({
      item: item,
      loading: true,
    })
    const res = await reshipTrackingId(item.id)
    if (res) {
      setTrackReship({
        item: {},
        loading: false,
      })
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res?.data?.message,
          life: 3000,
        })
      }
    }
  }
  const onTrackingDetailsDelete = async (item, index) => {
    const res = await removeTrackingId(item.orderid, item.id, item.trackingid, item.trackinglink)
    if (res && res.status === 200) {
      showSuccess('Success', 'Tracking Details Deleted')
      let _trackingDetails = [...trackingDetails]
      _trackingDetails.splice(index, 1)
      setTrackingDetails(_trackingDetails)
      setActionCounter(actionCounter + 1)
    } else {
      showError('Error', 'Tracking Details Unable To Deleted')
    }
  }

  const onSalesQualityScoreChange = async () => {
    const res = await patchSaleQualityScore(
      orderDetails.orderid,
      updatedSalesQAScore.saleQualityValues,
      updatedSalesQAScore.saleQualityScore
    )
    if (res && res.status === 200) {
      showSuccess('Success', 'Sales Quality Score Updated')
      setOrderDetails({
        ...orderDetails,
        saleQualityValues: updatedSalesQAScore.saleQualityValues,
        saleQualityScore: updatedSalesQAScore.saleQualityScore,
      })
      setIsEditingSalesQAScore(false)
    }
  }

  const onTrackingDetailsSubmit = async () => {
    setTrackSubmit(true)
    const temp = addTrackingDetails.map((item) => {
      delete item.productName
      return item
    })
    const res = await addTrackinIds(orderDetails.orderid, temp)
    if (res) {
      setTrackSubmit(false)
      if (res.status === 200) {
        showSuccess('Success', 'Tracking Details Added Successfully')
        let _trackingDetails = [...trackingDetails, ...res.data]
        setTrackingDetails(_trackingDetails)
        let temp = [...orderProductDetails]
        res.data.map((item) => {
          let index = temp.findIndex((item1) => item1.id === item.productid)
          if (index >= 0) {
            temp[index].status = 'Tracking'
          }
          return item
        })
        setOrderProductDetails(temp)
        setAddTrackingDetails([])
        setActionCounter(actionCounter + 1)
      } else {
        showError('Error', 'Tracking Details Not Added')
      }
    }
  }

  /* Templates */
  const orderProducIdTemplate = (rowData) => {
    return rowData?.id
  }

  const orderProductNameBodyTemplate = (rowData) => {
    return enableProductFields ? (
      <InputText
        style={{ border: 'none' }}
        disabled={!enableProductFields}
        value={rowData?.productName}
        onChange={(e) => onOrderProductChange(e.target.value, 'productName', rowData.id)}
      />
    ) : (
      rowData?.productName
    )
  }

  const orderStrengthBodyTemplate = (rowData) => {
    return enableProductFields ? (
      <Dropdown
        style={{ border: 'none' }}
        options={productStrengths.filter((item) => item.productCode === rowData?.productCode)}
        optionLabel="strengthName"
        disabled={!enableProductFields}
        value={rowData?._strength}
        onChange={async (e) => {
          onOrderProductChange(e.target.value, '_strength', rowData.id)
          onOrderProductChange(
            e.target.value.strengthName || e.target.value,
            'strength',
            rowData.id
          )
          onOrderProductChange('', '_quantity', rowData.id)
          onOrderProductChange(0, 'quantity', rowData.id)
          onOrderProductChange(0, 'quantityPrice', rowData.id)
          const res = await searchQuantity(e.target.value.strengthCode)
          if (res && res.status === 200) {
            onOrderProductChange(res.data, 'quantities', rowData.id)
          }
        }}
        editable
      />
    ) : (
      rowData?.strength
    )
  }

  const orderQuantityBodyTemplate = (rowData) => {
    return enableProductFields ? (
      <Dropdown
        style={{ border: 'none' }}
        options={rowData.quantities}
        optionLabel="quantity"
        emptyMessage="Reselect strength first or enter all value directly"
        disabled={!enableProductFields}
        value={rowData?._quantity}
        onChange={(e) => {
          onOrderProductChange(e.value, '_quantity', rowData.id)
          if (typeof e.value === 'object') {
            onOrderProductChange(e.value.quantity, 'quantity', rowData.id)
            onOrderProductChange(e.value.price, 'quantityPrice', rowData.id)
          }
          if (!isNaN(e.value)) {
            onOrderProductChange(e.value, 'quantity', rowData.id)
          }
        }}
        editable
      />
    ) : (
      rowData?.quantity
    )
  }

  const orderPillPriceBodyTemplate = (rowData) => {
    return enableProductFields ? (
      <InputNumber
        minFractionDigits={2}
        maxFractionDigits={2}
        min={0}
        step={0.1}
        style={{ border: 'none' }}
        disabled={!enableProductFields}
        value={rowData?.quantityPrice}
        onChange={(e) => onOrderProductChange(e.value, 'quantityPrice', rowData.id)}
      />
    ) : (
      '$' + rowData?.quantityPrice
    )
  }

  const orderTQtyBodyTemplate = (rowData) => {
    return enableProductFields ? (
      <InputNumber
        min={1}
        style={{ border: 'none' }}
        disabled={!enableProductFields}
        value={rowData?.totalQuantity}
        onChange={(e) => onOrderProductChange(e.value, 'totalQuantity', rowData.id)}
      />
    ) : (
      rowData?.totalQuantity
    )
  }

  const orderTPriceBodyTemplate = (rowData) => {
    return '$' + rowData?.total
  }

  const orderStatusBodyTemplate = (rowData) => {
    return (
      <Dropdown
        disabled={user?.role === 'agent'}
        options={status}
        style={{ border: 'none' }}
        value={rowData?.status}
        itemTemplate={(option) => (
          <span
            className={classNames({
              'opacity-50': !allowedProductStatus.includes(option),
            })}
          >
            {option}
          </span>
        )}
        onChange={(e) => {
          const val = e.target.value
          if (user?.role === 'agent') return
          if (!allowedProductStatus.includes(val)) return
          setChangeStatusDialog(true)
          setProxyRowData(rowData)
          setProxyEvent(e)
        }}
      />
    )
  }

  const orderShippingCompanyBodyTemplate = (rowData) => {
    return enableProductFields ? (
      <Dropdown
        style={{ border: 'none' }}
        disabled={!enableProductFields}
        value={rowData?.shippingCompany}
        onChange={(e) => onOrderProductChange(e.value, 'shippingCompany', rowData.id)}
        options={shippingTags}
      />
    ) : (
      rowData?.shippingCompany
    )
  }

  const hidePrescriptionDocumentDeleteDialog = () => {
    setPrescriptionDocumentDeleteVisible(false)
    setPrescriptionDocumentDeleteRecord({})
  }

  const hidePaymentFilesDeleteDialog = () => {
    setPaymentFilesDeleteVisible(false)
    setPaymentFilesDeleteRecord({})
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={orderDialogVisible}
        style={{ width: '1200px' }}
        header={`${orderDetails.orderLeadSource} Order Details`}
        modal
        className="p-fluid"
        footer={orderDialogFooter}
        onHide={hideDialog}
      >
        <div className=" p-formgrid p-grid">
          {/* x-header */}
          <div className="p-col-12 dg-header p-dialog-content">
            <div className="p-grid">
              <h4 className="p-col-12 p-md-4 p-m-0 p-py-2 p-text-center">
                Invoice-ID: {orderDetails?.orderid}
                {orderDetails?.blacklistedCustomer ? (
                  <>
                    <Tooltip target=".custom-target-icon" />
                    <span className="custom-target-icon p-ml-2" data-pr-tooltip="Blacklisted Order">
                      <i className="pi pi-ban" style={{ fontSize: '1em', color: 'red' }} />
                    </span>
                  </>
                ) : (
                  ''
                )}
              </h4>
              <h4 className="p-col-12 p-md-4 p-m-0 p-py-2 p-text-center">
                Name: {addressDetails?.fname} {addressDetails?.lname}
              </h4>
              <h4 className="p-col-12 p-md-4 p-m-0 p-py-2 p-text-center">
                {orderDetails?.website}
              </h4>
            </div>
          </div>
          {/* x-product-datatable */}
          <div className="p-col-12">
            <div className="card p-shadow-3">
              <DataTable
                value={orderProductDetails}
                rows={5}
                editMode="row"
                dataKey="id"
                showGridlines
                breakpoint="1000px"
                responsiveLayout="stack"
              >
                {/* <Column field="quantity" header="Quantity" editor={(options) => textEditor(options)} body={dateBodyTemplate} style={{ width: '20%' }}></Column> */}
                <Column header="ID" body={orderProducIdTemplate} style={{ width: '7%' }} />
                <Column
                  header="Product Name"
                  body={orderProductNameBodyTemplate}
                  style={{ width: '18%' }}
                />
                <Column
                  header="Strength"
                  body={orderStrengthBodyTemplate}
                  style={{ width: '8%' }}
                />
                <Column
                  header="No. Pills"
                  body={orderQuantityBodyTemplate}
                  style={{ width: '8%' }}
                />
                <Column
                  header="Pill Price"
                  body={orderPillPriceBodyTemplate}
                  style={{ width: '8%' }}
                />
                {/* <Column
                  readonly
                  header="Pill Cost"
                  body={orderPillCostBodyTemplate}
                  style={{ width: '8%' }}
                /> */}
                <Column header="No. Qty" body={orderTQtyBodyTemplate} style={{ width: '8%' }} />
                <Column
                  header="Total Price"
                  body={orderTPriceBodyTemplate}
                  style={{ width: '8%' }}
                />
                <Column header="Status" body={orderStatusBodyTemplate} style={{ width: '8%' }} />
                <Column
                  header="SC"
                  body={orderShippingCompanyBodyTemplate}
                  style={{ width: '2%' }}
                />
                <Column
                  header="Track"
                  body={(rd) => {
                    let disabled = false
                    let value = rd?.isShipmentDelayEnabled ? true : false
                    if (user?.role === 'agent') {
                      disabled = true
                    }
                    return (
                      <OrderProductINSDSwitch
                        disabled={disabled}
                        orderProductId={rd.id}
                        value={value}
                      />
                    )
                  }}
                  style={{ width: '2%' }}
                />
                {enableProductFields && (
                  <Column
                    header="Delete"
                    body={(rd) => {
                      return (
                        <Button
                          icon="pi pi-trash"
                          className="p-button-danger"
                          onClick={() => {
                            const removedProduct = orderProductDetails.filter(
                              (item) => item.id !== rd.id
                            )
                            setOrderProductDetails(removedProduct)
                            // setDeleteProductDialog(true)
                            // setDeleteProduct(rd)
                          }}
                        />
                      )
                    }}
                    style={{ width: '1%' }}
                  />
                )}
              </DataTable>
            </div>
          </div>
          {/* c-custNote */}
          <div className="p-col-12">
            <Message
              text={'Customer Note: ' + orderDetails?.customerNote || 'No Note'}
              className="p-shadow-3 card p-d-flex p-jc-start"
            />
            <Message
              text={`Notify Via: ${
                Array.isArray(orderDetails?.notifyVia) ? orderDetails?.notifyVia.join(', ') : ''
              }`}
              className="p-shadow-3 card p-d-flex p-jc-start"
            />
            <Message
              text={`Refill Date: ${
                orderDetails && orderDetails?.refillDate
                  ? orderDetails.formattedRefillDate
                  : 'No Refill Date Set'
              }`}
              className="p-shadow-3 card p-d-flex p-jc-start"
            />
          </div>
          {/* x-total  */}
          <div className="p-col-12 p-md-6">
            <div className="p-shadow-3 card">
              <div className="p-field p-col-12">
                <h4 className="">Invoice Details</h4>
                <hr />
              </div>
              <div className="p-formgrid p-grid ">
                <div className="p-fluid p-field p-col-4">
                  <label className="p-col-fixed p-text-bold">Discount Type</label>
                  <Dropdown
                    disabled={!enableProductFields}
                    options={discountOptions}
                    value={orderDetails.isDiscountPercent}
                    onChange={(e) => onOrderDetailsChange(e, 'isDiscountPercent')}
                  />
                </div>
                <div className="p-fluid p-field p-col-4">
                  <label className="p-col-fixed p-text-bold">Discount</label>
                  <InputNumber
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    min={0}
                    disabled={!enableProductFields}
                    value={orderDetails.discountValue}
                    onChange={(e) => {
                      onOrderDetailsChange(e, 'discountValue')
                    }}
                    onValueChange={(e) => onOrderDetailsChange(e, 'discountValue')}
                    prefix={orderDetails.isDiscountPercent === discountOptions[1] && '$'}
                    suffix={orderDetails.isDiscountPercent === discountOptions[0] && '%'}
                  />
                </div>
                <div className=" p-fluid p-field p-col-4">
                  <label className="p-col-fixed p-text-bold">Shipping</label>
                  <InputNumber
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    min={0}
                    disabled={!enableProductFields}
                    value={orderDetails.dcharge}
                    onValueChange={(e) => onOrderDetailsChange(e, 'dcharge')}
                    prefix="$"
                  />
                </div>
                <div className="p-col-12">
                  <DataTable
                    showGridlines
                    value={[
                      { name: 'Subtotal', value: orderDetails?.subtotal },
                      { name: 'Shipping', value: orderDetails?.dcharge },
                      { name: 'Discount', value: orderDetails?.discount },
                      ...(orderDetails?.couponDiscount && orderDetails?.couponDiscount !== '0.00'
                        ? [
                            {
                              name: `Coupon ${` (${orderDetails?.couponCode}) `}`,
                              value: orderDetails?.couponDiscount,
                            },
                          ]
                        : []),
                      { name: 'Total', value: orderDetails?.total },
                    ]}
                    responsiveLayout="scroll"
                  >
                    <Column
                      field="name"
                      body={(rd) => {
                        return rd.name === 'Total' ? (
                          <h5 className="p-m-0">{rd.name}</h5>
                        ) : (
                          <div>{rd.name}</div>
                        )
                      }}
                      header=""
                      style={{ width: '50%' }}
                    ></Column>
                    <Column
                      field="value"
                      body={(rd) => {
                        let val = rd.value ? rd.value : '0'
                        return rd.name === 'Total' ? (
                          <h5 className="p-m-0">${val}</h5>
                        ) : (
                          <div>${val}</div>
                        )
                      }}
                      header="Amount"
                      style={{ width: '50%' }}
                    ></Column>
                  </DataTable>
                </div>
              </div>
              {/* edit and save */}
              <div className="p-grid p-mt-3">
                {enableProductFields && (
                  <>
                    {orderDetails?.orderStatus === 'Pending' && (
                      <div className="p-col-12 p-md-4">
                        <Button
                          label="Add"
                          icon="pi pi-plus"
                          className="p-button-primary"
                          onClick={onProductAdd}
                        />
                      </div>
                    )}
                  </>
                )}

                {enableProductFields && (
                  <div className="p-col-12 p-md-4">
                    <Button
                      label="Save"
                      icon="pi pi-save"
                      className="p-button-info"
                      onClick={() => setSaveProductsDialog(true)}
                    />
                  </div>
                )}

                {enableProductFields && (
                  <div className="p-col-12 p-md-4">
                    <Button
                      label="Cancel"
                      icon="pi pi-times"
                      className="p-button-danger"
                      onClick={onDisableProductFields}
                    />
                  </div>
                )}

                {orderDetails?.orderStatus === 'Pending' && (
                  <>
                    {isEditable && (
                      <>
                        {!enableProductFields && (
                          <div className="p-col-12 p-md-4">
                            <Button
                              label="Edit Products"
                              icon="pi pi-pencil"
                              className="p-button-info"
                              onClick={() => {
                                setOriginalOrderProductDetails([...orderProductDetails])
                                onEnableProductFields()
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* x-payment */}
          <div className="p-col-12 p-md-6 ">
            <div className=" card p-shadow-3">
              <div className="p-field p-col-12">
                <h4>Payment Details</h4>
                <hr />
              </div>

              <div className="p-formgrid p-grid ">
                <div className="p-fluid p-field p-col-12 p-d-flex gap-1 p-flex-wrap">
                  {paymentMethods.map((item, idx) => (
                    <div key={idx} className="p-field-radiobutton">
                      <RadioButton
                        disabled={
                          !item.status ||
                          undeliveredOrderStatusOptions.includes(orderDetails?.orderStatus)
                        }
                        inputId={item.appName}
                        name="payment"
                        value={item.appName}
                        onChange={(e) => {
                          onOrderDetailsChange(e, 'paymentMethod')
                          let hashId = orderDetails?.hashId
                          const gatewayName = item.slug
                          const website = user?.website_url
                          setPaymentLinks([
                            `${website}/cart/checkout/payment?id=${hashId}&gateway-name=${gatewayName}`,
                          ])
                        }}
                        checked={orderDetails?.paymentMethod === item.appName}
                      />
                      <label htmlFor={item.appName}>{item.appName}</label>
                    </div>
                  ))}
                  {/* Code need to be review here */}
                  <div className="p-field-radiobutton">
                    <RadioButton
                      inputId={'all-payment-methods'}
                      value={'all'}
                      name="payment"
                      onChange={(e) => {
                        onOrderDetailsChange(e, 'paymentMethod')
                        let hashId = orderDetails?.hashId
                        const website = user?.website_url
                        setPaymentLinks([`${website}/cart/checkout/payment?id=${hashId}`])
                      }}
                      checked={orderDetails?.paymentMethod === 'all'}
                    />
                    <label htmlFor={'all-payment-methods'}>All Payment Methods</label>
                  </div>
                </div>
                <div className="p-fluid p-field p-col-12">
                  <Accordion activeIndex={0}>
                    <AccordionTab
                      header={
                        orderDetails?.isPaymentLinkThere ? 'View Payment Link' : 'Add Payment Link'
                      }
                    >
                      <div className="p-grid">
                        <Dropdown
                          emptyMessage="No option avaliable, enter or paste your own"
                          editable
                          options={paymentLinks}
                          id="paymentlink"
                          className="w-full p-mb-3"
                          placeholder="Enter Payment Link"
                          type="text"
                          value={orderDetails?.paymentlink ? orderDetails?.paymentlink : ''}
                          onChange={(e) => onOrderDetailsChange(e, 'paymentlink')}
                          disabled={orderDetails?.isPaymentLinkThere}
                          ref={paymentLinkRef}
                          itemTemplate={(option) => {
                            return <p className="m-0 p-0 max-w-10">{option}</p>
                          }}
                        />
                        {orderDetails?.isPaymentLinkThere ? (
                          <>
                            <div className="p-col-3">
                              <Button
                                className=" p-button-primary"
                                label="Open"
                                icon="pi pi-folder-open"
                                tooltip="Open Link"
                                tooltipOptions={{ position: 'bottom' }}
                                onClick={() => {
                                  window.open(orderDetails?.paymentlink)
                                }}
                                disabled={undeliveredOrderStatusOptions.includes(
                                  orderDetails?.orderStatus
                                )}
                              />
                            </div>

                            <div className="p-col-3">
                              <Button
                                className=" p-button-info"
                                label="Copy"
                                icon="pi pi-copy"
                                tooltip="Copy Link"
                                tooltipOptions={{ position: 'bottom' }}
                                onClick={() => {
                                  navigator.clipboard?.writeText(orderDetails?.paymentlink)
                                  toast.current.show({
                                    severity: 'success',
                                    summary: 'Link Copied',
                                    detail: 'Payment Link has been copied to clipboard',
                                    life: 3000,
                                  })
                                }}
                                disabled={undeliveredOrderStatusOptions.includes(
                                  orderDetails?.orderStatus
                                )}
                              />
                            </div>

                            <div className="p-col-3">
                              <Button
                                className=" p-button"
                                label="Resend"
                                icon="pi pi-reply"
                                tooltip="Resend Payment Mail"
                                tooltipOptions={{ position: 'bottom' }}
                                onClick={async () => {
                                  const res = await resendPaymentLink(orderDetails.orderid)
                                  if (res && res.status === 200) {
                                    showSuccess('Success', 'Payment Mail Resent')
                                  } else {
                                    showError('Error', res.data.error || 'Something went wrong')
                                  }
                                }}
                                disabled={undeliveredOrderStatusOptions.includes(
                                  orderDetails?.orderStatus
                                )}
                              />
                            </div>

                            <div className="p-col-3">
                              <Button
                                label="Delete"
                                className="p-button-danger"
                                icon="pi pi-trash"
                                tooltip="Delete Link"
                                tooltipOptions={{ position: 'bottom' }}
                                onClick={async () => {
                                  const res = await removePaymentLink(orderDetails?.orderid)
                                  if (res && res.status === 200) {
                                    showSuccess('Success', 'Payment Link Removed Successfully')
                                    let _orderDetails = { ...orderDetails }
                                    _orderDetails.paymentlink = null
                                    _orderDetails.paymentMethod = null
                                    _orderDetails.isPaymentLinkThere = false
                                    _orderDetails.discount = 0
                                    setOrderDetails(_orderDetails)
                                    // paymentLinkRef?.current?.focus()
                                    setActionCounter(actionCounter + 1)
                                  } else {
                                    showError('Error', 'Payment Link Not Removed')
                                  }
                                }}
                                disabled={undeliveredOrderStatusOptions.includes(
                                  orderDetails?.orderStatus
                                )}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="p-col-12">
                            {enableProductFields && (
                              <Message
                                className="p-field"
                                text="Save order-product details before submitting payment link"
                              />
                            )}
                            <Button
                              loading={plinkSubmit}
                              label={plinkSubmit ? 'Submitting' : 'Submit'}
                              disabled={
                                !orderDetails?.paymentlink || plinkSubmit || enableProductFields
                              }
                              icon="pi pi-check"
                              onClick={() => submitPaymentLink()}
                            />
                          </div>
                        )}
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>

          {/* x-address */}
          <div className="p-col-12 p-md-6 ">
            <div className="card p-shadow-3">
              <div className="p-field p-col-12">
                <h4 className="">Shipping Details</h4>
                <hr />
              </div>
              <div className="p-grid p-mt-1">
                <div className="p-field p-col-6">
                  <label htmlFor="fname" className="p-col-12 p-text-bold">
                    First Name
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="fname"
                      type="text"
                      value={addressDetails?.fname}
                      onChange={(e) => onAddressDetailsChange(e, 'fname')}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="lname" className="p-col-12 p-text-bold">
                    Last Name
                  </label>
                  <div className="p-col-12">
                    <InputText
                      id="lname"
                      value={addressDetails?.lname}
                      onChange={(e) => onAddressDetailsChange(e, 'lname')}
                      disabled={!enableFields}
                    />
                  </div>
                </div>

                <div className="p-field p-col-12">
                  <label htmlFor="addressline1" className="p-col-12 p-text-bold">
                    Address
                  </label>
                  <div className="p-col-12">
                    <InputTextarea
                      id="addressline1"
                      disabled={!enableFields}
                      value={addressDetails?.addressline1}
                      rows={2}
                      cols={30}
                      placeholder="Enter Address"
                      onChange={(e) => onAddressDetailsChange(e, 'addressline1')}
                    />
                  </div>
                </div>

                <div className="p-field p-col-6">
                  <label htmlFor="city" className="p-col-12 p-text-bold">
                    City
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="city"
                      type="text"
                      value={addressDetails?.city}
                      onChange={(e) => onAddressDetailsChange(e, 'city')}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="state" className="p-col-12 p-text-bold">
                    State
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="state"
                      type="state"
                      value={addressDetails?.state}
                      onChange={(e) => onAddressDetailsChange(e, 'state')}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="postalcode" className="p-col-12 p-text-bold">
                    Zip
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="postalcode"
                      type="text"
                      value={addressDetails?.postalcode}
                      onChange={(e) => onAddressDetailsChange(e, 'postalcode')}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="country" className="p-col-12 p-text-bold">
                    Country
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="country"
                      type="text"
                      value={addressDetails?.country}
                      onChange={(e) => onAddressDetailsChange(e, 'country')}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="email" className="p-col-12 p-text-bold">
                    Email
                  </label>
                  <div className="p-col-12">
                    <HideDetails
                      id={'email'}
                      value={addressDetails?.email}
                      type="input"
                      isDisabled={!enableFields}
                      onChange={(e) => onAddressDetailsChange(e, 'email')}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="phone" className="p-col-12 p-text-bold">
                    Phone
                  </label>
                  <div className="p-col-12">
                    {/* {enableFields ? (
                      <InputText
                        value={addressDetails?.phone}
                        onChange={(e) => onAddressDetailsChange(e, 'phone')}
                      />
                    ) : ( */}
                    <HideDetails
                      id={'phone'}
                      value={addressDetails?.phone}
                      type="input"
                      copyRegexReplace={/[^0-9]/g}
                      isDisabled={!enableFields}
                      onChange={(e) => onAddressDetailsChange(e, 'phone')}
                    />
                    {/* )} */}
                  </div>
                </div>
                <div className="p-field p-grid p-col-12">
                  {enableFields && (
                    <div className="p-col-12 p-md-4">
                      <Button
                        label="Save"
                        icon="pi pi-pencil"
                        className="p-button-info"
                        onClick={() => setSaveAddressDialog(true)}
                      />
                    </div>
                  )}
                  {enableFields && (
                    <div className="p-col-12 p-md-4">
                      <Button
                        label="Cancel"
                        icon="pi pi-times"
                        className="p-button-danger"
                        onClick={onDisableFields}
                      />
                    </div>
                  )}
                  {['Pending', 'Processed'].includes(orderDetails?.orderStatus) && (
                    <>
                      {isEditable && (
                        <>
                          {!enableFields && (
                            <>
                              {!requestInfo && (
                                <div className="p-col-12 p-md-4">
                                  <Button
                                    label="Edit Address"
                                    icon="pi pi-pencil"
                                    className="p-button-info"
                                    onClick={onEnableFields}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* x-tracking and sales quality*/}
          <div className="p-col-12 p-md-6">
            {/* tracking */}
            <div className="card p-shadow-3">
              <h4>Tracking Details</h4>
              <hr />
              <Accordion>
                <AccordionTab header="Add Tracking Details">
                  <div>
                    {addTrackingDetails.map((item, index) => {
                      return (
                        <div key={index} className="" style={{ borderRadius: '0px' }}>
                          <h6 className="p-my-2">Tracking {index + 1}</h6>
                          <div className=" p-grid  p-formgrid">
                            <div className="p-field p-col-6">
                              <Dropdown
                                value={item.productName}
                                options={orderProductDetails}
                                optionLabel="productName"
                                valueTemplate={(e) => {
                                  if (!e) {
                                    return <span>Select Product</span>
                                  } else {
                                    return `${e.productName} | ${e.id}`
                                  }
                                }}
                                itemTemplate={(e) => `${e.productName} | ${e.id}`}
                                onChange={(e) => {
                                  onTrackingDetailsChange(index, 'productName', e.value)
                                  onTrackingDetailsChange(index, 'productid', e.value.id)
                                }}
                                placeholder="Select a product"
                                disabled={undeliveredOrderStatusOptions.includes(
                                  orderDetails?.orderStatus
                                )}
                              />
                            </div>

                            <div className=" p-field p-col-6">
                              <InputNumber
                                placeholder="Quantity"
                                value={item.quantity}
                                onChange={(e) =>
                                  onTrackingDetailsChange(index, 'quantity', e.value)
                                }
                                disabled={undeliveredOrderStatusOptions.includes(
                                  orderDetails?.orderStatus
                                )}
                              />
                            </div>
                            <div className=" p-field p-col-11">
                              <InputText
                                placeholder="Tracking Id"
                                value={item.trackingid}
                                onChange={(e) =>
                                  onTrackingDetailsChange(
                                    index,
                                    'trackingid',
                                    e.target.value.replaceAll(' ', '')
                                  )
                                }
                                disabled={undeliveredOrderStatusOptions.includes(
                                  orderDetails?.orderStatus
                                )}
                              />
                            </div>
                            <div className="p-field p-col-1">
                              <Button
                                label=""
                                icon="pi pi-trash"
                                className="p-button-danger p-mr-3"
                                autoFocus
                                onClick={() => onAddTrackingDetailsRemove(index)}
                                tooltip="Remove This Row"
                                tooltipOptions={{ position: 'bottom' }}
                                disabled={undeliveredOrderStatusOptions.includes(
                                  orderDetails?.orderStatus
                                )}
                              />
                            </div>
                            <div className="p-field p-col-12">
                              <InputText
                                className=""
                                placeholder="Tracking Link"
                                value={item.trackinglink}
                                onChange={(e) =>
                                  onTrackingDetailsChange(index, 'trackinglink', e.target.value)
                                }
                                disabled={undeliveredOrderStatusOptions.includes(
                                  orderDetails?.orderStatus
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  {/* <InputText className="p-mt-2" id="shippinglink" placeholder="Enter Shipping Name" type="text" value={product.shippingname} onChange={(e) => onInputChange(e, 'shippingname')} /> */}
                  <div className="p-grid p-justify-end p-mt-3">
                    <div className="p-col-6">
                      <Button
                        icon="pi pi-plus"
                        label="Add Row"
                        className="p-button-info"
                        onClick={onAddTrackingDetailsAdd}
                        disabled={undeliveredOrderStatusOptions.includes(orderDetails?.orderStatus)}
                      />
                    </div>
                    <div className="p-col-6">
                      <Button
                        loading={trackSubmit}
                        label={trackSubmit ? 'Submitting' : 'Submit'}
                        icon="pi pi-check"
                        disabled={!trackingValid || trackSubmit}
                        onClick={onTrackingDetailsSubmit}
                      />
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
              <DataTable value={trackingDetails} showGridlines stripedRows>
                <Column header="Product Id" field="productid" />
                <Column header="Status" field="status" />
                <Column header="Qty" field="quantity" />
                <Column header="Track-Id" field="trackingid" />
                <Column
                  header="Action"
                  body={(item, { rowIndex }) => {
                    return (
                      <div className="p-d-flex gap-1by4">
                        <Button
                          tooltip="Open Link"
                          icon="pi pi-map-marker"
                          onClick={() => {
                            window.open(item?.trackinglink, '_blank')
                          }}
                        />
                        <Button
                          tooltip="Copy Link"
                          className="p-button-info"
                          icon="pi pi-copy"
                          onClick={() => {
                            navigator.clipboard?.writeText(item?.trackinglink)
                          }}
                        />
                        <Button
                          tooltip="Delete Tracking"
                          className="p-button-danger"
                          icon="pi pi-trash"
                          onClick={() => onTrackingDetailsDelete(item, rowIndex)}
                        />
                        <Button
                          tooltip="Resend Tracking"
                          loading={trackResend?.loading && trackResend?.item?.id === item.id}
                          disabled={trackResend?.loading && trackResend?.item?.id === item.id}
                          className="p-button"
                          icon="pi pi-send"
                          onClick={() => {
                            onTrackingResend(item)
                          }}
                        />
                        <Button
                          tooltip="Reship"
                          loading={trackReship?.loading && trackReship?.item?.id === item.id}
                          disabled={trackReship?.loading && trackReship?.item?.id === item.id}
                          className="p-button-info"
                          icon="pi pi-refresh"
                          onClick={() => {
                            onTrackingReship(item)
                          }}
                        />
                      </div>
                    )
                  }}
                />
              </DataTable>
              <h6>
                Total Quantity:{' '}
                {trackingDetails.reduce((accumulator, item) => accumulator + item.quantity, 0)}
              </h6>
            </div>
            {/* sale quality */}
            {['superadmin', 'admin', 'manager', 'operations'].includes(user.role) && (
              // <div className="p-col-12 p-md-4">
              <div className="card p-shadow-3">
                <h4 className="p-d-flex p-jc-between">
                  <span>Quality Analysis</span>
                </h4>
                <hr />
                <div className="p-field p-mb-4">
                  <label htmlFor="saleQuality" className="p-col-12">
                    Quality Values :{' '}
                    {isEditingSalesQAScore
                      ? updatedSalesQAScore.saleQualityScore
                      : orderDetails?.saleQualityScore}
                  </label>
                  <MultiSelect
                    disabled={!isEditingSalesQAScore}
                    id="saleQuality"
                    placeholder="Select Sales Quality"
                    value={
                      isEditingSalesQAScore
                        ? updatedSalesQAScore.saleQualityValues
                        : orderDetails?.saleQualityValues
                    }
                    onChange={(e) => {
                      let score = 0
                      e.target.value.forEach((element) => {
                        const elem = salesQAScoreOptions.find(
                          (el) => el.saleQualityValues === element
                        )
                        score += elem.saleQualityScore
                      })
                      handleObjChange(setUpdatedSalesQAScore, 'saleQualityValues', e.target.value)
                      handleObjChange(setUpdatedSalesQAScore, 'saleQualityScore', score)
                    }}
                    options={salesQAScoreOptions}
                    optionLabel="saleQualityValues"
                    optionValue="saleQualityValues"
                  />
                </div>
                {isEditingSalesQAScore && (
                  <div className="p-grid">
                    <div className="p-col-6">
                      <Button
                        label="Save"
                        icon="pi pi-pencil"
                        className="p-button-info"
                        onClick={onSalesQualityScoreChange}
                      />
                    </div>
                    <div className="p-col-6">
                      <Button
                        label="Cancel"
                        className="p-button-danger"
                        icon="pi pi-times"
                        onClick={() => {
                          setUpdatedSalesQAScore({
                            saleQualityValues: orderDetails?.saleQualityValues || [],
                            saleQualityScore: orderDetails?.saleQualityScore || 0,
                          })
                          setIsEditingSalesQAScore(!isEditingSalesQAScore)
                        }}
                      />
                    </div>
                  </div>
                )}
                {!isEditingSalesQAScore && (
                  <div className="">
                    <Button
                      label="Edit QA"
                      icon="pi pi-pencil"
                      className="p-button-info"
                      onClick={() => {
                        setUpdatedSalesQAScore({
                          saleQualityValues: orderDetails?.saleQualityValues || [],
                          saleQualityScore: orderDetails?.saleQualityScore || 0,
                        })
                        setIsEditingSalesQAScore(!isEditingSalesQAScore)
                      }}
                    />
                  </div>
                )}
              </div>
              // </div>
            )}
          </div>
          {/* Prescription Documents  */}
          <div className="p-col-12 p-md-6 ">
            <div className="card p-shadow-3">
              <h4>Prescription Documents</h4>
              <hr />
              <div>
                <DataTable
                  loading={prescriptionDocumentsTableLoading}
                  value={prescriptionDocuments}
                  showGridlines
                  stripedRows
                >
                  <Column
                    header="Image"
                    field="path"
                    body={(rd) => {
                      return (
                        <Image
                          src={generateS3Path(rd.path)}
                          zoomSrc={rd.path}
                          alt="Image"
                          width="80"
                          height="80"
                          preview
                          imageStyle={{ objectFit: 'cover' }}
                        />
                      )
                    }}
                    style={{ width: '2%' }}
                  />
                  <Column
                    header="Name"
                    field="name"
                    body={(rd) => {
                      const date = new Date(rd?.createdAt)
                      return (
                        <div>
                          <h6 className="p-mb-0 p-text-capitalize">{rd.name}</h6>
                          <p className="p-mb-0">
                            <span style={{ color: 'gray' }}>Uploaded By :</span>{' '}
                            <span className="text-white">{rd?.meta?.createdBy}</span>
                          </p>
                          <p className="p-mb-0">
                            <span style={{ color: 'gray' }}>Uploaded On :</span>{' '}
                            <span className="text-white">{format(date, 'MMMM dd, yyyy')}</span>
                          </p>
                        </div>
                      )
                    }}
                  />
                  <Column
                    header="Action"
                    style={{ width: '1%' }}
                    body={(rd) => {
                      return (
                        <div className="p-d-flex p-ai-center p-jc-center">
                          <Button
                            icon="pi pi-trash"
                            className="p-button-danger"
                            onClick={() => {
                              // handlePrescriptionDocumentDelete(rd)
                              setPrescriptionDocumentDeleteVisible(true)
                              setPrescriptionDocumentDeleteRecord(rd)
                            }}
                          />
                        </div>
                      )
                    }}
                  />
                </DataTable>
              </div>
              <div className=" p-mt-3">
                {prescriptionAttachments?.map((item, idx) => {
                  let { name, path } = item
                  if (name.length > 40) {
                    name = name.substring(0, 15) + '...' + name.substring(name.length - 20)
                  }
                  return (
                    <div key={idx} className="relative p-grid p-formgrid">
                      <div className=" p-d-flex p-jc-center p-field p-col-11">
                        <a
                          className={`p-button p-button-outlined p-button-secondary`}
                          key={name}
                          href={path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {name}
                        </a>
                      </div>
                      <div className=" p-d-flex p-jc-center p-field p-col-1">
                        <Button
                          className="p-button-danger"
                          onClick={() =>
                            removeFiles(
                              item.uid,
                              prescriptionAttachments,
                              setPrescriptionAttachments
                            )
                          }
                          icon="pi pi-trash"
                        />
                      </div>
                      <span
                        className={`${item.new ? 'blob bottom-15-px left-0-px p-mx-0' : ''}`}
                      ></span>
                    </div>
                  )
                })}
              </div>
              <div className="relative p-grid p-formgrid">
                <div className="p-field p-col-6">
                  <input
                    type="file"
                    className="p-hidden abcd"
                    accept="*"
                    ref={prescriptionDocumentRef}
                    multiple
                    onChange={(e) => {
                      handleFilesUpload(e, setPrescriptionAttachments)
                    }}
                  />

                  <Button
                    icon="pi pi-plus"
                    label="Upload New"
                    className="p-button-info"
                    disabled={prescriptionDocumentsTableLoading}
                    onClick={() => {
                      prescriptionDocumentRef.current.click()
                    }}
                  />
                </div>
                {prescriptionAttachments.length > 0 && (
                  <div className="p-field p-col-6">
                    <Button
                      icon="pi pi-check"
                      label={prescriptionDocumentsTableLoading ? 'Submitting' : 'Submit'}
                      loading={prescriptionDocumentsTableLoading}
                      className="p-button-primary"
                      onClick={() => {
                        handlePrescriptionDocumentsUpload()
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* payment screenshot  */}
          <div className="p-col-12 p-md-6 ">
            <div className="card p-shadow-3">
              <h4>Payment Screenshot</h4>
              <hr />
              <div>
                <DataTable
                  loading={paymentImageTableLoading}
                  value={paymentImageFiles}
                  showGridlines
                  stripedRows
                >
                  <Column
                    header="Image"
                    field="path"
                    body={(rd) => {
                      return (
                        <Image
                          src={generateS3Path(rd.path)}
                          zoomSrc={rd.path}
                          alt="Image"
                          width="80"
                          height="80"
                          preview
                          imageStyle={{ objectFit: 'cover' }}
                        />
                      )
                    }}
                    style={{ width: '2%' }}
                  />
                  <Column
                    header="Name"
                    field="name"
                    body={(rd) => {
                      const date = new Date(rd?.createdAt)
                      return (
                        <div>
                          <h6 className="p-mb-0 p-text-capitalize">{rd.name}</h6>
                          <p className="p-mb-0">
                            <span style={{ color: 'gray' }}>Uploaded By :</span>{' '}
                            <span className="text-white">{rd?.meta?.createdBy}</span>
                          </p>
                          <p className="p-mb-0">
                            <span style={{ color: 'gray' }}>Uploaded On :</span>{' '}
                            <span className="text-white">{format(date, 'MMMM dd, yyyy')}</span>
                          </p>
                        </div>
                      )
                    }}
                  />
                  <Column
                    header="Action"
                    style={{ width: '1%' }}
                    body={(rd) => {
                      return (
                        <div className="p-d-flex p-ai-center p-jc-center">
                          <Button
                            icon="pi pi-trash"
                            className="p-button-danger"
                            onClick={() => {
                              // handlePaymentImageDelete(rd)
                              setPaymentFilesDeleteVisible(true)
                              setPaymentFilesDeleteRecord(rd)
                            }}
                          />
                        </div>
                      )
                    }}
                  />
                </DataTable>
              </div>
              <div className=" p-mt-3">
                {paymentAttachments?.map((item, idx) => {
                  let { name, path } = item
                  if (name.length > 40) {
                    name = name.substring(0, 15) + '...' + name.substring(name.length - 20)
                  }
                  return (
                    <div key={idx} className="relative p-grid p-formgrid">
                      <div className=" p-d-flex p-jc-center p-field p-col-11">
                        <a
                          className={`p-button p-button-outlined p-button-secondary`}
                          key={name}
                          href={path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {name}
                        </a>
                      </div>
                      <div className=" p-d-flex p-jc-center p-field p-col-1">
                        <Button
                          className="p-button-danger"
                          onClick={() =>
                            removeFiles(item.uid, paymentAttachments, setPaymentAttachments)
                          }
                          icon="pi pi-trash"
                        />
                      </div>
                      <span
                        className={`${item.new ? 'blob bottom-15-px left-0-px p-mx-0' : ''}`}
                      ></span>
                    </div>
                  )
                })}
              </div>
              <div className="relative p-grid p-formgrid">
                <div className="p-field p-col-6">
                  <input
                    type="file"
                    className="p-hidden abcd"
                    accept="*"
                    ref={paymentScreenshotRef}
                    multiple
                    onChange={(e) => {
                      handleFilesUpload(e, setPaymentAttachments)
                    }}
                  />

                  <Button
                    icon="pi pi-plus"
                    label="Upload New"
                    className="p-button-info"
                    disabled={paymentImageTableLoading}
                    onClick={() => {
                      paymentScreenshotRef.current.click()
                    }}
                  />
                </div>
                {paymentAttachments.length > 0 && (
                  <div className="p-field p-col-6">
                    <Button
                      icon="pi pi-check"
                      label={paymentImageTableLoading ? 'Submitting' : 'Submit'}
                      loading={paymentImageTableLoading}
                      className="p-button-primary"
                      onClick={() => {
                        handlePaymentFilesUpload()
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* x-timeline */}
          <div className="p-col-12">
            <div className="card p-shadow-3">
              <h4 className="p-d-flex p-jc-between p-ai-center">
                <span>Order Timeline</span>
              </h4>
              <hr />
              {orderHistory.length > 0 ? (
                <div className="widget-timeline">
                  <div className="timeline-header p-d-flex p-jc-between p-ai-center"></div>
                  <div className="timeline-content">
                    <Timeline
                      value={orderHistory}
                      marker={marker}
                      content={content}
                      className="custimized-timeline"
                    />
                  </div>
                </div>
              ) : (
                <h5 className="p-text-center">
                  <i className="pi pi-exclamation-circle p-mx-2" />
                  No Order History
                </h5>
              )}
            </div>
          </div>
          {/* Isn't being used anymore */}
          {/* requests */}
          {/* <div className="p-col-12 p-md-4">
            <div className="card p-shadow-3">
              <h4 className="p-d-flex p-jc-between">
                <span>Requests</span>
              </h4>
              <hr />
            </div>
          </div> */}
        </div>
      </Dialog>
      {/* Add product */}
      <AddNewProductInOrder
        visible={addNewProductVisible}
        setVisible={setAddNewProductVisible}
        newProduct={newProduct}
        setNewProduct={setNewProduct}
        products={orderProductDetails}
        setProducts={setOrderProductDetails}
      />
      {/* Change Status */}
      <Dialog
        visible={changeStatusDialog}
        style={{ width: '450px' }}
        header="Status Change Confirmation"
        modal
        footer={
          <>
            <Button
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setChangeStatusDialog(false)}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              onClick={handleProductStatusChange}
              autoFocus
            />
          </>
        }
        onHide={() => setChangeStatusDialog(false)}
      >
        <div className="confirmation-content  p-d-flex p-ai-center">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          <div>
            {orderDetails && (
              <span>
                Are you sure you want change status to {proxyEvent?.value} for product (
                {proxyRowData?.id})?
              </span>
            )}
            {resend_status.includes(proxyEvent?.value) ? (
              <div className="p-mt-3 p-d-flex gap-1 p-ai-center ">
                <Checkbox
                  inputId="changeStatusNotification"
                  checked={changeStatusNotification}
                  onChange={(e) => setChangeStatusNotification(e.checked)}
                />
                <label htmlFor="changeStatusNotification">
                  Send notification to customer regrading this product progress
                </label>
              </div>
            ) : (
              <div className="p-mt-3">
                <label htmlFor="">This change wont send any notification to customer</label>
              </div>
            )}
          </div>
        </div>
      </Dialog>

      {/* Confirmation before saving order products */}
      <Dialog
        visible={saveProductsDialog}
        style={{ width: '450px' }}
        header="Save Products Confirmation"
        modal
        footer={
          <>
            <Button
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setSaveProductsDialog(false)}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              onClick={() => {
                onSaveOrderProducts()
              }}
              loading={pdSaveLoading}
              disabled={pdSaveLoading}
              autoFocus
            />
          </>
        }
        onHide={() => setSaveProductsDialog(false)}
      >
        <div className="confirmation-content  p-d-flex p-ai-center">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          {orderDetails && <span>Are you sure you want to save products detail?</span>}
        </div>
      </Dialog>

      {/* Confirmation before saving address */}
      <Dialog
        visible={saveAddressDialog}
        style={{ width: '450px' }}
        header="Save Address Confirmation"
        modal
        footer={
          <>
            <Button
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setSaveAddressDialog(false)}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              onClick={() => {
                saveAddressDeatils()
                setSaveAddressDialog(false)
              }}
              autoFocus
            />
          </>
        }
        onHide={() => setSaveAddressDialog(false)}
      >
        <div className="confirmation-content  p-d-flex p-ai-center">
          <i className="pi pi-exclamation-triangle p-mr-4" style={{ fontSize: '2rem' }} />
          {orderDetails && <span>Are you sure you want to save shipping details?</span>}
        </div>
      </Dialog>
      <WarningDialog
        onHide={hidePrescriptionDocumentDeleteDialog}
        visible={prescriptionDocumentDeleteVisible}
        onSubmit={handlePrescriptionDocumentDelete}
        loading={prescriptionDocumentsTableLoading}
      />
      <WarningDialog
        onHide={hidePaymentFilesDeleteDialog}
        visible={paymentFilesDeleteVisible}
        onSubmit={handlePaymentFileDelete}
        loading={paymentImageTableLoading}
      />
    </>
  )
}
