import axios from 'axios'
import { endOfDay, format, startOfDay } from 'date-fns'
import endpoints from './endpoints'

export const postShipmentDelayFollowupRecord = async ({
  orderId: id,
  orderProductId,
  followupMedium,
  note,
  status,
  estimatedTime,
  nextFollowupTime,
  clientFeedback,
}) => {
  try {
    return await axios.post(endpoints.shipmentDelay.followups + id, {
      followupMedium,
      note,
      status,
      estimatedTime,
      nextFollowupTime,
      clientFeedback,
      orderProductId,
    })
  } catch (error) {
    return error.response
  }
}

export const getShipmentDelayFollowupRecords = async (orderId, orderProductId) => {
  try {
    return await axios.get(endpoints.shipmentDelay.followups + orderId, {
      params: {
        orderProductId,
      },
    })
  } catch (error) {
    return error.response
  }
}

export const getShipmentDelayRecords = async ({ dateFrom, dateTo, expiredFlag }) => {
  try {
    return await axios.get(endpoints.shipmentDelay.index, {
      params: {
        dateFrom,
        dateTo,
        expiredFlag,
      },
    })
  } catch (error) {
    return error.response
  }
}

export const getShipmentDelayNotes = async (orderProductId) => {
  try {
    return await axios.get(endpoints.shipmentDelay.note + orderProductId)
  } catch (error) {
    return error.response
  }
}

export const addShipmentDelayNote = async (orderId, note, orderProductId) => {
  try {
    return await axios.post(endpoints.shipmentDelay.note + orderId, {
      note,
      orderProductId,
    })
  } catch (error) {
    return error.response
  }
}

export const editShipmentDelayNote = async (id, note) => {
  try {
    return await axios.patch(endpoints.shipmentDelay.note + id, { note })
  } catch (error) {
    return error.response
  }
}

export const deleteShipmentDelayNote = async (id) => {
  try {
    return await axios.delete(endpoints.shipmentDelay.note + id)
  } catch (error) {
    return error.response
  }
}
