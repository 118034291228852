import { TabPanel, TabView } from 'primereact/tabview'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { AutoComplete } from 'primereact/autocomplete'
import { MultiSelect } from 'primereact/multiselect'
import { format } from 'date-fns'
import {
  searchByOrderOrName,
  getAllCategoryDetails,
  postNewComplaintIssue,
  getAllComplaintIssues,
  putComplaintAgentById,
  getReopenRequestById,
  patchRequestStatus,
} from '../../api'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'

import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { handleObjChange } from '../../functions/setter'
import { Editor } from '@tinymce/tinymce-react'
import { Message } from 'primereact/message'
import { Link } from 'react-router-dom'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useGlobalContext } from '../../context'
import { Toast } from 'primereact/toast'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import ComplaintNotesDialog from '../../components/mycomponents/ComplaintAndCases/ComplaintNotesDialog'
import ComplaintDetailsDialog from '../../components/mycomponents/ComplaintAndCases/ComplaintDetailsDialog'
import ConfirmationDialogWithInput from '../../components/mycomponents/Dialog/ConfirmationDialogWithInput'
import { Password } from 'primereact/password'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import classNames from 'classnames'
import MyDatesPicker from '../../components/mini/MyDatesPicker'
import { getSubIssues } from '../../api/complaintsandcases'
import useQuery from '../../hooks/useQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { assignDates, dateFilters, dtFilenameDate } from '../../functions/myDateFns'
import ExportButton from '../../components/mini/ExportButton'
import ReactQuill from 'react-quill'
import validatePhoneNumber from '../../functions/validatePhoneNumber'
import SendMailDialog from '../../components/mini/SendMailDialog'

export default function ComplaintAndCasesPage() {
  const { agents, user, colorScheme, setSendEmailDialogVisible, toast } = useGlobalContext()
  const open_dt = useRef(null)
  const close_dt = useRef(null)
  const history = useHistory()
  const query = useQuery()
  const reopenId = parseInt(query.get('reopen_id'))
  //* Used in multiple places

  const complaintCloserCategories = [
    'Resolved on Call',
    'Resolved on Chat',
    'Resolved via Email',
    'Issue Withdrawn',
    'Order was reshipped',
    'Money was refunded',
    'Other',
  ]

  //complaint statuses
  const complaintStatuses = [
    { label: 'Open', value: 'open' },
    { label: 'Reopen', value: 'reopen' },
  ]

  //* Empty Objects for state
  const emptyComplaint = {
    invoiceId: '',
    issueCategory: '',
    customerName: '',
    customerId: '',
    issueSubCategory: '',
    openingNote: '',
    orderAgent: '',
    assignTo: '',
    customerPhone: '',
    customerEmail: '',
  }
  const emptyManualComplaint = {
    orderDetails: '',
    customerName: '',
    customerPhone: '',
    customerEmail: '',
    issueCategory: '',
    issueSubCategory: '',
    openingNote: '',
    assignTo: '',
  }
  const emptyReopenDetails = {
    compId: '',
    agentId: '',
    agent: '',
    reopenReason: '',
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  const emptyReopenConfirmation = {
    status: '',
    dialogVisible: false,
  }

  const empty_sendEmail = {
    email: '',
    subject: '',
    body: '',
    relatedTo: '',
    refId: '',
  }

  //* States Start
  const [openTableFilter, setOpenTableFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedToId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    orderBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
    issueType: { value: null, matchMode: FilterMatchMode.IN },
    issueSub: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  })
  const [closeTableFilter, setCloseTableFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedToId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    orderBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
    closedById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    closeType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    issueType: { value: null, matchMode: FilterMatchMode.IN },
    issueSub: { value: null, matchMode: FilterMatchMode.IN },
  })
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [dateCode, setDateCode] = useState(dateFilters[1].code)
  const [dateRange, setDateRange] = useState(null)
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))
  const [dateCode2, setDateCode2] = useState(dateFilters[1].code)
  const [dateRange2, setDateRange2] = useState(null)
  const [dateFilter2, setDateFilter2] = useState(assignDates(dateFilters[1].code))

  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [allOpenComplaints, setAllOpenComplaints] = useState([])
  const [allClosedComplaints, setAllClosedComplaints] = useState([])
  const [openIssuesTableLoading, setOpenIssuesTableLoading] = useState(false)
  const [closedIssuesTableLoading, setClosedIssuesTableLoading] = useState(false)
  const [issuesCategories, setIssuesCategories] = useState([])
  const [newManualComplaint, setNewManualComplaint] = useState(emptyManualComplaint)
  const [manualDialogVisible, setManualDialogVisible] = useState(false)
  const [searchAddressKeyword, setSearchAddressKeyword] = useState('')
  const [avaliableAddressInDb, setAvaliableAddressInDb] = useState([])
  const [filteredAddressInDbDisplayAry, setFilteredAddressInDbDisplayAry] = useState([])
  const [dialogVisible, setDialogVisible] = useState(false)
  const [newComplaint, setNewComplaint] = useState(emptyComplaint)
  const [updateAgentDialogVisible, setUpdateAgentDialogVisible] = useState(false)
  const [password, setPassword] = useState('')
  const [updateAgentDetails, setUpdateAgentDetails] = useState('')
  const [loading, setLoading] = useState(false)
  const [reopenComplaintDialogVisible, setReopenComplaintDialogVisible] = useState(false)
  const [reopenRequestDetails, setReopenRequestDetails] = useState(emptyReopenDetails)
  const [reopenRequestConfirmation, setReopenRequestConfirmation] =
    useState(emptyReopenConfirmation)
  const [sendEmail, setSendEmail] = useState(empty_sendEmail)
  //* States end

  //* Function For Autocomplete
  const searchOrderAddressOnInput = async (event) => {
    setTimeout(async () => {
      let _filteredOptions
      if (!event.query.trim().length) {
        _filteredOptions = [...avaliableAddressInDb]
      } else {
        let dbEData = await getAvailableAddress()
        _filteredOptions = dbEData.map((item) => {
          item.option = `${
            item.orderid
          } | ${item.fname.trim()} ${item.lname.trim()} | ${item.addressline1.trim()} | ${
            item.country
          }`
          return item
        })
      }
      setFilteredAddressInDbDisplayAry(_filteredOptions)
    }, 500)
  }

  //* Handler to fetch the available addresses(Used by searchOrderAddressOnInput function above)
  const getAvailableAddress = async () => {
    const res = await searchByOrderOrName(searchAddressKeyword)
    if (res && res.status === 200) {
      let data = res.data || []
      setAvaliableAddressInDb(data)
      return data
    } else {
      return []
    }
  }

  //* Function to setValues when you select an address
  const onAddressSelect = (value) => {
    handleObjChange(setNewComplaint, 'invoiceId', value.orderid)
    // if there was an agent assigned to order, assign the complaint directly to him
    if (value.masterid) {
      handleObjChange(setNewComplaint, 'orderAgent', value.masterid)
      if (['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role)) {
        handleObjChange(setNewComplaint, 'assignTo', value.masterid)
      }
    }

    // if agent is raising the complaint, assign it to them
    if (user.role === 'agent') {
      handleObjChange(setNewComplaint, 'assignTo', user.id)
    }
    // if customer id is availabel, add it
    if (value.custid && Number(value.custid)) {
      handleObjChange(setNewComplaint, 'customerId', value.custid)
    }

    handleObjChange(setNewComplaint, 'customerEmail', value.email || '')
    handleObjChange(setNewComplaint, 'customerPhone', value.phone || '')

    let userName = `${value.fname.trim()} ${value.lname.trim()}`
    handleObjChange(setNewComplaint, 'customerName', userName)
    setSearchAddressKeyword(value.orderid)
  }

  //* Handler to fetch all the issue categories from the database
  const fetchMainCategories = async () => {
    const res = await getAllCategoryDetails()
    if (res && res.status === 200) {
      setIssuesCategories(res.data)
    }
  }
  useEffect(() => {
    fetchMainCategories()
  }, [])
  // useEffect(() => {
  //   getRelaventSubIssue()
  // }, [openTableFilter.issueType?.value])

  //* Handler to fetch all open complaints
  const fetchAllOpenComplaints = useCallback(async () => {
    let status = ['open', 'reopen']
    status = status.map((stat) => `'${stat}'`).join(',')
    setOpenIssuesTableLoading(true)
    const res = await getAllComplaintIssues({
      status,
      dateFrom: dateFilter.dateFrom,
      dateTo: dateFilter.dateTo,
    })
    if (res) {
      setOpenIssuesTableLoading(false)
      if (res.status === 200) {
        setAllOpenComplaints(res.data)
      }
    }
  }, [dateFilter.dateFrom, dateFilter.dateTo])
  useEffect(() => {
    fetchAllOpenComplaints()
  }, [fetchAllOpenComplaints])

  //* Handler to fetch all closed complaints
  const fetchAllClosedComplaints = useCallback(async () => {
    let status = ['close']
    status = status.map((stat) => `'${stat}'`).join(',')
    setClosedIssuesTableLoading(true)
    const res = await getAllComplaintIssues({
      status,
      dateFrom: dateFilter2.dateFrom,
      dateTo: dateFilter2.dateTo,
    })
    if (res) {
      setClosedIssuesTableLoading(false)
      if (res.status === 200) {
        setAllClosedComplaints(res.data)
      }
    }
  }, [dateFilter2.dateFrom, dateFilter2.dateTo])
  useEffect(() => {
    fetchAllClosedComplaints()
  }, [fetchAllClosedComplaints])

  const onChangeFilter = (setter, value, target) => {
    setter((ps) => ({ ...ps, [target]: { ...ps[target], value } }))
  }

  // global filter search onchange
  const onGlobalFilterChange = (event, filter, setFilter) => {
    const value = event.target.value
    let _filters = { ...filter }

    _filters['global'].value = value

    setFilter(_filters)
  }

  //* Validate a new record before adding
  const validateNewRecord = (payload) => {
    const err = []
    const {
      type,
      invoiceId,
      issueCategory,
      issueSubCategory,
      description,
      assignTo,
      orderDetails,
      customerName,
      customerEmail,
      customerPhone,
    } = payload
    if (type === 'system') {
      if (!invoiceId) {
        err.push('You need to select an order')
      }
    }
    if (!issueCategory) {
      err.push('Selecting Issue Category is mandatory')
    }
    if (!issueSubCategory) {
      err.push('Selecting Issue Sub Category is manadatory')
    }
    if (!description?.trim()) {
      err.push('Description is invalid')
    }
    if (!customerName) {
      err.push('Customer name is invalid')
    }
    if (type === 'manual') {
      const { valid } = validatePhoneNumber(customerPhone)
      if (!orderDetails) {
        err.push('Order details are invalid')
      }
      if (!customerPhone) {
        err.push('Phone is invalid')
      }
      if (customerPhone && !valid) {
        err.push('Phone number format is invalid')
      }
      if (!customerEmail) {
        err.push('Email is invalid')
      }
    }
    if (!assignTo) {
      err.push('You need to assign the issue to an agent')
    }

    return err
  }

  //* Function to create new complaint
  const createNewComplaint = async () => {
    const payload = dialogVisible
      ? { ...newComplaint, type: 'system' }
      : { ...newManualComplaint, type: 'manual' }
    const errs = validateNewRecord(payload)
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    setLoading(true)
    const res = await postNewComplaintIssue(payload)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        hideNewIssueDialog()
        hideNewIssueDialogManual()
        setAllOpenComplaints((prevSate) => [res.data.record[0], ...prevSate])
      }
    }
  }

  //* Function to handle agent change with password for admin and manager
  const handleAgentChange = async () => {
    setOpenIssuesTableLoading(true)
    setLoading(true)
    const res = await putComplaintAgentById(updateAgentDetails, password)

    if (res) {
      setOpenIssuesTableLoading(false)
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Agent assigned successfully',
          detail: res.data.message,
        })
        let _filtered = allOpenComplaints.map((complaint, idx) => {
          if (complaint.id === updateAgentDetails?.complaintId) {
            complaint.assignedToId = updateAgentDetails.agentId
          }
          return complaint
        })

        setAllOpenComplaints(_filtered)
        hideTargetUpdateDialog()
      }
    }
  }

  const hideTargetUpdateDialog = () => {
    setUpdateAgentDialogVisible(false)
    setPassword('')
  }

  const hideNewIssueDialog = () => {
    setDialogVisible(false)
    setNewRecordError(emptyErr)
    setNewComplaint(emptyComplaint)
    setSearchAddressKeyword('')
    setLoading(false)
  }

  const hideNewIssueDialogManual = () => {
    setManualDialogVisible(false)
    setNewRecordError(emptyErr)
    setNewManualComplaint(emptyManualComplaint)
    setLoading(false)
  }

  const closeReopenRequestDialog = () => {
    history.push({
      pathname: '/issues/cac',
    })
    setReopenComplaintDialogVisible(false)
    setReopenRequestDetails(emptyReopenDetails)
  }

  const fetchReopenRequestById = useCallback(async () => {
    if (!reopenId) return
    setClosedIssuesTableLoading(true)
    const res = await getReopenRequestById(reopenId)
    if (res) {
      setClosedIssuesTableLoading(false)
      if (res.status === 200) {
        setReopenRequestDetails(res.data.value)
        setReopenComplaintDialogVisible(true)
      }
    }
  }, [reopenId])
  useEffect(() => {
    fetchReopenRequestById()
  }, [fetchReopenRequestById])

  const handlePatchRequestStatus = async () => {
    const { status } = reopenRequestConfirmation
    const res = await patchRequestStatus(reopenId, status, password)
    if (res) {
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setReopenRequestConfirmation(emptyReopenConfirmation)
        setPassword('')
        closeReopenRequestDialog()
        //TODO Remove network query and use state
        fetchAllClosedComplaints()
        fetchAllOpenComplaints()
      }
    }
  }

  const openDataTableFiltersHeader = () => {
    return (
      <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
        {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
          <>
            <div>
              <Dropdown
                value={openTableFilter.createdById.value}
                placeholder="Created Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setOpenTableFilters, e.value, 'createdById')
                }}
              ></Dropdown>
            </div>
            <div>
              <Dropdown
                value={openTableFilter.assignedToId.value}
                placeholder="Assigned Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setOpenTableFilters, e.value, 'assignedToId')
                }}
              ></Dropdown>
            </div>
            <div>
              <Dropdown
                value={openTableFilter.orderBy.value}
                placeholder="Order Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setOpenTableFilters, e.value, 'orderBy')
                }}
              ></Dropdown>
            </div>
          </>
        )}
        <MultiSelect
          options={issuesCategories}
          maxSelectedLabels={1}
          optionLabel="mainIssue"
          optionValue="mainIssue"
          filter
          filterBy="mainIssue"
          filterPlaceholder="Search..."
          showClear
          placeholder="Select Issue Category"
          value={openTableFilter.issueType.value}
          onChange={(e) => {
            onChangeFilter(setOpenTableFilters, e.value, 'issueType')
          }}
        />
        <MultiSelect
          options={
            openTableFilter.issueType.value && openTableFilter.issueType.value.length > 0
              ? issuesCategories
                  .filter((issue) => openTableFilter.issueType.value.includes(issue.mainIssue))
                  .map((item) => {
                    return {
                      label: item.mainIssue,
                      value: item.mainIssue,
                      items: item?.subIssues?.map((item2) => {
                        return {
                          label: item2.subIssue,
                          value: item2.subIssue,
                        }
                      }),
                    }
                  })
              : []
          }
          maxSelectedLabels={1}
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
          filter
          filterBy="label"
          filterPlaceholder="Search..."
          showClear
          placeholder="Select Sub-Issue Category"
          value={openTableFilter.issueSub.value}
          onChange={(e) => {
            onChangeFilter(setOpenTableFilters, e.value, 'issueSub')
          }}
        />
        <div>
          <Dropdown
            value={openTableFilter.status.value}
            placeholder="Complaint Type"
            options={complaintStatuses}
            optionLabel="label"
            optionValue="value"
            showClear
            onChange={(e) => {
              onChangeFilter(setOpenTableFilters, e.value, 'status')
            }}
          ></Dropdown>
        </div>
        <div className="">
          <MyDatesPicker
            code={dateCode}
            setCode={setDateCode}
            rangeDates={dateRange}
            setRangeDates={setDateRange}
            filterDates={dateFilter}
            setFilterDates={setDateFilter}
          />
        </div>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={openTableFilter['global'] ? openTableFilter['global'].value : '' || ''}
              onChange={(e) => onGlobalFilterChange(e, openTableFilter, setOpenTableFilters)}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    )
  }
  const closeDataTableFiltersHeader = () => {
    return (
      <div className="p-d-flex p-jc-end p-flex-wrap gap-1 p-ai-center p-pb-2">
        {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
          <>
            <div>
              <Dropdown
                value={closeTableFilter.createdById.value}
                placeholder="Created Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setCloseTableFilters, e.value, 'createdById')
                }}
              ></Dropdown>
            </div>
            <div>
              <Dropdown
                value={closeTableFilter.assignedToId.value}
                placeholder="Assigned Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setCloseTableFilters, e.value, 'assignedToId')
                }}
              ></Dropdown>
            </div>
            <div>
              <Dropdown
                value={closeTableFilter.orderBy?.value}
                placeholder="Order Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setCloseTableFilters, e.value, 'orderBy')
                }}
              ></Dropdown>
            </div>
            <div>
              <Dropdown
                value={closeTableFilter.closedById?.value}
                placeholder="Closed By Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setCloseTableFilters, e.value, 'closedById')
                }}
              ></Dropdown>
            </div>
          </>
        )}
        <Dropdown
          value={closeTableFilter.closeType.value}
          onChange={(e) => {
            onChangeFilter(setCloseTableFilters, e.value, 'closeType')
          }}
          showClear
          placeholder="Select Close Category"
          id="closeType"
          options={complaintCloserCategories}
        />
        <MultiSelect
          options={issuesCategories}
          maxSelectedLabels={1}
          optionLabel="mainIssue"
          optionValue="mainIssue"
          filter
          filterBy="mainIssue"
          filterPlaceholder="Search..."
          showClear
          placeholder="Select Issue Category"
          value={closeTableFilter.issueType.value}
          onChange={(e) => {
            onChangeFilter(setCloseTableFilters, e.value, 'issueType')
          }}
        />
        <MultiSelect
          options={
            closeTableFilter.issueType.value && closeTableFilter.issueType.value.length > 0
              ? issuesCategories
                  .filter((issue) => closeTableFilter.issueType.value.includes(issue.mainIssue))
                  .map((item) => {
                    return {
                      label: item.mainIssue,
                      value: item.mainIssue,
                      items: item?.subIssues?.map((item2) => {
                        return {
                          label: item2.subIssue,
                          value: item2.subIssue,
                        }
                      }),
                    }
                  })
              : []
          }
          maxSelectedLabels={1}
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
          filter
          filterBy="label"
          showClear
          placeholder="Select Sub-Issue Category"
          value={closeTableFilter.issueSub?.value}
          onChange={(e) => {
            onChangeFilter(setCloseTableFilters, e.value, 'issueSub')
          }}
        />
        <div className="">
          <MyDatesPicker
            code={dateCode2}
            setCode={setDateCode2}
            rangeDates={dateRange2}
            setRangeDates={setDateRange2}
            filterDates={dateFilter2}
            setFilterDates={setDateFilter2}
          />
        </div>
        <div className="">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={closeTableFilter['global'] ? closeTableFilter['global'].value : '' || ''}
              onChange={(e) => onGlobalFilterChange(e, closeTableFilter, setCloseTableFilters)}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      <Toast ref={toast} />
      <TabView className="card">
        {/* Open complaints section start*/}
        <TabPanel
          header="Open"
          activeIndex={activeTabIndex}
          onTabChange={(e) => setActiveTabIndex(e.index)}
        >
          <Toolbar
            className="p-toolbar p-mb-3 p-flex-wrap gap-1"
            left={
              <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                <h4 className="p-m-0">Complaints And Cases</h4>

                <Button
                  icon="pi pi-plus"
                  label="Report New Issue"
                  onClick={() => {
                    setDialogVisible(true)
                    if (user.role === 'agent') {
                      handleObjChange(setNewComplaint, 'assignTo', user.id)
                    }
                  }}
                />
                <Button
                  icon="pi pi-plus"
                  label="Report New Manual Issue"
                  onClick={() => {
                    setManualDialogVisible(true)
                    if (user.role === 'agent') {
                      handleObjChange(setNewManualComplaint, 'assignTo', user.id)
                    }
                  }}
                />
              </div>
            }
            right={
              <div>
                <div className="p-d-flex p-flex-wrap gap-1">
                  <Button
                    icon="pi pi-refresh"
                    onClick={() => {
                      fetchAllOpenComplaints()
                    }}
                  />
                  <ExportButton datatable_ref={open_dt} />
                </div>
              </div>
            }
          ></Toolbar>
          {/* DataTable to show open complaint records */}
          <DataTable
            ref={open_dt}
            exportFilename={`Open_Complaints_${dtFilenameDate}`}
            removableSort
            filters={openTableFilter}
            value={allOpenComplaints}
            header={openDataTableFiltersHeader}
            showGridlines
            loading={openIssuesTableLoading}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          >
            <Column
              field="id"
              header="Id"
              sortable
              style={{
                width: '5%',
              }}
            />
            <Column
              field="invoiceId"
              header="Invoice Id"
              style={{ width: '15%' }}
              body={(rd) => {
                if (rd.invoiceId === 'NA') {
                  return rd.invoiceId
                } else {
                  return (
                    <Link to={`/orders?invId=${rd.invoiceId}`} target="_blank">
                      {rd.invoiceId}
                    </Link>
                  )
                }
              }}
            />
            <Column
              field="customerName"
              header="Customer Name"
              style={{
                width: '10%',
              }}
            />
            <Column
              field="issueType"
              header="Issue Category"
              style={{
                width: '20%',
              }}
            />
            <Column
              field="description"
              header="Description"
              style={{
                width: '30%',
              }}
            />
            <Column
              field="createdAt"
              header="Open Date"
              body={(rd) => <>{format(new Date(rd.createdAt), 'dd-MM-yyyy')}</>}
              style={{
                width: '10%',
              }}
            />
            {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
              <Column
                header="Agent"
                field="assignedToId"
                style={{
                  width: '10%',
                }}
                body={(rd) => {
                  return (
                    <div className="p-fluid">
                      <Dropdown
                        placeholder="Agent"
                        //* for teamlead, put his agents in top of the list
                        options={
                          ['teamlead'].includes(user.role)
                            ? [
                                ...user.members,
                                ...agents.filter(
                                  (agent) => !user.members.map((u) => u.id).includes(agent.id)
                                ),
                              ]
                            : agents
                        }
                        disabled={user.role === 'agent'}
                        //* Fade out the agents that do not belong to a team manager
                        itemTemplate={(option) => {
                          return (
                            <div
                              className={classNames({
                                'opacity-50':
                                  ['teamlead'].includes(user.role) &&
                                  !user.members.map((u) => u.id).includes(option.id)
                                    ? true
                                    : false,
                              })}
                            >
                              {option.username}
                            </div>
                          )
                        }}
                        optionLabel="username"
                        optionValue="id"
                        filter
                        filterBy="username"
                        filterPlaceholder="Search by username"
                        value={rd.assignedToId}
                        onChange={(e) => {
                          //* Void selection if a manager clicks any agent other than their members
                          if (!user.members.map((u) => u.id).includes(e.value)) {
                            toast.current.show({
                              severity: 'warn',
                              summary: 'Warning',
                              detail: 'This agent is not in your team',
                            })
                            return
                          }
                          if (!user.members.map((u) => u.id).includes(rd.assignedToId)) {
                            toast.current.show({
                              severity: 'warn',
                              summary: 'Warning',
                              detail:
                                "Since the issue is assigned to someone outside your team, it's view only",
                            })
                            return
                          }
                          const details = {
                            complaintId: rd.id,
                            agentId: e.value,
                          }
                          setUpdateAgentDetails({
                            ...details,
                          })
                          setUpdateAgentDialogVisible(true)
                        }}
                      />
                    </div>
                  )
                }}
              />
            )}
            {user.role === 'agent' && (
              <Column
                field="agent"
                header="Agent"
                style={{
                  width: '10%',
                }}
              />
            )}
            <Column
              field="Action"
              header="Action"
              style={{
                width: '10%',
              }}
              body={(rd) => {
                return (
                  <div className="p-d-flex gap-1by2 p-jc-start">
                    <Link to={`?noteId=${rd?.id}`}>
                      <Button
                        icon="pi pi-copy"
                        tooltip="Open Notes"
                        className="p-button-info"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    <Link to={`?id=${rd?.id}`}>
                      <Button
                        icon="pi pi-eye"
                        tooltip="View"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    <Link to={`?history=${rd.id}`}>
                      <Button
                        tooltip="History"
                        tooltipOptions={{ position: 'bottom' }}
                        icon="pi pi-clock"
                        className="p-button p-button-help"
                      />
                    </Link>

                    <Button
                      icon="pi pi-envelope"
                      tooltip="Write Email"
                      tooltipOptions={{ position: 'bottom' }}
                      onClick={() => {
                        setSendEmail((ps) => ({
                          ...ps,
                          refId: rd.id,
                          email: rd.email,
                          relatedTo: 'complaints',
                          subject: `Update regarding your complaint ${rd.id}`,
                        }))
                        setSendEmailDialogVisible(true)
                      }}
                      className="p-button-info"
                    />
                  </div>
                )
              }}
            />
          </DataTable>
          <SendMailDialog mailData={sendEmail} setMailData={setSendEmail} />
          {/* System issue dialog start*/}
          <Dialog
            header="Report New Issue"
            visible={dialogVisible}
            style={{ width: '40%', minWidth: '400px' }}
            footer={
              <div>
                <Button
                  loading={loading}
                  label="Submit"
                  className="p-button-submit"
                  icon="pi pi-check"
                  onClick={() => {
                    createNewComplaint()
                  }}
                />
                <Button
                  label="Cancel"
                  className="p-button-text"
                  icon="pi pi-times"
                  onClick={() => hideNewIssueDialog()}
                />
              </div>
            }
            onHide={() => hideNewIssueDialog()}
          >
            <div className="p-grid gap-1">
              <div className="p-field p-fluid p-col-12 p-mb-0">
                <label className="p-d-block">
                  Enter Invoice Number <sup style={{ color: 'red' }}>*</sup>
                </label>
                <AutoComplete
                  placeholder="Enter Invoice Number/First Name"
                  autoHighlight
                  value={searchAddressKeyword}
                  field="option"
                  optionLabel="orderid"
                  suggestions={filteredAddressInDbDisplayAry}
                  completeMethod={searchOrderAddressOnInput}
                  onSelect={(e) => {
                    handleObjChange(setNewComplaint, 'orderAgent', '')
                    handleObjChange(setNewComplaint, 'assignTo', '')
                    onAddressSelect(e.value)
                  }}
                  onChange={(e) => setSearchAddressKeyword(e.value)}
                  delay={500}
                  panelStyle={{
                    width: '30%',
                    minWidth: '400px',
                  }}
                />
                {newComplaint.invoiceId && (
                  <p className="p-mt-2">
                    Click on the invoice to view order details
                    <Link
                      to={`/orders?invId=${newComplaint.invoiceId}`}
                      target="_blank"
                      className="p-ml-2"
                    >
                      <span>{newComplaint.invoiceId}</span>
                    </Link>
                  </p>
                )}
              </div>
              <div className="p-field p-fluid p-col-12 p-mb-0">
                <label className="p-d-block">
                  Issue Category <sup style={{ color: 'red' }}>*</sup>
                </label>
                <Dropdown
                  options={issuesCategories}
                  optionLabel="mainIssue"
                  optionValue="mainIssue"
                  placeholder="Select Issue Category"
                  value={newComplaint.issueCategory}
                  onChange={(e) => {
                    handleObjChange(setNewComplaint, 'issueCategory', e.value)
                  }}
                />
              </div>
              <div className="p-field p-fluid p-col-12 p-mb-0">
                <label className="p-d-block">
                  Issue Sub-Category <sup style={{ color: 'red' }}>*</sup>
                </label>
                <Dropdown
                  options={
                    newComplaint.issueCategory
                      ? issuesCategories[
                          issuesCategories.findIndex(
                            (val) => val.mainIssue === newComplaint.issueCategory
                          )
                        ]?.subIssues
                      : []
                  }
                  optionLabel="subIssue"
                  optionValue="subIssue"
                  placeholder="Select Issue Sub-Category"
                  value={newComplaint.issueSubCategory}
                  onChange={(e) => {
                    handleObjChange(setNewComplaint, 'issueSubCategory', e.value)
                  }}
                  panelStyle={{
                    width: '30%',
                    minWidth: '400px',
                  }}
                />
              </div>
              <div className="p-field p-fluid p-col-12 p-mb-0">
                <label className="p-d-block">
                  Description <sup style={{ color: 'red' }}>*</sup>
                </label>
                <InputTextarea
                  rows={5}
                  cols={30}
                  placeholder="Describe your problem here..."
                  value={newComplaint.description}
                  onChange={(e) => {
                    handleObjChange(setNewComplaint, 'description', e.target.value)
                  }}
                />
              </div>
              {newComplaint.orderAgent && (
                <div className="p-field p-fluid p-col-12 p-md-5 p-mb-0">
                  <label className="p-d-block">Order Agent</label>
                  <h6 className="p-mt-3">
                    {agents.find((agent) => agent.id === newComplaint.orderAgent)?.username}
                  </h6>
                </div>
              )}
              {(['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) ||
                user.id === 150) && (
                <div className="p-field p-fluid p-col-12 p-md-5 p-mb-0">
                  <label className="p-d-block">
                    Assign To <sup style={{ color: 'red' }}>*</sup>
                  </label>
                  <Dropdown
                    placeholder="Agent"
                    options={user.members}
                    disabled={user.role === 'agent'}
                    optionLabel="username"
                    optionValue="id"
                    showClear
                    filter
                    filterBy="username"
                    value={newComplaint.assignTo}
                    onChange={(e) => {
                      handleObjChange(setNewComplaint, 'assignTo', e.value)
                    }}
                  />
                </div>
              )}

              {newRecordError.state && (
                <>
                  {newRecordError.errors.map((err, idx) => {
                    return (
                      <div key={idx} className="p-fluid p-filed p-col-12">
                        <Message text={err} severity="warn" sticky={true} />
                      </div>
                    )
                  })}
                  <div className="p-fluid p-field p-col-12">
                    <Button
                      type="button"
                      onClick={() => {
                        setNewRecordError(emptyErr)
                      }}
                      icon="pi pi-times"
                      label="Clear Warnings"
                      className="p-button-secondary"
                    ></Button>
                  </div>
                </>
              )}
            </div>
          </Dialog>
          {/* System issue dialog end */}
          {/* Manual dialog start */}
          <Dialog
            header="Report New Issue"
            footer={() => {
              return (
                <>
                  <Button
                    loading={loading}
                    label="Submit"
                    className="p-button-submit"
                    icon="pi pi-check"
                    autoFocus
                    onClick={() => {
                      createNewComplaint()
                    }}
                  />
                  <Button
                    label="Cancel"
                    className="p-button-text"
                    onClick={() => hideNewIssueDialogManual()}
                    icon="pi pi-times"
                  />
                </>
              )
            }}
            style={{ width: '800px' }}
            className="p-fluid dialog-content"
            visible={manualDialogVisible}
            onHide={() => hideNewIssueDialogManual()}
          >
            <div className="p-formgrid p-grid">
              <div className="p-field p-col-12">
                <div className="p-field">
                  <label htmlFor="products_details">
                    Order Details <sup style={{ color: 'red' }}>*</sup>
                  </label>
                  {/* <Editor
                    className="editor"
                    apiKey={`${process.env.REACT_APP_TINYMCE_API_KEY}`}
                    style={{ width: '100%' }}
                    tinymceScriptSrc="https://cdn.tiny.cloud/1/crhihg018llbh8k3e3x0c5e5l8ewun4d1xr6c6buyzkpqwvb/tinymce/5/tinymce.min.js"
                    value={newManualComplaint.orderDetails}
                    init={{
                      skin: colorScheme === 'light' ? 'oxide' : 'oxide-dark',
                      content_css: colorScheme === 'light' ? 'default' : 'dark',
                      height: 500,
                      plugins: [
                        'advlist autolink lists link charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen table',
                        'importcss insertdatetime table paste code help wordcount template',
                      ],
                      toolbar:
                        'undo redo | fontselect | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table ',
                    }}
                    onEditorChange={(e) => {
                      handleObjChange(setNewManualComplaint, 'orderDetails', e)
                    }}
                  /> */}
                  <ReactQuill
                    value={newManualComplaint.orderDetails}
                    onChange={(value) =>
                      handleObjChange(setNewManualComplaint, 'orderDetails', value)
                    }
                  />
                </div>
                <div className="p-formgrid p-grid">
                  <div className="p-field p-col-12">
                    <label htmlFor="customerName">
                      Customer Name <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <InputText
                      id="customerName"
                      placeholder="Enter Customer"
                      value={newManualComplaint.customerName}
                      onChange={(e) =>
                        handleObjChange(setNewManualComplaint, 'customerName', e.target.value)
                      }
                    />
                  </div>
                  <div className="p-field p-col-12">
                    <label htmlFor="customerEmail">
                      Customer Email <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <InputText
                      id="customerEmail"
                      placeholder="jhondoe@gmail.com"
                      value={newManualComplaint.customerEmail}
                      onChange={(e) =>
                        handleObjChange(setNewManualComplaint, 'customerEmail', e.target.value)
                      }
                    />
                  </div>
                  <div className="p-field p-col-12">
                    <label htmlFor="customerPhone">
                      Customer Phone <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <InputText
                      id="customerPhone"
                      placeholder="Phone (with +XX country code)"
                      value={newManualComplaint.customerPhone}
                      onChange={(e) =>
                        handleObjChange(setNewManualComplaint, 'customerPhone', e.target.value)
                      }
                    />
                  </div>
                  <div className="p-field p-col-12">
                    <label htmlFor="issue_category">
                      Issue Category <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <Dropdown
                      value={newManualComplaint.issueCategory}
                      options={issuesCategories}
                      required
                      name="issueCategory"
                      onChange={(e) => {
                        handleObjChange(setNewManualComplaint, 'issueCategory', e.target.value)
                      }}
                      optionLabel="mainIssue"
                      optionValue="mainIssue"
                      placeholder="Select Issue Category"
                    />
                  </div>
                  <div className="p-field p-col-12">
                    <label htmlFor="subCategory">
                      Issue Sub-Category <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <Dropdown
                      value={newManualComplaint.issueSubCategory}
                      options={
                        newManualComplaint.issueCategory
                          ? issuesCategories[
                              issuesCategories.findIndex(
                                (val) => val.mainIssue === newManualComplaint.issueCategory
                              )
                            ]?.subIssues
                          : []
                      }
                      required
                      name="issueSubCategory"
                      onChange={(e) => {
                        handleObjChange(setNewManualComplaint, 'issueSubCategory', e.target.value)
                      }}
                      optionLabel="subIssue"
                      optionValue="subIssue"
                      placeholder="Select Issue Sub-Category"
                    />
                  </div>
                  <div className="p-field p-col-12">
                    <label htmlFor="description">
                      Description <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <InputTextarea
                      id="description"
                      value={newManualComplaint.description}
                      name="description"
                      onChange={(e) => {
                        handleObjChange(setNewManualComplaint, 'description', e.target.value)
                      }}
                      required
                      rows={3}
                      cols={20}
                      placeholder="Describe your problem here..."
                    />
                  </div>
                  {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(
                    user.role
                  ) && (
                    <div className="p-field p-col-12">
                      <label className="p-d-block">
                        Assign To <sup style={{ color: 'red' }}>*</sup>
                      </label>
                      <Dropdown
                        placeholder="Agent"
                        options={user.members}
                        disabled={user.role === 'agent'}
                        optionLabel="username"
                        optionValue="id"
                        showClear
                        filter
                        filterBy="username"
                        value={newManualComplaint.assignTo}
                        onChange={(e) => {
                          handleObjChange(setNewManualComplaint, 'assignTo', e.value)
                        }}
                      />
                    </div>
                  )}
                  {newRecordError.state && (
                    <>
                      {newRecordError.errors.map((err, idx) => (
                        <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                          <Message text={err} severity="warn" sticky={true} />
                        </div>
                      ))}
                      <div className="p-fluid p-field p-col-12">
                        <Button
                          type="button"
                          onClick={() => setNewRecordError(emptyErr)}
                          icon="pi pi-times"
                          label="Clear Warnings"
                          className="p-button-secondary"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Dialog>
          {/* Manual dialog  end*/}
          {/* Single Complaint Details Dialog start*/}
          <ComplaintDetailsDialog
            setLoading={setLoading}
            loading={loading}
            setOpenIssuesTableLoading={setOpenIssuesTableLoading}
            setClosedIssuesTableLoading={setClosedIssuesTableLoading}
            allOpenComplaints={allOpenComplaints}
            allClosedComplaints={allClosedComplaints}
            setAllOpenComplaints={setAllOpenComplaints}
            setAllClosedComplaints={setAllClosedComplaints}
            complaintCloserCategories={complaintCloserCategories}
          />
          {/* Single Complaint Details Dialog end*/}
          {/* Complaint Notes Dialog Component Start */}
          <ComplaintNotesDialog
            setOpenIssuesTableLoading={setOpenIssuesTableLoading}
            setClosedIssuesTableLoading={setClosedIssuesTableLoading}
            pathname={'/issues/cac'}
          />
          {/* Complaint Notes Dialog Component End */}
          {/* Confirmation dialog to change an assigned agent */}
          <ConfirmationDialogWithInput
            onHide={hideTargetUpdateDialog}
            header={'Change Agent'}
            onSubmit={handleAgentChange}
            visible={updateAgentDialogVisible}
            inputLabel={'Password'}
            customInputs={true}
            loading={loading}
          >
            <div className="">
              <label htmlFor="password" className="">
                Password
              </label>
              <Password
                autoComplete="nope"
                toggleMask
                feedback={false}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-mt-1"
                placeholder="Enter your password"
              />
            </div>
          </ConfirmationDialogWithInput>
          {/* Confirmation dialog to change an assigned agent */}
        </TabPanel>
        {/* Open complaints section end*/}
        {/* Close complaints section start*/}
        <TabPanel header="Closed">
          <Toolbar
            className="p-toolbar p-mb-3 p-flex-wrap gap-1"
            left={
              <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                <h4 className="p-m-0">Complaints And Cases</h4>
              </div>
            }
            right={
              <div>
                <div className="p-d-flex p-flex-wrap gap-1">
                  <Button
                    icon="pi pi-refresh"
                    onClick={() => {
                      fetchAllClosedComplaints()
                    }}
                  />

                  <ExportButton datatable_ref={close_dt} />
                </div>
              </div>
            }
          ></Toolbar>
          {/* DataTable to show closed record */}
          <DataTable
            ref={close_dt}
            exportFilename={`Closed_Complaints_${dtFilenameDate}`}
            removableSort
            filters={closeTableFilter}
            value={allClosedComplaints}
            header={closeDataTableFiltersHeader}
            showGridlines
            loading={closedIssuesTableLoading}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          >
            <Column
              sortable
              field="id"
              header="Id"
              style={{
                width: '5%',
              }}
            />
            <Column
              field="invoiceId"
              header="Invoice Id"
              style={{ width: '15%' }}
              body={(rd) => {
                if (rd.invoiceId === 'NA') {
                  return rd.invoiceId
                } else {
                  return (
                    <Link to={`/orders?invId=${rd.invoiceId}`} target="_blank">
                      {rd.invoiceId}
                    </Link>
                  )
                }
              }}
            />
            <Column
              field="customerName"
              header="Customer Name"
              style={{
                width: '10%',
              }}
            />
            <Column
              field="issueType"
              header="Issue Category"
              style={{
                width: '20%',
              }}
            />
            <Column
              field="description"
              header="Issue Description"
              style={{
                width: '30%',
              }}
            />
            <Column
              field="createdAt"
              header="Open Date"
              body={(rd) => <>{format(new Date(rd.createdAt), 'yyyy-MM-dd')}</>}
              style={{
                width: '10%',
              }}
            />
            <Column
              field="closedBy"
              header="Closed By"
              style={{
                width: '10%',
              }}
            />
            <Column
              field="Action"
              header="Action"
              style={{
                width: '10%',
              }}
              body={(rd) => {
                return (
                  <div className="p-d-flex gap-1by2 p-jc-start">
                    <Link to={`?noteId=${rd?.id}`}>
                      <Button
                        icon="pi pi-copy"
                        tooltip="Open Notes"
                        className="p-button-info"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    <Link to={`?id=${rd?.id}`}>
                      <Button
                        icon="pi pi-eye"
                        tooltip="View"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    {rd.reopenApproval === 'pending' &&
                      (['teamlead'].includes(user.role)
                        ? user.members?.map((u) => u.id).includes(rd.assignedToId) &&
                          user.members?.map((u) => u.id).includes(rd.closedById)
                        : rd.assignedToId === user.id ||
                          rd.closedById === user.id ||
                          user.id === 150 ||
                          ['superadmin', 'admin', 'manager', 'operations'].includes(user.role)) && (
                        <Link to={`?reopen_id=${rd?.id}`}>
                          <div className="relative">
                            <Button className="p-button-danger" icon="pi pi-undo"></Button>
                            <span className="blob bottom-12-px left-12-px"></span>
                          </div>
                        </Link>
                      )}
                    <Link to={`?history=${rd.id}`}>
                      <Button
                        tooltip="History"
                        tooltipOptions={{ position: 'bottom' }}
                        icon="pi pi-clock"
                        className="p-button p-button-help"
                      />
                    </Link>
                  </div>
                )
              }}
            />
          </DataTable>
          {/* Single Complaint Details Dialog start */}
          <ComplaintDetailsDialog
            setLoading={setLoading}
            loading={loading}
            setOpenIssuesTableLoading={setOpenIssuesTableLoading}
            setClosedIssuesTableLoading={setClosedIssuesTableLoading}
            allOpenComplaints={allOpenComplaints}
            allClosedComplaints={allClosedComplaints}
            setAllOpenComplaints={setAllOpenComplaints}
            setAllClosedComplaints={setAllClosedComplaints}
            complaintCloserCategories={complaintCloserCategories}
          />
          {/* Single Complaint Details Dialog end*/}
          {/* Complaint Notes Dialog Component Start */}
          <ComplaintNotesDialog
            setLoading={setLoading}
            loading={loading}
            setOpenIssuesTableLoading={setOpenIssuesTableLoading}
            setClosedIssuesTableLoading={setClosedIssuesTableLoading}
            pathname={'/issues/cac'}
          />
          {/*Complaint Notes Dialog Component End */}
          {/* Dialog to reopen start*/}
          <Dialog
            header={
              <div className="text-center">
                <h5 className="p-mb-0">Reopen Complaint {reopenRequestDetails?.id}</h5>
              </div>
            }
            visible={reopenComplaintDialogVisible}
            style={{ width: '40%', minWidth: '400px' }}
            onHide={() => closeReopenRequestDialog()}
            footer={<div></div>}
          >
            <div>
              <h6>Requested By : {reopenRequestDetails?.agent}</h6>
              <h5>Reason</h5>
              <h6 className="p-text-normal" style={{ lineHeight: '130%' }}>
                {reopenRequestDetails?.reopenReason}
              </h6>
            </div>
            {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
              <div className="p-d-flex p-fluid">
                <Button
                  className="p-button-raised p-mr-2 p-mt-5 p-button-success"
                  label="Approve"
                  name="approve"
                  icon="pi pi-check"
                  onClick={(e) => {
                    const _data = {
                      status: e.target.name,
                      dialogVisible: true,
                    }

                    setReopenRequestConfirmation(_data)
                  }}
                  tooltipOptions={{
                    position: 'bottom',
                  }}
                />
                <Button
                  className="p-button-raised  p-mt-5 p-button-danger"
                  label="Reject"
                  name="reject"
                  icon="pi pi-times"
                  onClick={(e) => {
                    const _data = {
                      status: e.target.name,
                      dialogVisible: true,
                    }

                    setReopenRequestConfirmation(_data)
                  }}
                  tooltipOptions={{
                    position: 'bottom',
                  }}
                />
              </div>
            )}
            {user.role === 'agent' && (
              <div className="p-field p-fluid p-col-12 p-mb-0">
                <Message text="Pending for approval" severity="warn" sticky={true} />
              </div>
            )}
          </Dialog>
          {/* Dialog to reopen end*/}
          {/* Confirmation dialog to ask password start*/}
          <ConfirmationDialogWithInput
            onHide={() => {
              setReopenRequestConfirmation(emptyReopenConfirmation)
            }}
            header={'Update Request Status'}
            onSubmit={handlePatchRequestStatus}
            visible={reopenRequestConfirmation.dialogVisible}
            inputLabel={'Password'}
            customInputs={true}
            loading={loading}
          >
            <div className="">
              <label htmlFor="password" className="">
                Password
              </label>
              <Password
                autoComplete="nope"
                toggleMask
                feedback={false}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-mt-1"
                placeholder="Enter your password"
              />
            </div>
          </ConfirmationDialogWithInput>
          {/* Confirmation dialog to ask password end*/}
        </TabPanel>
        {/* Close complaints section end */}
      </TabView>
      <ChangeHistorySidebar
        setTableLoading={
          activeTabIndex === 0 ? setOpenIssuesTableLoading : setClosedIssuesTableLoading
        }
        header="Complaint Change History"
        historyType="im_complaint"
      />
    </>
  )
}
