import React, { useCallback, useEffect, useRef, useState } from 'react'

import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'

import _ from 'lodash'
import { RadioButton } from 'primereact/radiobutton'
import isEmail from 'validator/lib/isEmail'
import {
  addManualOrder,
  aftershipAddressValidationProxy,
  checkoutShippingDetails,
  getProductNames,
  searchByOrderOrName,
  searchQuantity,
  searchStrength,
  uploadFileToS3,
} from '../../api'
import { useGlobalContext } from '../../context'
import country_name, { objectifiedData } from '../../data/country_name'

import classNames from 'classnames'
import { Message } from 'primereact/message'
import { numlookupPhoneValidate } from '../../functions/numlookup'
import { handleObjChange } from '../../functions/setter'
import validatePhoneNumber from '../../functions/validatePhoneNumber'
import { InputSwitch } from 'primereact/inputswitch'
import { Calendar } from 'primereact/calendar'
import { nanoid } from 'nanoid'

export default function AddManualOrder({
  resetUrl,
  discountOptions,
  newManualOrderDialogVisible,
  setNewManaulDialog,
  addNewOrderToTable,
  customProducts,
  setCustomProducts,
  customerData,
  setCustomerData,
  emptyCustomer,
  manualProducts,
  setManualProducts,
  showSuccess,
  toast,
}) {
  /**
   * @param {function} resetUrl - The function to reset the url
   * @param {array} discountOptions - The array of discount options
   * @param {boolean} newManualOrderDialogVisible - The state of the new manual order dialog
   * @param {function} setNewManaulDialog - The function to set the new manual order dialog
   * @param {function} addNewOrderToTable - The function to add new order to table
   * @param {array} customProducts - The array of custom products
   * @param {function} setCustomProducts - The function to set the custom products
   * @param {object} customerData - The object of customer data
   * @param {function} setCustomerData - The function to set the customer data
   * @param {object} emptyCustomer - The object of empty customer
   * @param {array} manualProducts - The array of manual products
   * @param {function} setManualProducts - The function to set the manual products
   * @param {function} showSuccess - The function to show the success message
   * @param {function} toast - The function to show the toast message
   * @description - The component to add manual order
   **/
  let emptyManualProduct = {
    productName: null,
    strength: null,
    quantity: 0,
    quantityPrice: 0.0,
    totalQuantity: 1,
    total: 0.0,
    totalPrice: 0.0,
    cost: 0.0,
    productCategory: null,
    shippingCompany: null,
  }
  let emptyCustomProduct = {
    productName: null,
    strength: null,
    quantity: 0,
    quantityPrice: 0.0,
    totalQuantity: 1,
    total: 0.0,
    totalPrice: 0.0,
    cost: 0.0,
    productCategory: 'Custom',
    shippingCompany: null,
  }
  const { shippingTags, user, agents, activeProductNames } = useGlobalContext()
  const featureImage = useRef(null)
  /* STATES */
  const emptyErr = {
    state: false,
    errors: [],
  }
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const verifyEmpty = {
    loading: false,
    data: null,
  }
  const [addressVerificationRes, setAddressVerificationRes] = useState(verifyEmpty)
  const [phoneVerificationRes, setPhoneVerificationRes] = useState(verifyEmpty)

  const [loading, setLoading] = useState(false)
  const [quantityNames, setQuantityNames] = useState([])
  const [grandtotal, setGrandtotal] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [profit, setProfit] = useState(0)
  const [discountType, setDiscountType] = useState(discountOptions[0])
  const [discountValue, setDiscountValue] = useState(0)
  const [searchAddressKeyword, setSearchAddressKeyword] = useState('')
  const [discount, setDiscount] = useState(0)
  const [shipping, setShipping] = useState(0)
  const [productNames, setProductNames] = useState([])
  const [strengthNames, setStrengthNames] = useState([])
  const [filteredAddressInDbDisplayAry, setFilteredAddressInDbDisplayAry] = useState([])
  const [isKeepAccountLinked, setIsKeepAccountLinked] = useState(true)
  const [copiedManualOrder, setCopiedManualOrder] = useState({})
  const [avaliableAddressInDb, setAvaliableAddressInDb] = useState([])
  const [orderLeadSource, setOrderLeadSource] = useState('')
  const notifyViaEmpty = {
    email: true,
    sms: true,
    whatsapp: true,
  }
  const [notifyVia, setNotifyVia] = useState(notifyViaEmpty)
  const [orderBy, setOrderBy] = useState(null)
  const [refillDate, setRefillDate] = useState('')
  const [attachments, setAttachments] = useState([])

  const clearState = () => {
    setCustomerData(emptyCustomer)
    setAddressVerificationRes(verifyEmpty)
    setPhoneVerificationRes(verifyEmpty)
    setQuantityNames([])
    setGrandtotal(0)
    setSubtotal(0)
    setDiscount(0)
    setShipping(0)
    setCustomProducts([])
    setStrengthNames([])
    setManualProducts([])
    setFilteredAddressInDbDisplayAry([])
    setNewRecordError(emptyErr)
    setCopiedManualOrder({})
    setAvaliableAddressInDb([])
    setOrderLeadSource('')
    setDiscountValue(0)
    setDiscountType(discountOptions[0])
    setSearchAddressKeyword('')
    setOrderBy(null)
    setNotifyVia(notifyViaEmpty)
    setIsKeepAccountLinked(true)
    setRefillDate('')
    setAttachments([])
  }

  /* REACT MAGIC */
  const getProducts = useCallback(async () => {
    const res = await getProductNames()
    if (res && res.status === 200) {
      setProductNames(res.data)
    }
  }, [])

  useEffect(() => {
    getProducts()
  }, [getProducts])

  const validateOrderProducts = (products, name) => {
    const err = []

    products.forEach((val, idx) => {
      const srNo = idx + 1
      const {
        productName,
        shippingCompany,
        productCategory,
        strength,
        quantity,
        quantityPrice,
        totalQuantity,
        totalPrice,
        total,
      } = val
      if (!productName) {
        err.push(`${name}-product #${srNo}: Product name is invalid`)
      }
      if (!shippingCompany) {
        err.push(`${name}-product #${srNo}: Shipping company is invalid`)
      }
      if (!productCategory) {
        err.push(`${name}-product #${srNo}: Product category is invalid`)
      }
      if (!strength) {
        err.push(`${name}-product #${srNo}: Strength is invalid`)
      }
      if (!quantity) {
        err.push(`${name}-product #${srNo}: Quantity is invalid`)
      }
      if (!quantityPrice) {
        err.push(`${name}-product #${srNo}: Quantity price is invalid`)
      }
      if (!totalQuantity) {
        err.push(`${name}-product #${srNo}: Total quantity is invalid`)
      }
      if (!totalPrice) {
        err.push(`${name}-product #${srNo}: Total price is invalid`)
      }
      if (!total) {
        err.push(`${name}-product #${srNo}: Total is invalid`)
      }
    })
    return err
  }

  const validateOrder = () => {
    let err = []
    const { fname, lname, phone, email, address, zip, country, state, city } = customerData
    const { valid } = validatePhoneNumber(phone)
    // customer
    if (!fname) {
      err.push('Firstname is invalid')
    }
    if (!lname) {
      err.push('Lastname is invalid')
    }
    if (!phone) {
      err.push('Phone is invalid')
    }
    if (phone && !valid) {
      err.push('Phone number format is invalid')
    }

    if (!email) {
      err.push('Email is invalid')
    } else if (!isEmail(email)) {
      err.push('Email format is invalid')
    }
    if (!address) {
      err.push('Address is invalidss')
    }
    if (!zip) {
      err.push('Zip is invalid')
    }
    if (!country) {
      err.push('Country is invalidry')
    }
    if (!state) {
      err.push('State is invalid')
    }
    if (!city) {
      err.push('City is invalid')
    }

    // orderLeadSource
    if (!orderLeadSource) {
      err.push('Lead source is invalid')
    }
    // products
    if (manualProducts.length === 0 && customProducts.length === 0) {
      err.push('At least one product is required')
    }
    const mpErrs = validateOrderProducts(manualProducts, 'Manual')
    const cpErrs = validateOrderProducts(customProducts, 'Custom')
    err = [...err, ...mpErrs, ...cpErrs]
    if (!['agent'].includes(user.role)) {
      if (!orderBy) {
        err.push('Assigned order to is invalid')
      }
    }
    if (!refillDate) {
      err.push('Refill date is invalid')
    }
    return err
  }

  const calculateSubTotal = useCallback(() => {
    let _subtotal = 0
    let _discountedSubtotal = 0
    let _discountValue = discountValue || 0

    manualProducts.concat(customProducts).forEach((val) => {
      const { quantityPrice, quantity, totalQuantity } = val
      let mySubtotal = 0
      let myDiscountedSubtotal = 0
      mySubtotal = parseFloat(quantityPrice * quantity * totalQuantity)
      if (discountType === 'Percentage') {
        let discountedQuantityPrice = parseFloat(
          quantityPrice - (quantityPrice * _discountValue) / 100
        )
        // Round the discounted price to the nearest integer
        discountedQuantityPrice = Math.round(discountedQuantityPrice * 100) / 100

        myDiscountedSubtotal = parseFloat(discountedQuantityPrice * quantity * totalQuantity)
      }
      _subtotal += mySubtotal
      _discountedSubtotal += myDiscountedSubtotal
      return _subtotal
    })

    let _discount = discountType === 'Percentage' ? _subtotal - _discountedSubtotal : _discountValue
    _discount = parseFloat(_discount.toFixed(2))
    setDiscount(_discount)

    let _grandTotal = parseFloat((_subtotal - _discount + shipping).toFixed(2))

    setProfit(0)
    setSubtotal(_subtotal)
    setGrandtotal(_grandTotal)
  }, [customProducts, manualProducts, discountValue, shipping])

  useEffect(() => {
    calculateSubTotal()
  }, [calculateSubTotal])

  /* FUNCTIONS */

  //search address by email
  const getAvailableAddress = async () => {
    const res = await searchByOrderOrName(searchAddressKeyword)
    if (res && res.status === 200) {
      let data = res.data || []
      setAvaliableAddressInDb(data)
      return data
    } else {
      return []
    }
  }

  const verifyPhone = async () => {
    handleObjChange(setPhoneVerificationRes, 'loading', true)
    const phoneRes = await numlookupPhoneValidate(customerData.phone)
    if (phoneRes) {
      handleObjChange(setPhoneVerificationRes, 'loading', false)
      setPhoneVerificationRes((ps) => ({
        ...ps,
        data: phoneRes,
      }))
    }
  }

  const verifyAddress = async () => {
    handleObjChange(setAddressVerificationRes, 'loading', true)
    const addressRes = await aftershipAddressValidationProxy({
      address: customerData.address,
      city: customerData.city,
      state: customerData.state,
      postal_code: customerData.zip,
      country: customerData.country,
    })
    if (addressRes) {
      handleObjChange(setAddressVerificationRes, 'loading', false)
      if (addressRes.status === 200) {
        setAddressVerificationRes((ps) => ({
          ...ps,
          data: addressRes?.data?.addressResult?.analysis,
        }))
      }
    }
  }

  const submitOrder = async () => {
    //NOTE user exits
    const errs = validateOrder()
    if (errs.length > 0) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    setLoading(true)

    let arrayOfFiles = []
    if (attachments.length) {
      await Promise.all(
        attachments.map(async (attachment, index) => {
          const res = await uploadFileToS3({ file: attachment.file })
          // save
          arrayOfFiles.push({
            uid: attachment.uid,
            name: attachment.name,
            path: res.data.filePath,
            createdAt: new Date().toISOString(),
            meta: {
              createdBy: user?.username,
            },
          })
        })
      )
    }

    let _manualProducts = manualProducts.filter(
      (val) => val.productName && val.strength && val.quantity
    )

    let _customProducts = customProducts.filter(
      (val) => val.productName && val.strength && val.quantity
    )

    setManualProducts(_manualProducts)
    setCustomProducts(_customProducts)

    let _manualProductsObj = _manualProducts.map((val) => {
      let obj = {}
      obj.productName = val.productName.productName
      obj.productCode = val.productName.productCode
      obj.productCategory = val.productCategory
      obj.shippingCompany = val.shippingCompany
      obj.strength = val.strength.strengthName
      obj.strengthCode = val.strength.strengthCode
      obj.quantity = val.quantity
      obj.quantityCode = val.quantityCode.quantityCode
      obj.quantityPrice = val.quantityPrice
      obj.quantityCost = val.cost

      obj.totalQuantity = val.totalQuantity
      obj.total = parseFloat(val.total).toFixed(2)
      obj.totalPrice = parseFloat(val.totalPrice).toFixed(2)
      return { ...obj }
    })

    let _customProductsObj = _customProducts.map((val) => {
      let obj = {}
      obj.productName = val.productName
      obj.productCategory = val.productCategory
      obj.shippingCompany = val.shippingCompany
      obj.strength = val.strength
      obj.quantity = val.quantity
      obj.quantityPrice = val.quantityPrice
      obj.quantityCost = val.cost
      obj.totalQuantity = val.totalQuantity
      obj.total = parseFloat(val.total).toFixed(2)
      obj.totalPrice = parseFloat(val.totalPrice).toFixed(2)
      return { ...obj }
    })

    let productData = [..._manualProductsObj, ..._customProductsObj]

    let payload = {
      orderLeadSource,
      isDiscountPercent: discountType === 'Percentage' ? 1 : 0,
      discount,
      profit,
      shipping,
      subtotal,
      total: grandtotal,
      payment: false,
      productData,
      customerData,
      offsetDate: new Date(),
      orderBy,
      refillDate,
      notifyViaEmail: notifyVia.email ? 1 : 0,
      notifyViaSms: notifyVia.sms ? 1 : 0,
      notifyViaWhatsapp: notifyVia.whatsapp ? 1 : 0,
      userId: isKeepAccountLinked && customerData.custid ? customerData.custid : undefined,
      prescriptionDocuments: JSON.stringify(arrayOfFiles),
    }

    const res = await addManualOrder(payload)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        clearState()
        addNewOrderToTable(res.data.invId)
        showSuccess('Order Placed', 'Manual order placed successfully')
        setNewManaulDialog(false)
      }
    }
  }
  const hideDialog = () => {
    resetUrl()
    clearState()
    setNewManaulDialog(false)
  }
  const searchOrderAddressOnInput = async (event) => {
    setTimeout(async () => {
      let _filteredOptions
      if (!event.query.trim().length) {
        _filteredOptions = [...avaliableAddressInDb]
      } else {
        let dbEData = await getAvailableAddress()
        _filteredOptions = dbEData.map((item) => {
          item.option = `
			
					${item.orderid} | 		${
            item.custid ? 'Linked' : 'Not Linked'
          } | ${item.fname.trim()} ${item.lname.trim()} | ${item.addressline1.trim()} | ${
            item.country
          }`
          return item
        })
      }
      setFilteredAddressInDbDisplayAry(_filteredOptions)
    }, 500)
  }
  const onAddressSelect = (value) => {
    setSearchAddressKeyword(value.orderid)
    setCustomerData({
      ...value,
      address: value.addressline1,
      zip: value.postalcode,
      phone: value.phone.replace(/\s/g, ''),
    })
    setAddressVerificationRes(verifyEmpty)
    setPhoneVerificationRes(verifyEmpty)
  }

  const onCustomerChange = (event) => {
    const { name, value } = event.target
    if (['address', 'city', 'state', 'zip', 'country'].includes(name)) {
      setAddressVerificationRes(verifyEmpty)
    }
    if (['phone'].includes(name)) {
      setPhoneVerificationRes(verifyEmpty)
    }
    setCustomerData({ ...customerData, [name]: value })
  }

  const onManualProductStrengthChange = async (e, index) => {
    if (e.value) {
      let _manualProducts = [...manualProducts]
      _manualProducts[index]['strength'] = e.value

      _manualProducts[index]['quantity'] = null
      _manualProducts[index]['quantityCode'] = null
      _manualProducts[index]['quantityPrice'] = 0
      _manualProducts[index]['totalQuantity'] = 1
      _manualProducts[index]['total'] = 0
      _manualProducts[index]['totalPrice'] = 0

      setManualProducts(_manualProducts)
      const res = await searchQuantity(e.value?.strengthCode)
      let _quantityNames = [...quantityNames]
      _quantityNames[index] = res.data.filter((quantity) => quantity.qtyStatus === 'active')
      setQuantityNames(_quantityNames)
    }
  }
  const onManualProductNameChange = async (e, index) => {
    if (e.value) {
      let _manualProducts = [...manualProducts]
      _manualProducts[index]['productName'] = e.value
      _manualProducts[index]['productCategory'] = e.value?.productCategory
      _manualProducts[index]['shippingCompany'] = e.value?.shippingCompany
      _manualProducts[index]['strength'] = null
      _manualProducts[index]['quantity'] = null
      _manualProducts[index]['quantityCode'] = null
      _manualProducts[index]['quantityPrice'] = 0
      _manualProducts[index]['totalQuantity'] = 1
      _manualProducts[index]['total'] = 0
      _manualProducts[index]['totalPrice'] = 0

      setManualProducts(_manualProducts)
      const res = await searchStrength(e.value?.productCode)
      let _strengthNames = [...strengthNames]
      _strengthNames[index] = res.data.filter((strength) => strength.strengthStatus === 'active')
      setStrengthNames(_strengthNames)
    }
  }
  const onManualProductQuantityChange = async (e, index) => {
    if (e.value) {
      let _manualProducts = [...manualProducts]
      _manualProducts[index]['quantityCode'] = e.value
      _manualProducts[index]['quantity'] = e.value?.quantity
      _manualProducts[index]['quantityPrice'] = e.value?.price
      _manualProducts[index]['cost'] = e.value?.cost

      _manualProducts[index]['totalPrice'] = parseFloat(e.value?.price * e.value?.quantity).toFixed(
        2
      )
      _manualProducts[index]['total'] = (
        _manualProducts[index]['totalPrice'] * (_manualProducts[index]['totalQuantity'] || 1)
      ).toFixed(2)
      setManualProducts(_manualProducts)
      getShippingCharge()
    }
  }
  const onManualProductTotalQuantityChange = async (e, index) => {
    if (e.value) {
      let _manualProducts = [...manualProducts]
      _manualProducts[index]['totalQuantity'] = e.value
      _manualProducts[index]['total'] = (
        _manualProducts[index]['totalPrice'] * (_manualProducts[index]['totalQuantity'] || 1)
      ).toFixed(2)
      setManualProducts(_manualProducts)
      getShippingCharge()
    }
  }

  const getShippingCharge = async () => {
    const products = manualProducts
      .map((val) => {
        return {
          productCode: val?.productName?.productCode,
          strengthCode: val?.strength?.strengthCode,
          quantityCode: val?.quantityCode?.quantityCode,
          totalQuantity: val?.totalQuantity || 1,
        }
      })
      .filter((val) => val.productCode && val.strengthCode && val.quantityCode)

    const res = await checkoutShippingDetails(products)
    if (res && res.status === 200) {
      const { shippingCharges, totalShippingCharges } = res.data
      setShipping(totalShippingCharges)
    }
  }

  const copyManualProduct = (index) => {
    let _copy = { ...manualProducts[index] }
    _copy = {
      ..._copy,
      productCategory: 'Custom',
      productName: _copy.productName.productName,
      strength: _copy.strength.strengthName,
    }
    delete _copy['quantityCode']

    setCopiedManualOrder({ ..._copy })
  }

  const addManualProduct = () => {
    let _manualProducts = [...manualProducts]
    _manualProducts.push({ ...emptyManualProduct })
    setManualProducts(_manualProducts)
  }

  const deleteManualProduct = (index) => {
    let _manualProducts = [...manualProducts]
    _manualProducts.splice(index, 1)
    setManualProducts(_manualProducts)
  }
  const addCustomProduct = () => {
    let _customProducts = [...customProducts]
    _customProducts.push({ ...emptyCustomProduct })
    setCustomProducts(_customProducts)
  }
  const pasteManualProduct = (index) => {
    let _customProducts = [...customProducts]

    _customProducts[index] = { ...copiedManualOrder }
    setCustomProducts(_customProducts)
  }
  const deleteCustomProduct = (index) => {
    let _customProducts = [...customProducts]
    _customProducts.splice(index, 1)

    setCustomProducts(_customProducts)
  }
  const onCustomProductChange = (e, target, index) => {
    const value = e.value || e.target.value
    let _customeProducts = [...customProducts]
    _customeProducts[index][target] = value

    _customeProducts[index]['totalPrice'] = (
      _customeProducts[index]['quantityPrice'] * _customeProducts[index]['quantity']
    ).toFixed(2)

    _customeProducts[index]['total'] = (
      _customeProducts[index]['totalPrice'] * _customeProducts[index]['totalQuantity']
    ).toFixed(2)

    setCustomProducts(_customeProducts)
  }

  const handleFilesUpload = (e) => {
    if (!e.target.files.length) {
      return
    }
    const files = e.target.files
    const myFiles = []
    let largeFiles = 0
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (file.size < 1024 * 1024 * 5) {
        const path = URL.createObjectURL(file)
        myFiles.push({
          uid: nanoid(),
          name: file.name,
          file,
          path,
        })
      } else {
        largeFiles++
      }
    }
    if (largeFiles) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: `${largeFiles} files are larger than 5 MB and will not be uploaded`,
      })
    }
    setAttachments((ps) => [...ps, ...myFiles])
  }

  const removeFiles = (uid) => {
    let filValue = attachments.filter((ele) => ele.uid !== uid)
    setAttachments(filValue)
  }

  /* COMPONENTS */

  const setNewManaulDialogFooter = (
    <>
      {!loading && (
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      )}
      <Button
        disabled={loading}
        loading={loading}
        label={loading ? 'Placing...' : 'Place Order'}
        icon="pi pi-plus"
        className="p-button"
        onClick={submitOrder}
        autoFocus
      />
    </>
  )
  return (
    <Dialog
      header="Add Manual Order"
      visible={newManualOrderDialogVisible}
      style={{ width: '1200px' }}
      modal
      className="p-fluid dialog-content"
      footer={setNewManaulDialogFooter}
      onHide={hideDialog}
    >
      <div className="p-formgrid p-grid">
        {/* ***************************************************first column**************************************** */}
        <div className="p-field p-col-12">
          <div>
            <div className="p-d-flex p-ai-center gap-2">
              <h4 className="p-m-0">Shipping Details</h4>
              <div className="flex-grow p-field">
                <label>Search old order addresses</label>
                <AutoComplete
                  placeholder="Search shipping details by order-id or customer firstname"
                  autoHighlight
                  value={searchAddressKeyword}
                  field="option"
                  optionLabel="orderid"
                  suggestions={filteredAddressInDbDisplayAry}
                  completeMethod={searchOrderAddressOnInput}
                  onSelect={(e) => onAddressSelect(e.value)}
                  onChange={(e) => setSearchAddressKeyword(e.value)}
                  delay={500}
                />
              </div>
            </div>

            <hr />

            <div className="card">
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-4">
                  <label htmlFor="email">Email</label>
                  <InputText
                    id="email"
                    value={customerData.email}
                    onChange={onCustomerChange}
                    name="email"
                    required
                    placeholder="Enter email"
                  />
                </div>

                <div className="p-field p-col-4">
                  <label htmlFor="firstname">Firstname</label>
                  <InputText
                    id="firstname"
                    value={customerData.fname}
                    required
                    onChange={onCustomerChange}
                    name="fname"
                    placeholder="Enter firstname"
                  />
                </div>

                <div className="p-field p-col-4">
                  <label htmlFor="lastname">Lastname</label>
                  <InputText
                    id="lastname"
                    value={customerData.lname}
                    onChange={onCustomerChange}
                    name="lname"
                    placeholder="Enter lastname"
                  />
                </div>
                <div className="p-field p-col-4">
                  <label htmlFor="phone">Phone (with +XX country code)</label>
                  <InputText
                    value={customerData.phone}
                    name="phone"
                    onChange={(e) => {
                      let formattedNumbers = e.target.value
                      formattedNumbers = formattedNumbers.replace(/\s/g, '')
                      handleObjChange(setCustomerData, 'phone', formattedNumbers)
                    }}
                    placeholder="Enter phone"
                  />
                </div>
                <div className="p-field p-col-2">
                  <label htmlFor="country">Country</label>
                  <Dropdown
                    value={customerData.country}
                    options={objectifiedData}
                    optionLabel="name"
                    optionValue="name"
                    required
                    name="country"
                    onChange={onCustomerChange}
                    filter
                    showClear
                    editable
                    placeholder="Select country"
                  />
                </div>

                <div className="p-field p-col-2">
                  <label htmlFor="state">State</label>
                  <InputText
                    id="state"
                    value={customerData.state}
                    onChange={onCustomerChange}
                    name="state"
                    required
                    placeholder="Enter state"
                  />
                </div>
                <div className="p-field p-col-2">
                  <label htmlFor="city">City</label>
                  <InputText
                    id="city"
                    value={customerData.city}
                    name="city"
                    onChange={onCustomerChange}
                    required
                    placeholder="Enter city"
                  />
                </div>
                <div className="p-field p-col-2">
                  <label htmlFor="zip">Zip</label>
                  <InputText
                    value={customerData.zip}
                    name="zip"
                    onChange={onCustomerChange}
                    useGrouping={false}
                    placeholder="Enter zip"
                  />
                </div>
              </div>

              <div className="p-field">
                <label htmlFor="address">Address</label>
                <InputTextarea
                  id="address"
                  value={customerData.address}
                  name="address"
                  onChange={onCustomerChange}
                  required
                  rows={3}
                  cols={20}
                  placeholder="Enter address"
                />
              </div>
              <div className="p-field p-d-flex gap-1">
                {/* Address Verification */}
                <Button
                  className={classNames({
                    'p-button-success':
                      addressVerificationRes.data?.verificationStatus !== undefined,
                    'p-button-warning':
                      addressVerificationRes.data?.verificationStatus === undefined &&
                      addressVerificationRes.data !== null,
                  })}
                  icon={classNames('pi', {
                    'pi-map': addressVerificationRes.data === null,
                    'pi-check': addressVerificationRes.data?.verificationStatus !== undefined,
                    'pi-times':
                      addressVerificationRes.data?.verificationStatus === undefined &&
                      addressVerificationRes.data !== null,
                  })}
                  label={
                    addressVerificationRes.data
                      ? `Address ${
                          addressVerificationRes.data?.verificationStatus || 'Unverified'
                        } | ${addressVerificationRes.data?.addressPrecision || 'Invalid'}`
                      : 'Verify Address'
                  }
                  onClick={verifyAddress}
                  loading={addressVerificationRes.loading}
                  disabled={
                    !customerData.address ||
                    !customerData.city ||
                    !customerData.state ||
                    !customerData.zip ||
                    !customerData.country ||
                    addressVerificationRes.data !== null ||
                    addressVerificationRes.loading
                  }
                />
                {/* Phone Verification */}
                <Button
                  className={classNames({
                    'p-button-success': phoneVerificationRes.data?.valid === true,
                    'p-button-warning': phoneVerificationRes.data?.valid === false,
                  })}
                  icon={classNames('pi', {
                    'pi-phone': phoneVerificationRes.data === null,
                    'pi-check': phoneVerificationRes.data?.valid === true,
                    'pi-times': phoneVerificationRes.data?.valid === false,
                  })}
                  label={
                    phoneVerificationRes.data
                      ? `Phone Number ${
                          phoneVerificationRes.data?.valid ? 'Verified' : 'Unverified'
                        }`
                      : 'Verify Phone'
                  }
                  onClick={verifyPhone}
                  loading={phoneVerificationRes.loading}
                  disabled={
                    !customerData.phone ||
                    phoneVerificationRes.data !== null ||
                    phoneVerificationRes.loading
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* products details */}
        <div
          className="p-field p-col-12"
          // style={{ overflowY: 'auto', position: 'relative', height: '560px' }}
        >
          <h4>
            <span>Products Details</span>
          </h4>
          <hr />
          <div className="card">
            {/* labels */}
            <div className="">
              <div className="p-grid p-mt-1">
                <div className="p-field p-text-bold p-col-2 p-mb-0">
                  <h6 className="p-mb-0" htmlFor="price">
                    Product Name
                  </h6>
                </div>

                <div className="p-field p-text-bold p-col-2 p-mb-0">
                  <h6 className="p-mb-0" htmlFor="totalQuantity">
                    Strength
                  </h6>
                </div>

                <div className="p-field p-text-bold p-col-1 p-mb-0 ">
                  <h6 className="p-mb-0" htmlFor="price">
                    No. Pills
                  </h6>
                </div>

                <div className="p-field p-text-bold p-col-1 p-mb-0 ">
                  <h6 className="p-mb-0" htmlFor="totalQuantity">
                    Pill Price
                  </h6>
                </div>

                <div className="p-field p-text-bold p-col-1 p-mb-0">
                  <h6 className="p-mb-0" htmlFor="totalQuantity">
                    Units
                  </h6>
                </div>
                <div className="p-field p-text-bold p-col-2 p-mb-0">
                  <h6 className="p-mb-0" htmlFor="totalQuantity">
                    Total
                  </h6>
                </div>

                <div className="p-field p-text-bold p-col-1 p-mb-0">
                  <h6 className="p-mb-0" htmlFor="price">
                    Pill Cost
                  </h6>
                </div>
                <div className="p-field p-text-bold p-col-1  p-mb-0">
                  <h6 className="p-mb-0" htmlFor="price">
                    Shipping
                  </h6>
                </div>

                <div className="p-field p-text-bold p-mb-0 p-col-1 p-d-flex p-justify-center">
                  <h6 className="p-mb-0" htmlFor="totalQuantity">
                    Action
                  </h6>
                </div>

                <div className="p-col-1"></div>
              </div>
            </div>
            {/* manual products */}
            <div className=" p-pt-4">
              {manualProducts &&
                manualProducts.map((val, i) => {
                  return (
                    <div key={i} className="p-fluid p-grid p-mb-0">
                      <div className="p-field p-col-2">
                        <Dropdown
                          value={val.productName}
                          options={activeProductNames}
                          onChange={(e) => {
                            if (e.value.isStockOut) return
                            onManualProductNameChange(e, i)
                          }}
                          itemTemplate={(option) => (
                            <span
                              className={classNames({
                                'opacity-50': option.isStockOut,
                              })}
                            >
                              {option.productName}
                            </span>
                          )}
                          optionLabel="productName"
                          filter
                          filterBy="productName,altNames,ingredients,productCategory,shippingCompany"
                          placeholder="Select Product"
                          emptyMessage="Loading Products"
                        />
                        <span className="perprice-text">({val.productCategory})</span>
                      </div>

                      <div className="p-field p-col-2">
                        <Dropdown
                          value={val.strength}
                          options={strengthNames[i]}
                          onChange={(e) => {
                            if (e.value.isStockOut) return
                            onManualProductStrengthChange(e, i)
                          }}
                          itemTemplate={(option) => (
                            <span className={classNames({ 'opacity-50': option.isStockOut })}>
                              {option.strengthName}
                            </span>
                          )}
                          optionLabel="strengthName"
                          filter
                          filterBy="strengthName"
                          placeholder="Select Strength"
                          emptyMessage="No Strength found"
                        />
                      </div>

                      <div className="p-field p-col-1">
                        <Dropdown
                          value={val.quantityCode}
                          options={quantityNames[i]}
                          onChange={(e) => {
                            if (e.value.isStockOut) return
                            onManualProductQuantityChange(e, i)
                          }}
                          itemTemplate={(option) => (
                            <span className={classNames({ 'opacity-50': option.isStockOut })}>
                              {option.quantity}
                            </span>
                          )}
                          optionLabel="quantity"
                          filter
                          filterBy="quantity"
                          placeholder="Select Quantity"
                          emptyMessage="No Quantity found"
                        />
                      </div>

                      <div className="p-field p-col-1">
                        <InputNumber
                          prefix="$"
                          currency="USD"
                          maxFractionDigits={2}
                          minFractionDigits={2}
                          value={val.quantityPrice}
                          readOnly={true}
                          disabled
                          id="totalQuantity"
                          integeronly
                          step={0.25}
                        />
                      </div>
                      <div className="p-field p-col-1">
                        <InputNumber
                          step={1}
                          value={val.totalQuantity}
                          onChange={(e) => onManualProductTotalQuantityChange(e, i)}
                          id="totalQuantity"
                          integeronly
                        />
                      </div>

                      <div className="p-field p-col-2">
                        <InputNumber
                          prefix="$"
                          currency="USD"
                          value={val.total}
                          maxFractionDigits={2}
                          minFractionDigits={2}
                          disabled
                          id="totalQuantity"
                          integeronly
                        />
                        <span className="perprice-text">(Per price: ${val.totalPrice})</span>
                      </div>
                      <div className="p-field p-col-1">
                        <InputNumber
                          disabled
                          decimal
                          prefix="$"
                          currency="USD"
                          maxFractionDigits={2}
                          minFractionDigits={2}
                          value={val.cost}
                          onChange={(e) => onManualProductTotalQuantityChange(e, i)}
                          id="totalQuantity"
                          integeronly
                        />
                      </div>
                      <div className="p-field p-col-1">
                        <Dropdown
                          value={val.shippingCompany}
                          options={shippingTags}
                          placeholder="Shipper"
                        />
                      </div>

                      <div className="p-field p-col-1 p-d-flex p-ai-start p-jc-center p-pt-2">
                        <i
                          className="pi pi-trash p-px-1"
                          onClick={() => deleteManualProduct(i)}
                          style={{
                            fontSize: '1.5rem',
                            color: 'var(--orange-600)',
                            cursor: 'pointer',
                          }}
                        ></i>
                        {val.productName && val.quantity && val.strength && (
                          <i
                            className="pi pi-copy p-px-1"
                            onClick={() => copyManualProduct(i)}
                            style={{
                              fontSize: '1.5rem',
                              color: 'var(--blue-600)',
                            }}
                          ></i>
                        )}
                      </div>

                      {/* {submitted && !item.productName && <small className="p-invalid">Product name is required.</small>} */}
                    </div>
                  )
                })}

              {/* custom product */}
              {customProducts && customProducts.length > 0 && (
                <>
                  {customProducts.map((val, idx) => {
                    return (
                      <div key={idx} className="p-fluid p-grid">
                        <div className=" p-col-2">
                          <InputText
                            placeholder="Product Name"
                            value={val?.productName}
                            onChange={(e) => onCustomProductChange(e, 'productName', idx)}
                            id="cProductName"
                          />
                          <span className="perprice-text">({val.productCategory})</span>
                        </div>

                        <div className="p-field p-col-2">
                          <InputText
                            placeholder="Product Strength"
                            value={val?.strength}
                            onChange={(e) => onCustomProductChange(e, 'strength', idx)}
                            id="cStrengthName"
                            integeronly
                          />
                        </div>

                        <div className="p-field p-col-1">
                          <InputNumber
                            step={50}
                            value={val?.quantity}
                            onValueChange={(e) => onCustomProductChange(e, 'quantity', idx)}
                            id="cQuantity"
                            integeronly
                          />
                        </div>
                        <div className="p-field p-col-1">
                          <InputNumber
                            prefix="$"
                            currency="USD"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            step={0.25}
                            value={val?.quantityPrice}
                            onValueChange={(e) => onCustomProductChange(e, 'quantityPrice', idx)}
                            id="cTotalQuantity"
                            integeronly
                          />
                        </div>
                        <div className="p-field p-col-1">
                          <InputNumber
                            value={val?.totalQuantity}
                            onValueChange={(e) => onCustomProductChange(e, 'totalQuantity', idx)}
                            id="cTotalQuantity"
                            integeronly
                          />
                        </div>
                        <div className="p-field p-col-2">
                          <InputNumber
                            prefix="$"
                            currency="USD"
                            value={val?.total}
                            maxFractionDigits={2}
                            minFractionDigits={2}
                            disabled
                            // onChange={(e) => onCustomProductChange(e, 'total', i)}
                            id="ctotal"
                          />
                          <span className="perprice-text">(Per price: ${val?.totalPrice})</span>
                        </div>
                        <div className="p-field p-col-1">
                          <InputNumber
                            prefix="$"
                            currency="USD"
                            step={0.25}
                            maxFractionDigits={2}
                            minFractionDigits={2}
                            value={val?.cost}
                            onValueChange={(e) => onCustomProductChange(e, 'cost', idx)}
                            id="cTotalQuantity"
                            integeronly
                          />
                        </div>
                        <div className="p-field p-col-1">
                          <Dropdown
                            value={val?.shippingCompany}
                            options={shippingTags}
                            placeholder="Shipper"
                            onChange={(e) => onCustomProductChange(e, 'shippingCompany', idx)}
                          />
                        </div>

                        <div className="p-field p-col-1 p-d-flex p-ai-start p-jc-center p-pt-2">
                          <i
                            className="pi pi-trash p-px-1"
                            onClick={() => deleteCustomProduct(idx)}
                            style={{
                              fontSize: '1.5rem',
                              color: 'var(--orange-600)',
                              cursor: 'pointer',
                            }}
                          ></i>
                          {copiedManualOrder.productName && (
                            <i
                              className="pi pi-copy p-px-1"
                              onClick={() => pasteManualProduct(idx)}
                              style={{
                                fontSize: '1.5rem',
                                color: 'var(--green-600)',
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
            {/* add product buttons */}
            <div className="p-field p-grid ">
              <div className="p-col-6">
                <Button
                  icon="pi pi-plus"
                  label="Add Global-pharma Product"
                  onClick={() => addManualProduct()}
                  className=" p-button p-text-white"
                />
              </div>
              <div className="p-col-6">
                <Button
                  onClick={() => addCustomProduct()}
                  icon="pi pi-plus"
                  label="Add Custom Product"
                  className=" p-button-info p-text-white"
                />
              </div>
            </div>
          </div>
        </div>
        {/* other details */}
        <div className="p-field p-col-12">
          <h4 className="p-d-flex p-jc-between  p-ai-end">
            <span>Other Details</span>
          </h4>
          <hr />
          <div className="card">
            <div className="p-grid p-formgrid">
              <div className="p-col-12 p-md-3">
                <div className="p-d-flex p-flex-column gap-1">
                  <h6 className="p-m-0">Order Lead Source</h6>
                  <div className="p-d-flex gap-1by4">
                    <RadioButton
                      inputId="INDMRT"
                      name="orderLeadSource"
                      value="INDMRT"
                      onChange={(e) => setOrderLeadSource(e.value)}
                      checked={orderLeadSource === 'INDMRT'}
                    />
                    <label htmlFor="INDMRT">INDMRT</label>
                  </div>
                  <div className="p-d-flex gap-1by4">
                    <RadioButton
                      inputId="ORGANIC"
                      name="orderLeadSource"
                      value="ORGANIC"
                      onChange={(e) => setOrderLeadSource(e.value)}
                      checked={orderLeadSource === 'ORGANIC'}
                    />
                    <label htmlFor="ORGANIC">ORGANIC</label>
                  </div>
                  <div className="p-d-flex gap-1by4">
                    <RadioButton
                      inputId="REORDER"
                      name="orderLeadSource"
                      value="REORDER"
                      onChange={(e) => setOrderLeadSource(e.value)}
                      checked={orderLeadSource === 'REORDER'}
                    />
                    <label htmlFor="REORDER">REORDER</label>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-3">
                {!['agent'].includes(user.role) && (
                  <div className="p-d-flex p-flex-column gap-1by2">
                    <h6 className="p-m-0">Assign Order To</h6>
                    <div className="p-field">
                      <Dropdown
                        showClear
                        options={
                          user.role === 'teamlead'
                            ? user?.members
                            : user?.members?.filter(
                                (m) => m.role === 'agent' || m.role === 'teamlead'
                              )
                        }
                        optionLabel="username"
                        optionValue="id"
                        filter
                        filterBy="username,role"
                        filterPlaceholder="Search agent"
                        placeholder="Assign order to"
                        value={orderBy}
                        onChange={(e) => setOrderBy(e.value)}
                      />
                    </div>
                  </div>
                )}
                <div className="p-d-flex p-flex-column gap-1by2">
                  <h6 className="p-m-0">Refill Date</h6>
                  <div className="p-field">
                    <Calendar
                      // options={
                      //   user.role === 'teamlead'
                      //     ? user?.members
                      //     : user?.members?.filter(
                      //         (m) => m.role === 'agent' || m.role === 'teamlead'
                      //       )
                      // }
                      // optionLabel="username"
                      // optionValue="id"
                      // filter
                      // filterBy="username,role"
                      // filterPlaceholder="Search agent"
                      // placeholder="dd/mm/yy"
                      dateFormat="dd/mm/yy"
                      value={refillDate}
                      onChange={(e) => setRefillDate(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-3">
                <div className="p-d-flex p-flex-column gap-1">
                  <h6 className="p-m-0">Notify Customer Via</h6>
                  <div className="p-d-flex gap-1by4">
                    <Checkbox
                      inputId="Email"
                      name="notifyVia"
                      onChange={(e) => setNotifyVia((ps) => ({ ...ps, email: e.checked }))}
                      checked={notifyVia.email}
                    />
                    <label htmlFor="Email">Email</label>
                  </div>
                  <div className="p-d-flex gap-1by4">
                    <Checkbox
                      inputId="SMS"
                      name="notifyVia"
                      onChange={(e) => setNotifyVia((ps) => ({ ...ps, sms: e.checked }))}
                      checked={notifyVia.sms}
                    />
                    <label htmlFor="SMS">SMS</label>
                  </div>
                  <div className="p-d-flex gap-1by4">
                    <Checkbox
                      inputId="WhatsApp"
                      name="notifyVia"
                      onChange={(e) => setNotifyVia((ps) => ({ ...ps, whatsapp: e.checked }))}
                      checked={notifyVia.whatsapp}
                    />
                    <label htmlFor="WhatsApp">WhatsApp</label>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-3">
                <div className="p-d-flex p-flex-column gap-1">
                  <h6 className="p-m-0">Link With Customer Account</h6>
                  <div className="p-d-flex gap-1by4">
                    <InputSwitch
                      disabled={!customerData.custid}
                      checked={!customerData.custid ? false : isKeepAccountLinked}
                      onChange={(e) => setIsKeepAccountLinked(e.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* pricing details */}
        <div className="p-field p-col-12">
          {/* total section  */}
          <div className="card">
            <div className="p-grid p-formgrid">
              <div className=" p-col-12 p-field">
                <div className="p-formgrid p-grid p-jc-between">
                  <div className="p-col-6 p-grid p-formgrid p-mt-2">
                    <div className=" p-fluid p-field  p-col-6">
                      <label className="p-col-fixed p-text-bold">Discount Type</label>
                      <Dropdown
                        value={discountType}
                        onChange={(e) => {
                          setDiscountType(e.value)
                          setDiscountValue(0)
                        }}
                        options={discountOptions}
                      />
                    </div>
                    <div className=" p-fluid p-field  p-col-6">
                      <label className="p-col-fixed p-text-bold">Discount Value</label>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        step={10}
                        min={0}
                        value={discountValue}
                        onChange={(e) => {
                          setDiscountValue(e.value)
                        }}
                        prefix={discountType === discountOptions[1] && '$'}
                        suffix={discountType === discountOptions[0] && '%'}
                      />
                    </div>{' '}
                    <div className="  p-fluid p-field p-col-12">
                      <label className="p-col-fixed p-text-bold">Shipping Charges</label>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        step={10}
                        min={0}
                        value={shipping}
                        onChange={(e) => setShipping(e.value)}
                        prefix="$"
                      />
                    </div>
                    <div className="p-col-12">
                      <DataTable
                        showGridlines
                        value={[
                          { name: 'Subtotal', value: subtotal },
                          { name: 'Shipping', value: shipping },
                          { name: 'Discount', value: discount },
                          { name: 'Total', value: grandtotal },
                        ]}
                        responsiveLayout="scroll"
                      >
                        <Column
                          field="name"
                          body={(rd) => {
                            return rd.name === 'Total' ? (
                              <h5 className="p-m-0">{rd.name}</h5>
                            ) : (
                              <div>{rd.name}</div>
                            )
                          }}
                          header=""
                          style={{ width: '50%' }}
                        ></Column>
                        <Column
                          field="value"
                          body={(rd) => {
                            let val = rd.value ? rd.value : '0'
                            return rd.name === 'Total' ? (
                              <h5 className="p-m-0">${parseFloat(val).toFixed(2)}</h5>
                            ) : (
                              <div>${parseFloat(val).toFixed(2)}</div>
                            )
                          }}
                          header="Amount"
                          style={{ width: '50%' }}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                  <div className=" p-fluid p-field p-col-6 p-mt-2">
                    <label className="p-col-fixed p-text-bold">Attach prescription</label>
                    <input
                      type="file"
                      className="p-hidden abcd"
                      accept="image/*"
                      ref={featureImage}
                      multiple
                      onChange={(e) => {
                        handleFilesUpload(e)
                      }}
                    />
                    <Button
                      label="Choose Files"
                      className="p-button-outlined p-mb-6"
                      onClick={() => {
                        featureImage.current.click()
                      }}
                    />

                    {/* <div className="p-field p-fluid p-col-12 p-mb-0"> */}
                    {attachments?.map((item, idx) => {
                      let { name, path } = item
                      if (name.length > 40) {
                        name = name.substring(0, 15) + '...' + name.substring(name.length - 20)
                      }
                      return (
                        <div key={idx} className="relative p-grid p-formgrid">
                          <div className=" p-d-flex p-jc-center p-field p-col-11">
                            <a
                              className={`p-button p-button-outlined p-button-secondary`}
                              key={name}
                              href={path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {name}
                            </a>
                          </div>
                          <div className=" p-d-flex p-jc-center p-field p-col">
                            <Button
                              className="p-button-danger"
                              onClick={() => removeFiles(item.uid)}
                              icon="pi pi-trash"
                            />
                          </div>
                          <span
                            className={`${item.new ? 'blob bottom-15-px left-0-px p-mx-0' : ''}`}
                          ></span>
                        </div>
                      )
                    })}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* errors details */}
        <div className="p-field p-col-12">
          <div className="p-fluid p-grid p-formgrid p-mt-3">
            {newRecordError.state && (
              <>
                {newRecordError.errors.map((err, idx) => (
                  <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                ))}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    type="button"
                    onClick={() => setNewRecordError(emptyErr)}
                    icon="pi pi-times"
                    label="Clear Warnings"
                    className="p-button-secondary"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  )
}
