import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { MultiSelect } from 'primereact/multiselect'
import { Password } from 'primereact/password'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { deleteTeam, getTeam, getTeams, patchTeam, postTeam } from '../../api'
import { useGlobalContext } from '../../context'
import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'

export default function Teams() {
  const { agents, user } = useGlobalContext()
  const toast = useRef(null)
  const dt = useRef(null)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = searchParams.get('id')
  const [expandedRows, setExpandedRows] = useState(null)
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  }
  const onChangeFilter = (value, target) => {
    let _filters = { ...filters }
    _filters[target].value = value
    setFilters(_filters)
  }
  const [filters, setFilters] = useState(emptyFilterObject)
  const [password, setPassword] = useState('')

  const [tableLoading, setTableLoading] = useState(false)
  const emptyErr = {
    state: false,
    errors: [],
  }
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [editRecordError, setEditRecordError] = useState(emptyErr)
  const emptyDelete = {
    id: null,
    state: false,
    visible: false,
  }
  const [deleteRecordLoading, setDeleteRecordLoading] = useState(emptyDelete)
  const handleDeleteRecordLoadingChange = (target, value) => {
    setDeleteRecordLoading((ps) => ({ ...ps, [target]: value }))
  }
  const resetDeleteRecordLoading = () => {
    setDeleteRecordLoading(emptyDelete)
    setPassword('')
  }

  const emptyNewRecord = {
    teamName: '',
    teamManager: null,
    teamLead: null,
    teamMembers: [],
    password: '',
    loading: false,
  }
  const [newRecordData, setNewRecordData] = useState(emptyNewRecord)
  const [records, setRecords] = useState([])

  const [editRecordData, setEditRecordData] = useState(emptyNewRecord)
  const [recordVisible, setRecordVisible] = useState(false)

  const handleNewRecordChanges = (target, value) => {
    setNewRecordData((ps) => ({ ...ps, [target]: value }))
  }
  const handleEditRecordChanges = (target, value) => {
    setEditRecordData((ps) => ({ ...ps, [target]: value }))
  }
  const resetNewRecord = () => {
    setNewRecordData(emptyNewRecord)
  }
  const resetEditRecord = () => {
    setEditRecordData(emptyNewRecord)
  }
  const validateNewRecord = (item) => {
    const { teamName, teamManager, teamLead, teamMembers, password } = item
    const err = []
    if (!teamName) {
      err.push('Team name is invalid')
    }
    if (!teamManager) {
      err.push('Team manager is invalid')
    }
    if (!teamLead) {
      err.push('Team lead is invalid')
    }
    if (!teamMembers) {
      err.push('Team members is invalid')
    }
    if (!password) {
      err.push('Password is invalid')
    }
    return err
  }
  const handleSubmitNewRecord = async () => {
    const errs = validateNewRecord(newRecordData)
    if (errs.length > 0) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
    } else {
      handleNewRecordChanges('loading', true)
      setNewRecordError(emptyErr)
      const { teamName, teamManager, teamLead, teamMembers, password } = newRecordData

      const res = await postTeam({
        teamName,
        teamManager,
        teamLead,
        teamMembers,
        password,
      })
      if (res) {
        handleNewRecordChanges('loading', false)
        if (res.status === 201) {
          toast.current.show({
            severity: 'success',
            summary: 'Operation Successful',
            detail: res.data.message,
          })
          resetNewRecord()
          setRecords((ps) => [...res.data.data, ...ps])
        }
      }
    }
  }

  const handleSubmitEditRecord = async () => {
    const errs = validateNewRecord(editRecordData)
    if (errs.length > 0) {
      setEditRecordError({
        state: true,
        errors: errs,
      })
    } else {
      handleEditRecordChanges('loading', true)
      setEditRecordError(emptyErr)
      const { teamName, teamManager, teamLead, teamMembers, password } = editRecordData

      const res = await patchTeam(id, {
        teamName,
        teamManager,
        teamLead,
        teamMembers,
        password,
      })
      if (res) {
        handleEditRecordChanges('loading', false)
        if (res.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Operation Successful',
            detail: res.data.message,
          })
          setRecords((ps) =>
            [...ps].map((item) => {
              if (item.id === res.data.data[0].id) {
                return res.data.data[0]
              }
              return item
            })
          )
          resetEditRecord()
          hideRecord()
        }
      }
    }
  }

  const fetchRecords = useCallback(async () => {
    setTableLoading(true)
    const res = await getTeams()
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setRecords(res.data)
      } else {
        setRecords([])
      }
    }
  }, [])
  useEffect(() => fetchRecords(), [fetchRecords])

  const hideRecord = () => {
    setEditRecordData(newRecordData)
    setRecordVisible(false)
    history.push({
      pathname: '/users/teams',
    })
  }
  const fetchRecord = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getTeam(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 404) {
        hideRecord()
      }
      if (res.status === 200) {
        setEditRecordData((ps) => ({
          ...ps,
          ...res.data,
        }))
        setRecordVisible(true)
      }
    }
  }, [id])
  useEffect(() => fetchRecord(), [fetchRecord])
  useEffect(() => [editRecordData])

  const allowExpansion = (rowData) => {
    return rowData.members.length > 0
  }

  const rowExpansionTemplate = (data) => {
    return (
      <div className="w-full">
        <h5>Team {data.teamName}</h5>
        <DataTable value={data.members}>
          <Column field="userId" header="Agent Id" sortable></Column>
          <Column field="username" header="Agent Name" sortable></Column>
        </DataTable>
      </div>
    )
  }
  const handleRecordDelete = async () => {
    const id = deleteRecordLoading.id
    handleDeleteRecordLoadingChange('state', true)
    const res = await deleteTeam(id, password)
    if (res) {
      handleDeleteRecordLoadingChange('state', false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successfull',
          detail: res.data.message,
        })
        const _records = [...records].filter((item) => item.id !== id)
        resetDeleteRecordLoading()
        setRecords(_records)
      }
    }
  }
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'teamlead', 'agent']}>
      <div className="card">
        <Toast ref={toast} />
        <TabView>
          <TabPanel header="Teams">
            <Toolbar
              className="p-toolbar p-mb-3 p-flex-wrap gap-1"
              left={
                <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                  <h4 className="p-m-0">Teams</h4>
                </div>
              }
              right={
                <div>
                  <div className="p-d-flex p-flex-wrap gap-1by2">
                    <div>
                      <Button icon="pi pi-refresh" onClick={fetchRecords} />
                    </div>

                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        value={filters.global.value}
                        onChange={(e) => {
                          onChangeFilter(e.target.value, 'global')
                        }}
                        placeholder="Search..."
                      />
                    </span>
                  </div>
                </div>
              }
            ></Toolbar>
            <DataTable
              value={records}
              ref={dt}
              filters={filters}
              loading={tableLoading}
              dataKey="id"
              paginator
              showGridlines
              rows={10}
              rowsPerPageOptions={[10, 25, 50]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
              rowHover
              emptyMessage="No record found."
              breakpoint="1200px"
              responsiveLayout="stack"
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
            >
              <Column expander={allowExpansion} style={{ width: '3em' }} />
              <Column header="ID" field="id" />
              <Column header="Team Name" field="teamName" />
              <Column header="Team Manager" field="teamManager" />
              <Column header="Team Lead" field="teamLead" />
              <Column header="Total Members" field="totalMembers" />
              <Column
                header="Action"
                body={(rd) => (
                  <div className="p-d-flex gap-1">
                    <Link to={`?id=${rd?.id}`}>
                      <Button icon="pi pi-pencil" />
                    </Link>
                    {['superadmin', 'admin'].includes(user.role) && (
                      <Button
                        icon="pi pi-trash"
                        className="p-button-danger"
                        onClick={() => {
                          handleDeleteRecordLoadingChange('id', rd.id)
                          handleDeleteRecordLoadingChange('visible', true)
                        }}
                        loading={rd.id === deleteRecordLoading.id && deleteRecordLoading.state}
                        disabled={rd.id === deleteRecordLoading.id && deleteRecordLoading.state}
                      />
                    )}
                  </div>
                )}
              />
            </DataTable>
          </TabPanel>
          {['superadmin', 'admin', 'manager'].includes(user.role) && (
            <TabPanel header="Add Team">
              <div className="p-fluid p-grid p-formgrid">
                <div className="p-col-12 p-lg-12 p-fluid p-field">
                  <label className="p-col-fixed">Team Name</label>
                  <InputText
                    placeholder="Enter the name of team"
                    value={newRecordData.teamName}
                    onChange={(e) => handleNewRecordChanges('teamName', e.target.value)}
                  />
                </div>
                <div className="p-col-12 p-lg-6 p-fluid p-field">
                  <label className="p-col-fixed">Team Manager</label>
                  <Dropdown
                    placeholder="Select a team manager"
                    options={agents.filter((i) => i.role === 'manager')}
                    optionLabel="username"
                    optionValue="id"
                    filter
                    filterBy="username"
                    filterPlaceholder="Search..."
                    value={newRecordData.teamManager}
                    onChange={(e) => handleNewRecordChanges('teamManager', e.value)}
                  />
                </div>
                <div className="p-col-12 p-lg-6 p-fluid p-field">
                  <label className="p-col-fixed">Team Lead</label>
                  <Dropdown
                    placeholder="Select a team lead"
                    options={agents.filter((i) => i.role === 'teamlead')}
                    optionLabel="username"
                    optionValue="id"
                    filter
                    filterBy="username"
                    filterPlaceholder="Search..."
                    value={newRecordData.teamLead}
                    onChange={(e) => handleNewRecordChanges('teamLead', e.value)}
                  />
                </div>
                <div className="p-col-12 p-fluid p-field">
                  <label className="p-col-fixed">Team Members</label>
                  <MultiSelect
                    placeholder="Select team members"
                    options={agents.filter((i) => i.role === 'agent')}
                    optionLabel="username"
                    optionValue="id"
                    filter
                    filterBy="username"
                    filterPlaceholder="Search..."
                    value={newRecordData.teamMembers}
                    onChange={(e) => handleNewRecordChanges('teamMembers', e.value)}
                    display="chip"
                  />
                </div>
                <div className="p-col-12 p-fluid p-field">
                  <label className="p-col-fixed">Your Password</label>
                  <Password
                    toggleMask
                    feedback={false}
                    value={newRecordData.password}
                    onChange={(e) => handleNewRecordChanges('password', e.target.value)}
                    className="w-full p-mt-1"
                    placeholder="Enter your password"
                  />
                </div>
                {newRecordError.state && (
                  <>
                    {newRecordError.errors.map((err, idx) => (
                      <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                        <Message text={err} severity="warn" sticky={true} />
                      </div>
                    ))}
                    <div className="p-fluid p-field p-col-12">
                      <Button
                        type="button"
                        onClick={() => setNewRecordError(emptyErr)}
                        icon="pi pi-times"
                        label="Clear Warnings"
                        className="p-button-secondary"
                      />
                    </div>
                  </>
                )}
                <div className="p-col-12 p-fluid p-field">
                  <Button
                    loading={newRecordData.loading}
                    disabled={newRecordData.loading}
                    label={newRecordData.loading ? 'Submitting' : 'Submit'}
                    icon="pi pi-save"
                    onClick={handleSubmitNewRecord}
                  />
                </div>
              </div>
            </TabPanel>
          )}
        </TabView>

        <Dialog
          visible={recordVisible}
          header={`Team #${id}`}
          className="w-full"
          style={{ width: '90%' }}
          onHide={hideRecord}
          footer={
            <div>
              <Button
                label="Cancel"
                className="p-button-text"
                icon="pi pi-times"
                onClick={hideRecord}
              />
              <Button
                loading={editRecordData.loading}
                disabled={editRecordData.loading}
                label={editRecordData.loading ? 'Saving...' : 'Save'}
                icon="pi pi-save"
                onClick={handleSubmitEditRecord}
              />
            </div>
          }
        >
          <div className="p-fluid p-grid p-formgrid">
            <div className="p-col-12 p-lg-4 p-fluid p-field">
              <label className="p-col-fixed">Team Name</label>
              <InputText
                placeholder="Enter the name of team"
                value={editRecordData.teamName}
                onChange={(e) => handleEditRecordChanges('teamName', e.target.value)}
              />
            </div>
            <div className="p-col-12 p-lg-4 p-fluid p-field">
              <label className="p-col-fixed">Team Manager</label>
              <Dropdown
                placeholder="Select a team manager"
                disabled={!['superadmin', 'admin'].includes(user.role)}
                options={agents.filter((i) => i.role === 'manager')}
                optionLabel="username"
                optionValue="id"
                filter
                filterBy="username"
                filterPlaceholder="Search..."
                value={editRecordData.teamManager}
                onChange={(e) => handleEditRecordChanges('teamManager', e.value)}
              />
            </div>
            <div className="p-col-12 p-lg-4 p-fluid p-field">
              <label className="p-col-fixed">Team Lead</label>
              <Dropdown
                placeholder="Select a team lead"
                disabled={!['superadmin', 'admin', 'manager'].includes(user.role)}
                options={agents.filter((i) => i.role === 'teamlead')}
                optionLabel="username"
                optionValue="id"
                filter
                filterBy="username"
                filterPlaceholder="Search..."
                value={editRecordData.teamLead}
                onChange={(e) => handleEditRecordChanges('teamLead', e.value)}
              />
            </div>
            <div className="p-col-12 p-fluid p-field">
              <label className="p-col-fixed">Team Members</label>
              <MultiSelect
                placeholder="Select team members"
                options={agents.filter((i) => i.role === 'agent')}
                optionLabel="username"
                optionValue="id"
                filter
                filterBy="username"
                filterPlaceholder="Search..."
                value={editRecordData.teamMembers}
                onChange={(e) => handleEditRecordChanges('teamMembers', e.value)}
                display="chip"
              />
            </div>
            <div className="p-col-12 p-fluid p-field">
              <label className="p-col-fixed">Your Password</label>
              <Password
                toggleMask
                feedback={false}
                value={editRecordData.password}
                onChange={(e) => handleEditRecordChanges('password', e.target.value)}
                className="w-full p-mt-1"
                placeholder="Enter your password"
              />
            </div>
            {editRecordError.state && (
              <>
                {editRecordError.errors.map((err, idx) => (
                  <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                ))}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    type="button"
                    onClick={() => setEditRecordError(emptyErr)}
                    icon="pi pi-times"
                    label="Clear Warnings"
                    className="p-button-secondary"
                  />
                </div>
              </>
            )}
          </div>
        </Dialog>

        <ConfirmDialogWithPassword
          onHide={resetDeleteRecordLoading}
          onSubmit={handleRecordDelete}
          visible={deleteRecordLoading.visible}
          loading={deleteRecordLoading.state}
          password={password}
          setPassword={setPassword}
          headerText="Delete Team"
          mainText={`Are you sure you want to delete team #${deleteRecordLoading?.id}?`}
        />
      </div>
    </PageAllowedToRoles>
  )
}
