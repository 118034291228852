import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getUsers } from '../../api'
import { FilterMatchMode } from 'primereact/api'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import ExportButton from '../../components/mini/ExportButton'
import AddUserDialog from '../../components/mycomponents/UserManagement/AddUserDialog'
import UserDialog from '../../components/mycomponents/UserManagement/UserDialog'
import { InputSwitch } from 'primereact/inputswitch'
import { useGlobalContext } from '../../context'
import { dtFilenameDate } from '../../functions/myDateFns'
import { onChangeFilter } from '../../functions/setter'
import { useHistory } from 'react-router-dom'
export default function Users() {
  const history = useHistory()
  const { user: currentUser, userOptions: ctx_usersOptions, toast } = useGlobalContext()
  const dt = useRef(null)
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    role: { value: null, matchMode: FilterMatchMode.IN },
  }
  const [filters, setFilters] = useState(emptyFilterObject)
  const [roleOptions, setRoleOptions] = useState(ctx_usersOptions)
  const getMyRoles = () => {
    if (currentUser.role === 'superadmin') {
      return ['agent', 'manager', 'teamlead', 'operations', 'admin']
    }
    if (currentUser.role === 'admin') {
      return ['agent', 'manager', 'teamlead']
    }
    if (currentUser.role === 'manager') {
      return ['agent', 'teamlead']
    }
    return []
  }

  useEffect(() => {
    if (!currentUser) return
    const myRoles = getMyRoles()
    return setRoleOptions(ctx_usersOptions.filter((i) => myRoles.includes(i.value)))
  }, [currentUser])
  const [records, setRecords] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [addDialogVisible, setAddDialogVisible] = useState(false)
  const fetchUsers = useCallback(async () => {
    const res = await getUsers()
    if (res && res.status === 200) {
      let users = res.data
      const myRoles = getMyRoles()
      users = users.filter(
        (agent) => agent.email !== currentUser.email && myRoles.includes(agent.role)
      )
      if (currentUser.role === 'manager') {
        users = users.filter((agent) => currentUser.members.map((i) => i.id).includes(agent.id))
      }
      setRecords(users)
    } else {
      setRecords([])
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Error fetching agents',
      })
    }
  }, [currentUser.email])
  useEffect(() => fetchUsers(), [fetchUsers])
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager']}>
      <div className="card">
        <Toolbar
          className="p-toolbar"
          left={() => (
            <div className="p-d-flex gap-1 p-ai-center">
              <h4 className="p-my-0">Users</h4>
              <Button
                onClick={() => setAddDialogVisible(true)}
                icon="pi pi-plus"
                label="Add User"
              />
            </div>
          )}
          right={() => (
            <div className="p-d-flex p-ai-center gap-1">
              <Button onClick={() => fetchUsers()} icon="pi pi-refresh" />
              <ExportButton datatable_ref={dt} />
            </div>
          )}
        ></Toolbar>
        <DataTable
          ref={dt}
          exportFilename={`Users_${dtFilenameDate}`}
          filters={filters}
          columnResizeMode="fit"
          removableSort
          value={records}
          showGridlines
          paginator={true}
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
          globalFilter={globalFilterValue}
          globalFilterFields={['username', 'email', 'role']}
          header={() => (
            <div className="p-grid gap-1 p-my-2">
              <div className="w-full p-d-flex p-jc-end gap-1">
                <div className="">
                  <MultiSelect
                    options={ctx_usersOptions}
                    optionLabel="name"
                    optionValue="value"
                    showClear
                    placeholder="Select Users"
                    value={filters.role.value}
                    onChange={(e) => {
                      onChangeFilter(setFilters, e.value, 'role')
                    }}
                  />
                </div>
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    type="search"
                    value={globalFilterValue}
                    onChange={(e) => {
                      setGlobalFilterValue(e.target.value)
                    }}
                    placeholder="Search..."
                  />
                </span>
              </div>
            </div>
          )}
        >
          <Column header="Id" sortable field="id" style={{ width: '0%' }} />
          <Column
            header="Avatar"
            field="avatar"
            style={{ width: '0%' }}
            body={(rd) => {
              return (
                <img
                  alt={rd.avatar}
                  src={`${process.env.REACT_APP_S3_BUCKET_URL}/Avatars/${rd.avatar}.png`}
                  onError={(e) =>
                    (e.target.src =
                      'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
                  }
                  className="w-3"
                  style={{ borderRadius: '50px' }}
                />
              )
            }}
          />
          <Column header="Role" sortable field="role" />
          <Column header="Name" sortable field="username" />
          <Column header="Email" sortable field="email" />
          <Column
            header="Logged"
            sortable
            field="status"
            body={(rd) => <div>{rd.status ? 'Yes' : 'No'}</div>}
          />
          <Column
            header="IP Restriction"
            sortable
            field="ipRestriction"
            body={(rd) => <div>{rd.ipRestriction ? 'Yes' : 'No'}</div>}
          />
          <Column
            header="Block"
            sortable
            field="block"
            body={(rd) => <div>{rd.block ? 'Yes' : 'No'}</div>}
          />
          <Column
            header="All Orders"
            sortable
            field="allOrderAccess"
            body={(rd) => <div>{rd.allOrderAccess ? 'Yes' : 'No'}</div>}
          />
          <Column
            header="All Unassigned Orders"
            sortable
            field="allUnassignedOrderAccess"
            body={(rd) => <div>{rd.allUnassignedOrderAccess ? 'Yes' : 'No'}</div>}
          />
          <Column
            header="Bin Orders Access"
            sortable
            field="binOrderAccess"
            body={(rd) => <div>{rd.binOrderAccess ? 'Yes' : 'No'}</div>}
          />
          <Column
            header="Action"
            body={(rd) => {
              return (
                <Button
                  icon="pi pi-eye"
                  onClick={() =>
                    history.push({
                      pathname: '/users',
                      search: `?id=${rd?.id}`,
                    })
                  }
                />
              )
            }}
          />
        </DataTable>
      </div>
      <AddUserDialog
        roleOptions={roleOptions}
        dialogVisible={addDialogVisible}
        setRecords={setRecords}
        setDialogVisible={setAddDialogVisible}
      />
      <UserDialog
        pathname={'/users'}
        roleOptions={roleOptions}
        setRecords={setRecords}
        records={records}
      />
    </PageAllowedToRoles>
  )
}
