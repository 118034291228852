import { format, subDays } from 'date-fns'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useState } from 'react'
import { Calendar } from 'primereact/calendar'
import { handleObjChange, onChangeFilter } from '../../functions/setter'
import { Message } from 'primereact/message'
import isEmail from 'validator/lib/isEmail'
import { useGlobalContext } from '../../context'
import { InputTextarea } from 'primereact/inputtextarea'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import {
  deleteDNDRecord,
  getAllDNDRecords,
  getDNDRecordById,
  patchDNDRecord,
  postDNDRecord as postDNDRecord,
} from '../../api'
import { Link } from 'react-router-dom'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useQuery from '../../hooks/useQuery'
import validatePhoneNumber from '../../functions/validatePhoneNumber'
export default function CustomerDNDPage() {
  const { toast, user } = useGlobalContext()
  const history = useHistory()
  const query = useQuery()
  const id = query.get('id')
  const customerDetails_empty = {
    email: '',
    phone: '',
    dndTillDate: '',
    note: '',
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  const [loading, setLoading] = useState(false)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [allDNDRecords, setAllDNDRecords] = useState([])
  const [password, setPassword] = useState('')
  const [addCustomerDialogVisible, setAddCustomerDialogVisible] = useState(false)
  const [customerDetails, setCustomerDetails] = useState(customerDetails_empty)
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [deleteRecordDetails, setDeleteRecordDetails] = useState(customerDetails_empty)
  const [editDialogVisisble, setEditDialogVisible] = useState(false)
  const [editRecordDetails, setEditRecordDetails] = useState(customerDetails_empty)
  const [customerFilters, setCustomerFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const fetchAllRecords = async () => {
    setLoading(true)
    const res = await getAllDNDRecords()
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        setAllDNDRecords(res.data.record)
      }
    }
  }

  useEffect(() => {
    fetchAllRecords()
  }, [])

  const fetchEditRecord = useCallback(async () => {
    if (!id) return
    setLoading(true)
    const res = await getDNDRecordById(id)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        setEditRecordDetails(res.data.value)
        setEditDialogVisible(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchEditRecord()
  }, [fetchEditRecord])

  const validateNewRecord = (email, phone) => {
    const err = []
    const { valid } = validatePhoneNumber(phone)
    if (!email) {
      err.push('Email is invalid')
    }
    if (email && !isEmail(email)) {
      err.push('Email format is invalid')
    }
    if (!phone) {
      err.push('Phone is invalid')
    }
    if (phone && !valid) {
      err.push('Phone number format is invalid')
    }
    return err
  }

  const createNewRecord = async () => {
    const { email, phone, dndTillDate, note } = customerDetails
    const errs = validateNewRecord(email, phone)
    if (errs.length) {
      setNewRecordError({
        errors: errs,
        state: true,
      })
      return
    }
    setLoading(true)
    const res = await postDNDRecord(email, phone, dndTillDate, note)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setAllDNDRecords((ps) => [res.data.record, ...ps])
        hideAddCustomerDialog()
      }
    }
  }

  const deleteRecord = async () => {
    const { id: recordId } = deleteRecordDetails
    setLoading(true)
    const res = await deleteDNDRecord(recordId, password)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'success',
          detail: res.data.message,
        })
        let _records = allDNDRecords
        _records = _records.filter((record) => record.id !== recordId)
        setAllDNDRecords(_records)
        hideDeleteDialog()
      }
    }
  }

  const updateRecord = async () => {
    const { id, email, phone, dndTillDate, note } = editRecordDetails
    const errs = validateNewRecord(email, phone)
    if (errs.length) {
      setNewRecordError({
        errors: errs,
        state: true,
      })
      return
    }
    setLoading(true)
    const res = await patchDNDRecord(id, email, phone, dndTillDate, note)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        let _records = allDNDRecords
        _records = _records.map((record, idx) => {
          if (record.id === id) {
            return editRecordDetails
          }
          return record
        })

        setAllDNDRecords(_records)
        hideEditRecordDialog()
      }
    }
  }

  const hideEditRecordDialog = () => {
    setEditDialogVisible(false)
    setEditRecordDetails(customerDetails_empty)
    history.push('/customers/dnd')
  }

  const hideAddCustomerDialog = () => {
    setAddCustomerDialogVisible(false)
    setCustomerDetails(customerDetails_empty)
    setNewRecordError(emptyErr)
  }

  const hideDeleteDialog = () => {
    setPassword('')
    setDeleteDialogVisible(false)
    setDeleteRecordDetails(customerDetails_empty)
  }

  const customerFiltersHeader = () => {
    return (
      <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
        {/* {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && ( */}
        <>
          <div>
            <Dropdown
              value={customerFilters.createdBy.value}
              placeholder="Created By"
              options={user.members}
              optionLabel="username"
              optionValue="id"
              showClear
              filter
              filterBy="username"
              onChange={(e) => {
                onChangeFilter(setCustomerFilters, e.value, 'createdBy')
              }}
            ></Dropdown>
          </div>
        </>
        {/* )} */}
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={customerFilters['global'] ? customerFilters['global'].value : '' || ''}
              onChange={(e) => onChangeFilter(setCustomerFilters, e.target.value, 'global')}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="card">
        <Toolbar
          className="p-toolbar p-mb-3 p-flex-wrap gap-1"
          left={
            <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
              <h4 className="p-m-0">Customer DND</h4>

              <Button
                icon="pi pi-plus"
                label="Add customer"
                onClick={() => {
                  setAddCustomerDialogVisible(true)
                }}
              />
            </div>
          }
          right={
            <div>
              <div className="p-d-flex p-flex-wrap gap-1">
                <Button
                  icon="pi pi-refresh"
                  onClick={() => {
                    fetchAllRecords()
                  }}
                />
                {/* <ExportButton datatable_ref={open_dt} /> */}
              </div>
            </div>
          }
        />
        <DataTable
          // ref={open_dt}
          // exportFilename={`Open_Complaints_${dtFilenameDate}`}
          removableSort
          filters={customerFilters}
          value={allDNDRecords}
          header={customerFiltersHeader}
          showGridlines
          loading={loading}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
        >
          <Column field="id" header="Id" sortable />
          <Column field="phone" header="Phone Number" style={{ width: '15%' }} />
          <Column field="email" header="Email" />
          <Column
            field="dndTillDate"
            header="DND Date"
            body={(rd) => (
              <>{rd?.dndTillDate ? format(new Date(rd.dndTillDate), 'dd-MM-yyyy') : 'Forever'}</>
            )}
          />
          <Column field="note" header="Note" />
          <Column
            field="username"
            header="Created By"
            style={{
              width: '10%',
            }}
          />
          <Column
            field="createdAt"
            header="Created At"
            body={(rd) => <>{format(new Date(rd.createdAt), 'dd-MM-yyyy')}</>}
          />

          <Column
            field="Action"
            header="Action"
            style={{
              width: '10%',
            }}
            body={(rd) => {
              return (
                <div className="p-d-flex gap-1by2 p-jc-start">
                  <Link to={`?id=${rd?.id}`}>
                    <Button
                      icon="pi pi-pencil"
                      tooltip="Edit"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                    />
                  </Link>

                  <Button
                    icon="pi pi-trash"
                    className="p-button-danger"
                    tooltip="Delete"
                    tooltipOptions={{
                      position: 'bottom',
                    }}
                    onClick={() => {
                      setDeleteDialogVisible(true)
                      setDeleteRecordDetails(rd)
                    }}
                  />

                  <Link to={`?history=${rd.id}`}>
                    <Button
                      tooltip="History"
                      tooltipOptions={{ position: 'bottom' }}
                      icon="pi pi-clock"
                      className="p-button p-button-help"
                    />
                  </Link>
                </div>
              )
            }}
          />
        </DataTable>
        <Dialog
          header="Add new customer"
          visible={addCustomerDialogVisible}
          style={{ width: '40%', minWidth: '400px' }}
          footer={
            <div>
              <Button
                loading={loading}
                label="Submit"
                className="p-button-submit"
                icon="pi pi-check"
                onClick={() => {
                  createNewRecord()
                }}
              />
              <Button
                label="Cancel"
                className="p-button-text"
                icon="pi pi-times"
                onClick={() => hideAddCustomerDialog()}
              />
            </div>
          }
          onHide={() => hideAddCustomerDialog()}
        >
          <div className="p-grid">
            <div className="p-field p-fluid p-col-12">
              <label className="p-d-block">
                Enter Email <sup style={{ color: 'red' }}>*</sup>
              </label>
              <InputText
                id="customerEmail"
                placeholder="Enter Customer Email"
                value={customerDetails.email}
                onChange={(e) => handleObjChange(setCustomerDetails, 'email', e.target.value)}
              />
            </div>
            <div className="p-field p-fluid p-col-12">
              <label className="p-d-block">
                Enter Phone (with +XX country code) <sup style={{ color: 'red' }}>*</sup>
              </label>
              <InputText
                id="customerPhone"
                placeholder="Enter Customer Phone"
                value={customerDetails.phone}
                onChange={(e) => handleObjChange(setCustomerDetails, 'phone', e.target.value)}
              />
            </div>
            <div className="p-field p-fluid p-col-12">
              <label className="p-d-block">
                Enter DND Date <sup style={{ color: 'transparent' }}>*</sup>
              </label>
              <Calendar
                id="DNDDATE"
                placeholder="Select Date"
                minDate={subDays(new Date(), 0)}
                value={customerDetails.dndTillDate}
                onChange={(e) => {
                  handleObjChange(setCustomerDetails, 'dndTillDate', e.value || '')
                }}
              />
            </div>
            <div className="p-field p-fluid p-col-12">
              <label className="p-d-block">
                Enter note (if any) <sup style={{ color: 'transparent' }}>*</sup>
              </label>
              <InputTextarea
                id="note"
                placeholder="Enter note"
                rows={3}
                value={customerDetails.note}
                onChange={(e) => handleObjChange(setCustomerDetails, 'note', e.target.value)}
              />
            </div>
            {newRecordError.state && (
              <>
                {newRecordError.errors.map((err, idx) => (
                  <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                ))}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    type="button"
                    onClick={() => setNewRecordError(emptyErr)}
                    icon="pi pi-times"
                    label="Clear Warnings"
                    className="p-button-secondary"
                  />
                </div>
              </>
            )}
          </div>
        </Dialog>
        <Dialog
          header="Update record"
          visible={editDialogVisisble}
          style={{ width: '40%', minWidth: '400px' }}
          footer={
            <div>
              <Button
                loading={loading}
                label="Submit"
                className="p-button-submit"
                icon="pi pi-check"
                onClick={() => {
                  updateRecord()
                }}
              />
              <Button
                label="Cancel"
                className="p-button-text"
                icon="pi pi-times"
                onClick={() => hideEditRecordDialog()}
              />
            </div>
          }
          onHide={() => hideEditRecordDialog()}
        >
          <div className="p-grid">
            <div className="p-field p-fluid p-col-12">
              <label className="p-d-block">
                Customer Email <sup style={{ color: 'red' }}>*</sup>
              </label>
              <InputText
                id="customerEmail"
                placeholder="Enter Customer Email"
                value={editRecordDetails.email}
                onChange={(e) => handleObjChange(setEditRecordDetails, 'email', e.target.value)}
              />
            </div>
            <div className="p-field p-fluid p-col-12">
              <label className="p-d-block">
                Enter Phone (with +XX country code) <sup style={{ color: 'red' }}>*</sup>
              </label>
              <InputText
                id="customerPhone"
                placeholder="Enter Customer Phone"
                value={editRecordDetails.phone}
                onChange={(e) => handleObjChange(setEditRecordDetails, 'phone', e.target.value)}
              />
            </div>
            <div className="p-field p-fluid p-col-12">
              <label className="p-d-block">
                Enter DND Date <sup style={{ color: 'transparent' }}>*</sup>
              </label>
              <Calendar
                id="DNDDATE"
                placeholder="Select Date"
                minDate={subDays(new Date(), 0)}
                value={editRecordDetails.dndTillDate ? new Date(editRecordDetails.dndTillDate) : ''}
                onChange={(e) => {
                  handleObjChange(setEditRecordDetails, 'dndTillDate', e.value || '')
                }}
              />
            </div>
            <div className="p-field p-fluid p-col-12">
              <label className="p-d-block">
                Enter note (if any) <sup style={{ color: 'transparent' }}>*</sup>
              </label>
              <InputTextarea
                id="note"
                placeholder="Enter note"
                rows={3}
                value={editRecordDetails.note}
                onChange={(e) => handleObjChange(setEditRecordDetails, 'note', e.target.value)}
              />
            </div>
            {newRecordError.state && (
              <>
                {newRecordError.errors.map((err, idx) => (
                  <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                ))}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    type="button"
                    onClick={() => setNewRecordError(emptyErr)}
                    icon="pi pi-times"
                    label="Clear Warnings"
                    className="p-button-secondary"
                  />
                </div>
              </>
            )}
          </div>
        </Dialog>
        <ConfirmDialogWithPassword
          password={password}
          setPassword={setPassword}
          visible={deleteDialogVisible}
          loading={loading}
          headerText={'Delete Record'}
          mainText={`Delete customer record with id: ${deleteRecordDetails?.id}`}
          onHide={hideDeleteDialog}
          onSubmit={deleteRecord}
        />
        <ChangeHistorySidebar
          setTableLoading={setLoading}
          header="DND Record History"
          historyType="customer-dnd"
        />
      </div>
    </>
  )
}
