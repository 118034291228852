import { AutoComplete } from 'primereact/autocomplete'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from 'primereact/toast'
import React, { useState, useRef } from 'react'
import { useGlobalContext } from '../../../context'
import { MultiSelect } from 'primereact/multiselect'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { handleObjChange } from '../../../functions/setter'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { postUserTicket, getUploadURL, uploadFileToS3 } from '../../../api'
import { subDays } from 'date-fns'
import { nanoid } from 'nanoid'
// import uploadFile from '../../../functions/uploadFile'
// import { postImage } from '../../../api/test'

const RaiseTicket = ({
  newTicketDialogVisible,
  setNewTicketDialogVisible,
  setAllUsersTickets,
  setMyTickets,
}) => {
  const toast = useRef(null)
  const { user, agents, teams } = useGlobalContext()
  const featureImage = useRef(null)
  const emptyTicketObject = {
    ticketType: '',
    assignToUsers: [],
    assignToTeam: '',
    subject: '',
    description: '',
    expectedTAT: '',
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [newTicketRecord, setNewTicketRecord] = useState(emptyTicketObject)
  const [attachments, setAttachments] = useState([])
  const [loading, setLoading] = useState(false)
  const validateNewRecord = () => {
    const errs = []
    const maxSize = 1024 * 1024 * 5
    const { ticketType, assignToUsers, assignToTeam, subject, description } = newTicketRecord
    if (!ticketType) {
      errs.push('Ticket type is mandatory')
    }
    if (!subject) {
      errs.push('Please enter a subject')
    }
    if (!assignToTeam && !assignToUsers.length) {
      errs.push('Please assign the ticket to either team or user(s)')
    }
    if (!description) {
      errs.push('Please enter the description')
    }
    //[x] Add condition for checking file size
    if (attachments.length) {
      attachments.forEach((file) => {
        if (file.file.size > maxSize) {
          errs.push('File should be less than 5 MB')
        }
      })
    }
    return errs
  }

  const createNewTicket = async () => {
    const errs = validateNewRecord()
    if (errs.length) {
      setNewRecordError({ state: true, errors: errs })
      return
    }
    setLoading(true)
    let arrayOfFiles = []
    if (attachments.length) {
      await Promise.all(
        attachments.map(async (attachment, index) => {
          const res = await uploadFileToS3({ file: attachment.file })
          // save
          arrayOfFiles.push({
            uid: attachment.uid,
            name: attachment.name,
            path: res.data.filePath,
            // New additions based on orders reference
            createdAt: new Date().toISOString(),
            meta: {
              createdBy: user?.username,
            },
          })
        })
      )
    }

    const res = await postUserTicket(newTicketRecord, arrayOfFiles)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setAllUsersTickets((prevstate) => [res.data.record, ...prevstate])
        setMyTickets((prevstate) => [res.data.record, ...prevstate])
        hideNewTicketDialog()
      }
    }
  }

  const hideNewTicketDialog = () => {
    setNewTicketDialogVisible(false)
    setNewTicketRecord(emptyTicketObject)
    setAttachments([])
    setLoading(false)
    setNewRecordError(emptyErr)
  }
  const handleFilesUpload = (e) => {
    if (!e.target.files.length) {
      return
    }
    const files = e.target.files
    const myFiles = []
    let largeFiles = 0
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (file.size < 1024 * 1024 * 5) {
        const path = URL.createObjectURL(file)
        myFiles.push({
          uid: nanoid(),
          name: file.name,
          file,
          path,
        })
      } else {
        largeFiles++
      }
    }
    if (largeFiles) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: `${largeFiles} files are larger than 5 MB and will not be uploaded`,
      })
    }
    setAttachments((ps) => [...ps, ...myFiles])
  }

  const removeFiles = (uid) => {
    let filValue = attachments.filter((ele) => ele.uid !== uid)
    setAttachments(filValue)
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={
          <div className="p-text-center">
            <h4 style={{ color: '#4B8AF4' }}>Raise Ticket</h4>
          </div>
        }
        closable={false}
        visible={newTicketDialogVisible}
        style={{ width: '100vw', maxWidth: '750px' }}
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => hideNewTicketDialog()}
            />
            <Button
              loading={loading}
              label={loading ? 'Opening...' : 'Open'}
              className="p-button-submit"
              icon="pi pi-check"
              onClick={() => {
                createNewTicket()
              }}
            />
          </div>
        }
      >
        <div className="p-grid" style={{ rowGap: '1rem' }}>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block" htmlFor="TicketType">
              Ticket Type <sup style={{ color: 'red' }}>*</sup>
            </label>
            <Dropdown
              id="TicketType"
              options={[
                {
                  name: 'Technical',
                },
                {
                  name: 'Non Technical',
                },
              ]}
              optionLabel="name"
              optionValue="name"
              placeholder="Select Ticket Type"
              value={newTicketRecord.ticketType}
              onChange={(e) => {
                handleObjChange(setNewTicketRecord, 'ticketType', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6 p-mb-0">
            <label className="p-d-block" htmlFor="AgentSelection">
              Assign To User
            </label>
            <MultiSelect
              disabled={newTicketRecord.assignToTeam}
              filter
              filterPlaceholder="Search..."
              showClear
              maxSelectedLabels={1}
              id="AgentSelection"
              options={agents.filter((agent) => agent.id !== user.id)}
              optionLabel="username"
              optionValue="id"
              placeholder="Individuals"
              value={newTicketRecord.assignToUsers}
              onChange={(e) => {
                handleObjChange(setNewTicketRecord, 'assignToUsers', e.target.value || [])
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6 p-mb-0">
            <label className="p-d-block" htmlFor="teamSelection">
              Assign To Team
            </label>
            <Dropdown
              disabled={newTicketRecord.assignToUsers.length > 0}
              filter
              filterPlaceholder="Search..."
              id="teamSelection"
              options={teams}
              optionLabel="teamName"
              optionValue="teamID"
              placeholder="Team"
              showClear
              value={newTicketRecord.assignToTeam}
              onChange={(e) => {
                handleObjChange(setNewTicketRecord, 'assignToTeam', e.target.value || '')
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block" htmlFor="subject">
              Subject <sup style={{ color: 'red' }}>*</sup>
            </label>
            <InputText
              placeholder="Enter Subject"
              id="subject"
              value={newTicketRecord.subject}
              onChange={(e) => {
                handleObjChange(setNewTicketRecord, 'subject', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block" htmlFor="description">
              Description <sup style={{ color: 'red' }}>*</sup>
            </label>
            <InputTextarea
              id="description"
              rows={5}
              cols={30}
              placeholder="Describe your problem here..."
              value={newTicketRecord.description}
              onChange={(e) => {
                handleObjChange(setNewTicketRecord, 'description', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6 p-mb-0">
            <label className="p-d-block" htmlFor="expectedTAT">
              Expected TAT
            </label>
            <Calendar
              id="expectedTAT"
              placeholder="Close Date"
              showTime
              hourFormat="12"
              minDate={subDays(new Date(), 0)}
              value={newTicketRecord.expectedTAT}
              onChange={(e) => {
                handleObjChange(setNewTicketRecord, 'expectedTAT', e.value || '')
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6 p-mb-0 ">
            <label className="p-d-block">Attachment</label>
            <input
              type="file"
              className="p-hidden abcd"
              accept="*"
              ref={featureImage}
              multiple
              onChange={(e) => {
                handleFilesUpload(e)
              }}
            />
            <Button
              label="Choose Files"
              className="p-button-outlined"
              onClick={() => {
                featureImage.current.click()
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            {attachments?.map((item, idx) => {
              let { name, path } = item
              if (name.length > 40) {
                name = name.substring(0, 15) + '...' + name.substring(name.length - 20)
              }
              return (
                <div key={idx} className="relative p-grid p-formgrid p-mb-4">
                  <div className=" p-d-flex p-jc-center p-field p-col-8">
                    <a
                      className={`p-button p-button-outlined p-button-secondary`}
                      key={name}
                      href={path}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {name}
                    </a>
                  </div>
                  <div className=" p-d-flex p-jc-center p-field p-col-4">
                    <Button
                      className="p-button-danger"
                      onClick={() => removeFiles(item.uid)}
                      label="Delete"
                    />
                  </div>
                  <span
                    className={`${item.new ? 'blob bottom-15-px left-0-px p-mx-0' : ''}`}
                  ></span>
                </div>
              )
            })}
          </div>
          {newRecordError.state && (
            <>
              {newRecordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setNewRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default RaiseTicket

// To upload signle file, can be removed later
// if (attachment.file) {
//   uploadFile = await getUploadURL({ folder, fileName })
//   await fetch(uploadFile.data.url, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//     body: attachment.file,
//   })
// }
