import { Toast } from 'primereact/toast'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Dialog } from 'primereact/dialog'
import { Timeline } from 'primereact/timeline'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { InputTextarea } from 'primereact/inputtextarea'
import { Divider } from 'primereact/divider'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { format, formatDistanceToNow, subDays } from 'date-fns'
import useQuery from '../../../hooks/useQuery'
import {
  deleteDACNote,
  getDACIssueFollowup,
  getDACNotesById,
  patchDACNote,
  postDACFollowup,
  postNewDACIssueNote,
} from '../../../api'
import { useGlobalContext } from '../../../context'
import { TabPanel, TabView } from 'primereact/tabview'
import { handleObjChange } from '../../../functions/setter'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { ConfirmPopup } from 'primereact/confirmpopup'

export default function ViewDACRecordNotes({
  setOpenIssuesTableLoading,
  setClosedIssuesTableLoading,
  pathname,
}) {
  //* Used in multiple places
  const newNoteRef = useRef(null)
  const toast = useRef(null)
  const history = useHistory()
  const query = useQuery()
  const noteId = parseInt(query.get('noteId'))
  const [loading, setLoading] = useState(false)

  const { user } = useGlobalContext()

  //* Empty objects
  const emptyErr = {
    state: false,
    errors: [],
  }
  const empty_addDACFollowupRecord = {
    followupMedium: '',
    issueAndResolution: '',
    loading: false,
    estimatedTime: '',
    nextFollowupTime: '',
    clientFeedback: '',
  }
  const empty_deleteDACNoteData = {
    note: {},
    visible: false,
  }

  const empty_editDACNoteData = {
    noteId: '',
    visible: false,
    text: '',
  }

  //* State start
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [notesDialogVisible, setNotesDialogVisible] = useState(false)
  const [DACIssueNotes, setDACIssueNotes] = useState([])
  const [generalNote, setGeneralNote] = useState('')
  const [addNewNoteBtn, setAddNewNoteBtn] = useState(false)
  const [DACFollowupRecords, setDACFollowupRecords] = useState([])
  const [note, setNote] = useState('')
  const [addDACFollowupRecord, setAddDACFollowupRecord] = useState(empty_addDACFollowupRecord)
  const [deleteDACNoteData, setDeleteDACNoteData] = useState(empty_deleteDACNoteData)
  const [editDACNoteData, setEditDACNoteData] = useState(empty_editDACNoteData)
  //* State end

  //* Function to fetch all notes for a DAC issue using it's id
  const fetchDACIssueNotesById = useCallback(async () => {
    if (!noteId) return
    setOpenIssuesTableLoading(true)
    setClosedIssuesTableLoading(true)
    const res = await getDACNotesById(noteId)
    if (res) {
      setOpenIssuesTableLoading(false)
      setClosedIssuesTableLoading(false)
      if (res.status === 200) {
        setDACIssueNotes(res.data.notes)
        setNotesDialogVisible(true)
      }
    }
    const followupRes = await getDACIssueFollowup(noteId)
    if (followupRes) {
      setNotesDialogVisible(true)
      if (followupRes.status === 200) {
        setDACFollowupRecords(followupRes.data.followups)
      }
    }
  }, [noteId])
  useEffect(() => {
    fetchDACIssueNotesById()
  }, [fetchDACIssueNotesById])

  //* Function to hide the section to add a new note
  const hideDACIssueNotes = () => {
    setLoading(false)
    setNotesDialogVisible(false)
    setDACIssueNotes([])
    setGeneralNote('')
    setAddNewNoteBtn(false)
    history.push({
      pathname: pathname,
    })
  }

  //* Function to add a new note to DAC issue
  const handleNewComplaintNote = async () => {
    if (!note.trim()) {
      setNewRecordError({
        state: true,
        errors: ['Note is invalid'],
      })
      return
    }
    setLoading(true)
    const res = await postNewDACIssueNote(note, noteId)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        setLoading(false)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setDACIssueNotes((ps) => [res.data.note, ...ps])
        setNote('')
        setAddNewNoteBtn(false)
      }
    }
  }

  const validateComplaintFollowup = () => {
    const err = []
    const { issueAndResolution, followupMedium, estimatedTime, nextFollowupTime } =
      addDACFollowupRecord

    if (!issueAndResolution) {
      err.push('Adding an Issue & Resolution is mandatory')
    }
    if (!followupMedium.length) {
      err.push('Add a medium of communication')
    }
    if (!estimatedTime) {
      err.push('Please select estimated time of resolution')
    }
    if (!nextFollowupTime) {
      err.push('Please select next followup interval')
    }
    return err
  }

  const handleComplaintFollowup = async () => {
    const errs = validateComplaintFollowup()
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    handleObjChange(setAddDACFollowupRecord, 'loading', true)
    const { followupMedium, issueAndResolution, estimatedTime, nextFollowupTime, clientFeedback } =
      addDACFollowupRecord
    let id = noteId
    const res = await postDACFollowup({
      id,
      followupMedium,
      note: issueAndResolution,
      estimatedTime,
      nextFollowupTime,
      clientFeedback,
    })
    if (res) {
      handleObjChange(setAddDACFollowupRecord, 'loading', false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setDACFollowupRecords((ps) => [...res.data.followup, ...ps])
        setDACIssueNotes((ps) => [...res.data.note, ...ps])
        setAddDACFollowupRecord(empty_addDACFollowupRecord)
      }
    }
  }

  const hideDeleteDACNote = () => {
    setDeleteDACNoteData(empty_deleteDACNoteData)
  }

  const handleDeleteDACNote = async () => {
    setLoading(true)
    const res = await deleteDACNote(deleteDACNoteData.note?.id)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        let _DACIssueNotes = DACIssueNotes
        _DACIssueNotes = _DACIssueNotes.filter((n) => n.id !== deleteDACNoteData.note.id)
        setDACIssueNotes(_DACIssueNotes)
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
      }
      hideDeleteDACNote()
    }
  }

  const content = (item, index) => {
    let parseData = new Date(item?.createdAt)
    let date = formatDistanceToNow(parseData, { addSuffix: true })
    let date1 = format(parseData, 'PPPPp')
    let expectedTAT = format(new Date(item?.estimatedTime), 'PPp')
    let nextFollowup = format(new Date(item?.nextFollowupTime), 'PPp')

    return (
      <div key={item.id} className="p-mb-2">
        <h6> Followup recorded via {item.followupMedium}</h6>
        <p className="">
          <span>Note: </span>
          <label className="p-text-lowercase" style={{ wordBreak: 'break-all' }}>
            {item?.note}
          </label>
        </p>
        <div>
          <p>
            <span>Performed By: </span>
            <label className="p-text-lowercase">{item?.createdBy}</label>
          </p>
        </div>
        <div>
          <p>
            <span>E.Time: </span>
            <label>{expectedTAT}</label>
          </p>
          <p>
            <span>N.Time: </span>
            <label>{nextFollowup}</label>
          </p>
          <span>
            {date} - {date1}
          </span>
        </div>
      </div>
    )
  }

  const hideEditDACNote = () => {
    setEditDACNoteData(empty_editDACNoteData)
  }

  const handleEditDACNote = async () => {
    setLoading(true)
    const res = await patchDACNote(editDACNoteData.noteId, editDACNoteData.text)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        let _DACIssueNotes = DACIssueNotes
        _DACIssueNotes = _DACIssueNotes.map((n) =>
          n.id === editDACNoteData.noteId ? res.data.note : n
        )

        setDACIssueNotes(_DACIssueNotes)
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
      }
      hideEditDACNote()
    }
  }

  return (
    <div>
      <Toast ref={toast} />

      <Dialog
        visible={notesDialogVisible}
        header={`Notes for DAC issue #${noteId}`}
        style={{
          width: '60%',
          minWidth: '400px',
        }}
        onHide={() => {
          hideDACIssueNotes()
        }}
        footer={
          <Button
            label="Cancel"
            className="p-button-text"
            icon="pi pi-times"
            onClick={() => {
              hideDACIssueNotes()
            }}
          />
        }
      >
        <div className="p-grid">
          <div className="p-col-7">
            {(DACIssueNotes[0]?.assignedToId === user.id ||
              DACIssueNotes[0]?.orderAgentId === user.id ||
              ['supreadmin', 'admin', 'manager', 'operations'].includes(user.role) ||
              (['teamlead'].includes(user.role) &&
                user.members.map((u) => u.id).includes(DACIssueNotes[0]?.assignedToId)) ||
              user.id === 150) &&
              DACIssueNotes[0]?.complaintStatus !== 'close' && (
                <TabView>
                  <TabPanel header="Add Notes">
                    <div className="card p-fluid">
                      <div className="p-field">
                        <InputTextarea
                          autoResize
                          rows={3}
                          placeholder="Enter note"
                          value={note}
                          onChange={(e) => setNote(e.target.value || '')}
                        />
                      </div>
                      <div className="p-field">
                        <Button
                          label={loading ? 'Adding' : 'Add'}
                          loading={loading}
                          disabled={loading}
                          onClick={handleNewComplaintNote}
                        />
                      </div>
                      {newRecordError.state && (
                        <>
                          {newRecordError.errors.map((err, idx) => {
                            return (
                              <div key={idx} className="p-fluid p-filed p-col-12">
                                <Message text={err} severity="warn" sticky={true} />
                              </div>
                            )
                          })}
                          <div className="p-fluid p-field p-col-12">
                            <Button
                              type="button"
                              onClick={() => {
                                setNewRecordError(emptyErr)
                              }}
                              icon="pi pi-times"
                              label="Clear Warnings"
                              className="p-button-secondary"
                            ></Button>
                          </div>
                        </>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel header="Add Followup">
                    <div className="card">
                      <div className="p-field ">
                        <label htmlFor="reason" className="">
                          Add Issue and Resolution <sup style={{ color: 'red' }}>*</sup>
                        </label>
                        <InputTextarea
                          id="reason"
                          rows={3}
                          cols={30}
                          value={addDACFollowupRecord.issueAndResolution}
                          onChange={(e) =>
                            handleObjChange(
                              setAddDACFollowupRecord,
                              'issueAndResolution',
                              e.target.value || ''
                            )
                          }
                          className="w-full p-mt-1"
                          placeholder="Start typing here..."
                        />
                      </div>
                      <div className="p-field">
                        <label htmlFor="medium" className="">
                          Enter your medium of communication <sup style={{ color: 'red' }}>*</sup>
                        </label>

                        <InputText
                          id="medium"
                          value={addDACFollowupRecord.followupMedium}
                          onChange={(e) =>
                            handleObjChange(
                              setAddDACFollowupRecord,
                              'followupMedium',
                              e.target.value || ''
                            )
                          }
                          className="w-full p-mt-1"
                          placeholder="Call, Sms, Email"
                        />
                      </div>
                      <div className="p-field">
                        <label htmlFor="client-feedback" className="">
                          Add client feedback
                        </label>
                        <InputTextarea
                          id="client-feedback"
                          rows={3}
                          cols={30}
                          value={addDACFollowupRecord.clientFeedback}
                          onChange={(e) =>
                            handleObjChange(
                              setAddDACFollowupRecord,
                              'clientFeedback',
                              e.target.value || ''
                            )
                          }
                          className="w-full p-mt-1"
                          placeholder="Start typing here..."
                        />
                      </div>
                      <div className="p-field p-fluid ">
                        <label className="p-d-block" htmlFor="expectedTAT">
                          Select Estimated Resolution Time <sup style={{ color: 'red' }}>*</sup>
                        </label>
                        <Calendar
                          id="ERT"
                          placeholder="Select ERT"
                          showTime
                          hourFormat="12"
                          minDate={subDays(new Date(), 0)}
                          value={addDACFollowupRecord.estimatedTime}
                          onChange={(e) => {
                            handleObjChange(setAddDACFollowupRecord, 'estimatedTime', e.value || '')
                            if (addDACFollowupRecord.nextFollowupTime === '') {
                              handleObjChange(
                                setAddDACFollowupRecord,
                                'nextFollowupTime',
                                e.value || ''
                              )
                            }
                          }}
                        />
                      </div>
                      <div className="p-field p-fluid ">
                        <label className="p-d-block" htmlFor="expectedTAT">
                          Next Followup <sup style={{ color: 'red' }}>*</sup>
                        </label>
                        <Calendar
                          id="next-followup"
                          placeholder="Select Next Followup Interval"
                          showTime
                          hourFormat="12"
                          minDate={subDays(new Date(), 0)}
                          value={addDACFollowupRecord.nextFollowupTime}
                          onChange={(e) => {
                            handleObjChange(
                              setAddDACFollowupRecord,
                              'nextFollowupTime',
                              e.value || ''
                            )
                          }}
                        />
                      </div>
                      <Button
                        className="w-full"
                        label="Submit"
                        disabled={addDACFollowupRecord.loading}
                        loading={addDACFollowupRecord.loading}
                        onClick={handleComplaintFollowup}
                      />
                      {newRecordError.state && (
                        <>
                          {newRecordError.errors.map((err, idx) => {
                            return (
                              <div key={idx} className="p-fluid p-filed p-col-12">
                                <Message text={err} severity="warn" sticky={true} />
                              </div>
                            )
                          })}
                          <div className="p-fluid p-field p-col-12">
                            <Button
                              type="button"
                              onClick={() => {
                                setNewRecordError(emptyErr)
                              }}
                              icon="pi pi-times"
                              label="Clear Warnings"
                              className="p-button-secondary"
                            ></Button>
                          </div>
                        </>
                      )}
                    </div>
                  </TabPanel>
                </TabView>
              )}
            <>
              <div className="card">
                <h5 className="p-m-0">Notes</h5>
              </div>
              <div className="p-grid p-formgrid">
                {DACIssueNotes?.map((note, idx) => {
                  return (
                    <div className="p-col-12" key={idx}>
                      <div className="card">
                        <h6 style={{ wordBreak: 'break-word' }}>
                          {note?.note?.trim()?.startsWith('Issue') ? 'Followup' : 'Note'}
                        </h6>
                        <p style={{ wordBreak: 'break-word' }}>{note?.note}</p>
                        <hr />
                        <div className="p-d-flex gap-1by2 p-ai-center p-jc-between">
                          <div className="p-d-flex gap-1by2 p-ai-center opacity-50">
                            {note?.id && <small>#{note?.id}</small>}
                            {note?.createdBy && <small>By: {note.createdBy}</small>}

                            {note?.updatedAt && (
                              <small>Updated At: {format(new Date(note.updatedAt), 'PPp')}</small>
                            )}
                          </div>
                          {(DACIssueNotes[0]?.assignedToId === user.id ||
                            DACIssueNotes[0]?.orderAgentId === user.id ||
                            ['supreadmin', 'admin', 'manager', 'operations'].includes(user.role) ||
                            (['teamlead'].includes(user.role) &&
                              user.members
                                .map((u) => u.id)
                                .includes(DACIssueNotes[0]?.assignedToId)) ||
                            user.id === 150) &&
                            DACIssueNotes[0]?.complaintStatus !== 'close' &&
                            note.noteType === 'general' && (
                              <>
                                <div className="p-d-flex gap-1">
                                  <Button
                                    icon="pi pi-pencil"
                                    className="p-button-text"
                                    onClick={() => {
                                      setEditDACNoteData({
                                        noteId: note.id,
                                        visible: true,
                                        text: note.note,
                                      })
                                    }}
                                  />

                                  <Button
                                    id={`deleteOrderNote-${note.id}`}
                                    className="p-button-danger p-button-text"
                                    icon="pi pi-trash"
                                    onClick={() => {
                                      setDeleteDACNoteData({
                                        note,
                                        visible: true,
                                      })
                                    }}
                                  />
                                  <ConfirmPopup
                                    target={document.getElementById(`deleteOrderNote-${note.id}`)}
                                    visible={
                                      deleteDACNoteData.visible &&
                                      deleteDACNoteData.note?.id === note?.id
                                    }
                                    onHide={hideDeleteDACNote}
                                    message="Are you sure you want to delete this note?"
                                    icon="pi pi-exclamation-triangle"
                                    acceptClassName="p-button-danger"
                                    accept={handleDeleteDACNote}
                                  />
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          </div>
          <div className="p-col-12 p-lg-5">
            <div className="card">
              <h5 className="p-d-flex p-jc-between p-ai-center">
                <span>Complaint Followup Timeline</span>
              </h5>
              <hr />
              {DACFollowupRecords.length > 0 ? (
                <div className="widget-timeline">
                  <div className="timeline-header p-d-flex p-jc-between p-ai-center"></div>
                  <div className="timeline-content">
                    <Timeline
                      value={DACFollowupRecords}
                      content={content}
                      className="custimized-timeline"
                    />
                  </div>
                </div>
              ) : (
                <h5 className="p-text-center">
                  <i className="pi pi-exclamation-circle p-mx-2" />
                  No Followup History
                </h5>
              )}
            </div>
          </div>
        </div>
        {/* <div>
          <Timeline
            value={DACIssueNotes}
            align="alternate"
            className="customized-timeline"
            marker={customizedMarker}
            content={customizedContent}
            style={{ zIndex: '999' }}
          />
          {(DACIssueNotes[0]?.assignedToId === user.id ||
            DACIssueNotes[0]?.orderAgentId === user.id ||
            ['superadmin', 'admin', 'manager', 'operations'].includes(user.role) ||
            (['teamlead'].includes(user.role) &&
              user.members.map((u) => u.id).includes(DACIssueNotes[0]?.assignedToId)) ||
            user.id === 150) &&
            DACIssueNotes[0]?.issueStatus !== 'close' && (
              <div className="p-d-flex p-flex-column p-jc-end p-ai-end">
                {addNewNoteBtn && (
                  <>
                    <div className="p-field p-fluid p-col-6 p-mb-0 p-px-0">
                      <label className="p-d-block">Add another note</label>
                      <InputTextarea
                        style={{ borderRadius: '15px 15px 0 15px' }}
                        ref={newNoteRef}
                        autoResize
                        rows={3}
                        cols={30}
                        placeholder="Type Here..."
                        value={generalNote}
                        onChange={(e) => {
                          setGeneralNote(e.target.value)
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        loading={loading}
                        label="Submit"
                        icon="pi pi-check"
                        className="p-mx-2 p-button-rounded"
                        onClick={() => {
                          addNewNoteToDACIssue()
                        }}
                      />
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger"
                        disabled={loading}
                        onClick={() => {
                          setAddNewNoteBtn(false)
                          setGeneralNote('')
                          setNewRecordError(emptyErr)
                        }}
                      />
                    </div>
                  </>
                )}
                {!addNewNoteBtn && (
                  <div>
                    <Button
                      label="Add Note"
                      icon="pi pi-plus "
                      className="p-button-rounded"
                      onClick={() => {
                        setAddNewNoteBtn(true)
                        const tm = setTimeout(() => {
                          newNoteRef?.current?.focus()
                        }, 100)
                        return () => clearTimeout(tm)
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          {newRecordError.state && (
            <>
              {newRecordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setNewRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </div> */}
        <Dialog
          visible={editDACNoteData.visible}
          onHide={hideEditDACNote}
          className="w-full"
          style={{ maxWidth: '600px' }}
          header="Edit Order Note"
          footer={
            <div className="p-d-flex p-jc-end">
              <Button
                label="Cancel"
                className="p-button-text"
                icon="pi pi-times"
                disabled={loading}
                onClick={hideEditDACNote}
              />
              <Button
                label={loading ? 'Saving' : 'Save'}
                loading={loading}
                disabled={loading}
                onClick={handleEditDACNote}
                icon="pi pi-save"
              />
            </div>
          }
        >
          <InputTextarea
            autoResize
            rows={5}
            placeholder="Enter the note"
            className="w-full"
            value={editDACNoteData.text}
            onChange={(e) =>
              setEditDACNoteData({
                ...editDACNoteData,
                text: e.target.value,
              })
            }
          />
        </Dialog>
      </Dialog>
    </div>
  )
}
